import { ErrorMessage } from "@hookform/error-message";
import { IonContent, IonPage, useIonToast } from "@ionic/react";
import AxiosApi from "../../utils/axios";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { baseUrl } from "../../utils/config";
import FormError from "../FormError";
import arrow from "../../assets/images/arrowback.svg";
import GoogleMapReact from "google-map-react";
import { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

type AddAddressProps = {
  update: any;
  setAddAddress: any;
};

const AddAddressForm = ({ update, setAddAddress }: AddAddressProps) => {
  const [lats, setLats] = useState();
  const [long, setLong] = useState();
  const [present] = useIonToast();
  const presentToast = (message: any) => {
    present({
      message: message,
      duration: 3000,
      position: "bottom",
    });
  };
  const defaultProps = {
    center: {
      lat: lats || 25.2854,
      lng: long || 51.531,
    },
    zoom: 14,
  };
  const history = useHistory();
  const token = localStorage.getItem("token");
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(CreateUserValidation),
  });
  const onSubmit = (data: any) => {
    data.lat = defaultProps.center.lat;
    data.long = defaultProps.center.lng;
    AxiosApi.post(`${baseUrl}/api/user/address/create`, { ...data, token })
      .then((res: any) => {
        reset();
        update(true);
        setAddAddress(false);
        presentToast("Address Added Successfully");
      })

      .catch((err: any) => {
        presentToast(err?.response?.data.message);
      });
  };
  const { t } = useTranslation();
  return (
    <>
      <div>
        <div className="menu-size">
          <div className="d-flex  mt-3 py-1">
            <div className="align-self-center">
              <Link
                to="#"
                onClick={() => {
                  setAddAddress(false);
                }}
                className="ps-1"
                shadow-0=""
              >
                <img src={arrow} alt="back" />
              </Link>
            </div>
            <div className="align-self-center ms-3">
              <h1
                className="m-0 main-clr"
                style={{
                  fontWeight: "700",
                  fontSize: "24px",
                  lineHeight: "30px",
                }}
              >
                {t("addnewaddress")}
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="content mt-5">
        <form onSubmit={handleSubmit(onSubmit)} className="px-3 text-left pb-7">
          <div className="form-group boxed">
            <label className="login-label">{t("address1")}</label>
            <div className="input-wrapper">
              <input
                type="text"
                className="form-control login-input"
                placeholder="address1"
                {...register("address1", {
                  required: "this field is required",
                })}
              />
              <ErrorMessage
                errors={errors}
                name="address1"
                render={FormError}
              />
            </div>
          </div>
          <div className="pb-1"></div>
          <div className="form-group boxed">
            <label className="login-label">{t("address2")}</label>
            <div className="input-wrapper">
              <input
                type="text"
                className="form-control login-input"
                placeholder="address2"
                {...register("address2", {
                  required: "this field is required",
                })}
              />
              <ErrorMessage
                errors={errors}
                name="address2"
                render={FormError}
              />
            </div>
          </div>
          <div className="pb-1"></div>
          <div className="form-group boxed">
            <label className="login-label">{t("city")}</label>
            <div className="input-wrapper">
              <input
                type="text"
                className="form-control login-input"
                placeholder="city"
                {...register("city", {
                  required: "this field is required",
                })}
              />
              <ErrorMessage errors={errors} name="city" render={FormError} />
            </div>
          </div>
          <div className="pb-1"></div>
          <div className="form-group boxed">
            <label className="login-label">{t("area")}</label>
            <div className="input-wrapper">
              <input
                type="text"
                className="form-control login-input"
                placeholder="area"
                {...register("area", {
                  required: "this field is required",
                })}
              />
              <ErrorMessage errors={errors} name="area" render={FormError} />
            </div>
          </div>
          <div className="pb-1"></div>

          <div style={{ height: 400, width: 370 }}>
            <GoogleMapReact
              defaultCenter={defaultProps.center}
              defaultZoom={defaultProps.zoom}
              bootstrapURLKeys={{
                key: "AIzaSyDxsUzIfLpefNwPISG4wYuo_qrrKHuMUKQ",
              }}
              yesIWantToUseGoogleMapApiInternals={true}
              onClick={(e: any) => {
                setLats(e.lat);
                setLong(e.lng);
              }}
            >
              <Marker
                lat={defaultProps.center.lat}
                lng={defaultProps.center.lng}
                text="check"
              />
            </GoogleMapReact>
          </div>

          <button
            className=" btn btn-primary btn-block btn-lg my-5"
            style={{ width: "100%" }}
          >
            {t("addaddress")}
          </button>
        </form>
      </div>
    </>
  );
};
export default AddAddressForm;

const Marker = ({ text }: any) => {
  return (
    <div className="SuperAwesomePin">
      <img
        height="25"
        width="25"
        src="https://img.icons8.com/color/48/000000/marker--v1.png"
        alt="location"
      />
    </div>
  );
};

AddAddressForm.propTypes = {
  values: PropTypes.any,
  setValues: PropTypes.any,
  moveMarker: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.number,
  lat: PropTypes.number,
  long: PropTypes.number,
};
