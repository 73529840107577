import * as yup from "yup";

const localeMessage = (type, values) => ({
  transKey: `forms.validation.${type}`,
  values,
});

yup.setLocale({
  mixed: {
    default: ({ path }) => localeMessage("invalid", { name: path }),
    matches: ({ path }) => localeMessage("invalid", { name: path }),
    required: ({ path }) => localeMessage("required", { name: path }),
  },
  number: {
    default: ({ path }) => localeMessage("invalid", { name: path }),
    max: ({ path, max, value }) =>
      localeMessage("max", { name: path, max, value }),
    required: ({ path }) => localeMessage("required", { name: path }),
  },
  string: {
    default: ({ path }) => localeMessage("invalid", { name: path }),
    matches: ({ path }) => localeMessage("invalid", { name: path }),
    required: ({ path }) => localeMessage("required", { name: path }),

    min: ({ min }) => ({
      transKey: "forms.validation.minLength",
      values: { min },
    }),
    max: ({ max }) => ({
      transKey: "forms.validation.maxLength",
      values: { max },
    }),
  },
});

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const RegisterValidation = yup.object().shape({
  name: yup.string().required("Name is a required field"),
  mobile: yup
    .string()
    .typeError("Mobile must be number")
    .required("Number is a required field"),
  password: yup.string().required("Password is a required field"),
  userCurrncy: yup.string().required("User Currency is a required field"),
});

export const LoginValidation = yup.object().shape({
  username: yup
    .string()
    .typeError("Number must be number")
    .required("Number is a required field"),
  password: yup.string().required("Password is a required field"),
});

export const PhoneLoginSchema = yup.object().shape({
  phone: yup.string().matches(phoneRegExp).required(),
  // phone: yup.string().matches(phoneRegExp, "phone number is not valid").required(),
});

export const DonationSchema = yup.object().shape({
  donation_types: yup.array().required(),
  name: yup.string().required(),
  address: yup.string().required(),
  housing_type: yup.string().nullable().required(),
  state_id: yup.string().required(),
  pickup_date: yup.string().required(),
  photos: yup.mixed().nullable().required(),
});

export const UserDataSchema = yup.object().shape({
  name: yup.string().required(),
  address: yup.string().required(),
  state_id: yup.string().required(),
  housing_type: yup.string().required(),
});

export const CheckoutSchema = yup.object().shape({
  name: yup.string().required(),
  state_id: yup.string().required(),
  address: yup.string().required(),
  housing_type: yup.string().required(),
});

export const ChangePasswordSchema = yup.object().shape({
  old_password: yup.string().required("old password is a required field"),
  password: yup.string().required(),
  password_confirmation: yup
    .string()
    .required("password confirmation is a required field")
    .oneOf(
      [yup.ref("password")],
      "password confirmation doesn't match password"
    ),
});

export const contactSchema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().required().email(),
  phone: yup
    .number()
    .typeError(
      "Phone is required and must be a number, characters are not allowed"
    )
    .required(),
  subject: yup.string().required(),
  message: yup.string().required(),
  recaptcha: yup
    .boolean()
    .oneOf([true], "recaptcha is a required field")
    .required(),
});

export const EmailSchema = yup.object().shape({
  email: yup.string().required(),
});

export const CodeVerificationSchema = yup.object().shape({
  verify_code: yup
    .string()
    .required("Verification code must be completed before submitting its value")
    .min(4, "Verification code must be completed before submitting its value"),
});

export const QuantitySchema = yup.object().shape({
  stock: yup.number(),
  quantity: yup.number().max(yup.ref("stock")),
});

//
export const CreateAccountValidation = yup.object().shape({
  name: yup.string().required("this field is required"),
  password: yup
    .string()
    .required("Required")
    .min(8, "Password is too short - should be 8 chars minimum")
    .max(30, "'Password is too long - should be 30 chars maximum")
    .matches(/^(?=.*[a-z])/, "Must contain at least one lowercase character")
    .matches(/^(?=.*[A-Z])/, "Must contain at least one uppercase character")
    .matches(/^(?=.*[0-9])/, "Must contain at least one number")
    .matches(/^(?!.*(.)\1\1).*$/, "You Can't Repeat Same Character 3 Times")
    .matches(
      /^(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=.*[a-zA-Z0-9]).+$/,
      "Must Contain One Special Or Symbol Character"
    ),
  repassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords don't match!")
    .required("Required"),
  // mobile: yup.string().required("this field is required"),
  // userCurrncy: yup.string().required("this field is required"),
  check: yup.boolean().oneOf([true], "Accept privacy policy"),
});
export const ForgetPasswordValidation = yup.object().shape({
  mobilenum: yup.string().required("Number is a required field"),
});
export const ChangePasswordValidation = yup.object().shape({
  currentPassword: yup.string().required("Required"),
  newPassword: yup
    .string()
    .required("Required")
    .min(8, "Password is too short - should be 8 chars minimum")
    .max(30, "Password is too long - should be 30 chars maximum")
    .matches(/^(?=.*[a-z])/, "Must contain at least one lowercase character")
    .matches(/^(?=.*[A-Z])/, "Must contain at least one uppercase character")
    .matches(/^(?=.*[0-9])/, "Must contain at least one number")
    .matches(/^(?!.*(.)\1\1).*$/, "You Can't Repeat Same Character 3 Times")
    .matches(
      /^(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=.*[a-zA-Z0-9]).+$/,
      "Must Contain One Special Or Symbol Character"
    ),
  verifyPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Passwords don't match!")
    .required("Required"),
});

// import * as yup from "yup";
