import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { clearSpinnerStatus } from "../../redux/actions";

export const ProtectedRoute = ({ children, ...rest }) => {
  // const { token } = useSelector(({ userState }) => userState);
  const token = localStorage.token;
  if (token) {
    if (localStorage.getItem("favourites_offers") == undefined)
      localStorage.setItem("favourites_offers", JSON.stringify([]));
    if (localStorage.getItem("favourites_products") == undefined)
      localStorage.setItem("favourites_products", JSON.stringify([]));
    if (localStorage.getItem("favourites_tagslist") == undefined)
      localStorage.setItem("favourites_tagslist", JSON.stringify([]));
  }
  const distpatch = useDispatch();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        token
          ? children
          : distpatch(clearSpinnerStatus()) && (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: location },
                }}
              />
            )
      }
    />
  );
};

ProtectedRoute.propTypes = {
  children: PropTypes.element.isRequired,
};
