import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const ListItemCoponent = ({ ticket, HandleCloseTicket, i }: any) => {
  const { t } = useTranslation();
  return (
    <Link
      to={`/ticket/${ticket?.id}`}
      className="d-flex  gap-4 text-left address-box mb-2 position-relative"
      key={Math.random()}
    >
      <div className="d-flex flex-column">
        <p className="main-lists-h">
          {t("ticketnumber")} : {ticket?.ticketNumber}
        </p>

        <div className="d-flex  justify-content-between">
          <p className="main-lists-h">
            {t("tickettype")} : {ticket?.ticketType}
          </p>

          <div
            className="collapse show"
            id="tab-17"
            data-bs-parent="#tab-group-6"
          >
            <div className="">
              <div className="text-center ">
                {
                  <Link to="#" className="flex ">
                    {!ticket?.isClosed ? (
                      <span
                        onClick={() => {
                          HandleCloseTicket(ticket?.id);
                        }}
                        className={` position-absolute ${
                          ticket?.isClosed ? "text-danger" : "main-clr"
                        } `}
                        style={{
                          fontSize: "13px",
                          right: "8px",
                          color: "#ff9900",
                        }}
                      >
                        {t("closeticket")}
                      </span>
                    ) : (
                      <span
                        className={` position-absolute ${
                          ticket?.isClosed ? "text-danger" : "main-clr"
                        } `}
                        style={{
                          fontSize: "13px",
                          right: "8px",
                          color: "#ff9900",
                        }}
                      >
                        {t("closed")}
                      </span>
                    )}
                  </Link>
                }
              </div>
            </div>
          </div>
        </div>
        <p className="m-0 position relative d-flex gap-2 main-lists-b ">
          {t("ticketmessage")}: {ticket?.ticketBody}
        </p>
      </div>
    </Link>
  );
};
export default ListItemCoponent;
