import { Link } from "react-router-dom"
import { IMAGES } from "../common"
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar ,useIonToast} from '@ionic/react';
import home from "../assets/images/home.svg";
import offers2 from "../assets/images/offers2.svg";
import merchant from "../assets/images/merchant.svg";
import leftmenu from "../assets/images/leftmenu.svg";
import qr from "../assets/images/qr.svg";
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import rightarrow from "../assets/images/arrow-right.svg";
import arrow from "../assets/images/arrowback.svg";
import { useHistory, useParams } from "react-router";
import AxiosApi from "../utils/axios"
import { baseUrl } from "../utils/config";


export const Footer: React.FC = () => {
  const history = useHistory();

  const [present] = useIonToast();

  const presentToast = () => {
    present({
      message: 'Qrcode is not valid',
      duration: 1500,
      position: 'bottom'
    });
  };

  const openScanner = async () => {
    await hapticsVibrate();
    const data = await BarcodeScanner.scan();

   if (data) {
    AxiosApi({
      url: `${baseUrl}/api/public/merchant/details`,
      method: "post",
      data: { merchantId: data.text },
    }).then((res) => {
      if(res?.data?.data){
        history.push('merchantdetails/' + data.text)
      }else {
        presentToast();
      }
      
    }).catch(() => {
      presentToast();
    });
   }
  };

  const hapticsVibrate = async () => {
    await Haptics.vibrate();
  };

  return (
    <>
      <div className="w-100">
        <div className="footer mx-auto  text-center ">
          <div className="btn-group w-100 " role="group">
            <Link className="btn " to="/home">
            <img className="" src={home} alt="home" />

              </Link>
            <Link className="btn " to="/offers">
            <img className="" src={offers2} alt="offers" />
              </Link>

              <Link className="" onClick={openScanner} to="/home">
            <img className="qr-footer" src={qr} alt="qr" />
              </Link>

            <Link  className="btn " to="/products">
            <img className="" src={merchant} alt="merchant" />
              </Link>

            <Link  className="btn " to="/setting">
            <img className="" src={leftmenu} alt="leftmenu" />
              </Link>
        
          </div>
        </div>

      </div>
    </>

  )

}

