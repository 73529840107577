import { IonContent, IonPage, useIonToast } from "@ionic/react";
import AxiosApi from "../../utils/axios";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AddAddressForm from "../../components/ActionSheets/AddAddressForm";
import { LoadingContainer } from "../../components/ActionSheets/LoadingAction";
import { NodataContainer } from "../../components/ActionSheets/NoData";
import { baseUrl } from "../../utils/config";
import CreateTicketForm from "./CreateTicket";
import colorticket from "../../assets/images/colorticket.png";
import ListItemCoponent from "./ListItemComponent";
import offers2 from "../../assets/images/offers2.svg";
import merchant from "../../assets/images/merchant.svg";
import { useTranslation } from "react-i18next";
const TicketList = () => {
  const token = localStorage.getItem("token");
  const [ticketList, setTicketList] = useState<any>();
  const [addTicket, setAddNewTicket] = useState(false);
  const [loading, setLoading] = useState(false);
  const [general, setGeneral] = useState<any>();
  const [product, setProduct] = useState<any>();
  const [offer, setOffer] = useState<any>();
  const { t } = useTranslation();
  const [present] = useIonToast();
  const presentToast = (message: any) => {
    present({
      message: message,
      duration: 3000,
      position: "bottom",
    });
  };
  const getTicketList = () => {
    setLoading(true);
    try {
      AxiosApi.post(`${baseUrl}/api/user/ticket/list`, { token }).then(
        (res: any) => {
          setGeneral(
            res.data.data.rows.filter(
              (ticket: any) => ticket.ticketType === "general"
            )
          );
          setOffer(
            res.data.data.rows.filter(
              (ticket: any) => ticket.ticketType === "offer"
            )
          );
          setProduct(
            res.data.data.rows.filter(
              (ticket: any) => ticket.ticketType === "product"
            )
          );
          setLoading(false);
        }
      );
    } catch (err: any) {
      setLoading(false);
      presentToast(err?.response?.data.message);
    }
  };

  const HandleCloseTicket = useCallback(
    (id: any) => {
      try {
        AxiosApi.post(`${baseUrl}/api/user/ticket/close`, { id, token })
          .then(() => {
            presentToast("Ticket Has Been Closed");
          })
          .then(() => {
            getTicketList();
          });
      } catch (err: any) {
        presentToast(err?.response?.data.message);
      }
    },
    [token]
  );
  useEffect(() => {
    getTicketList();
  }, [token, addTicket]);

  return (
    <>
      {addTicket ? (
        <CreateTicketForm addTicket={setAddNewTicket} />
      ) : (
        <>
          {loading ? (
            <LoadingContainer />
          ) : (
            <>
              <div className="row text-center px-4 mt-4 ticketlist">
                <ul className="nav nav-tabs capsuled" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active "
                      data-bs-toggle="tab"
                      href="#General"
                      role="tab"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6 tabs-img"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                        />
                      </svg>
                      {t("general")}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-bs-toggle="tab"
                      href="#Offer"
                      role="tab"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6 tabs-img"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z"
                        />
                      </svg>
                      {t("offers")}
                    </a>
                    <a
                      className="nav-link"
                      data-bs-toggle="tab"
                      href="#Product"
                      role="tab"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6 tabs-img"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z"
                        />
                      </svg>
                      {t("products")}
                    </a>
                  </li>
                </ul>
                <div className="tab-content mt-4 px-2">
                  <div
                    className="tab-pane fade active show"
                    id="General"
                    role="tabpanel"
                  >
                    {general?.length !== 0 ? (
                      general?.map((ticket: any, i: any) => (
                        <ListItemCoponent
                          HandleCloseTicket={HandleCloseTicket}
                          i={i}
                          ticket={ticket}
                        />
                      ))
                    ) : (
                      <NodataContainer />
                    )}
                  </div>
                  <div className="tab-pane fade  " id="Offer" role="tabpanel">
                    {offer?.length !== 0 ? (
                      offer?.map((ticket: any, i: any) => (
                        <ListItemCoponent
                          HandleCloseTicket={HandleCloseTicket}
                          i={i}
                          ticket={ticket}
                        />
                      ))
                    ) : (
                      <NodataContainer />
                    )}
                  </div>
                  <div className="tab-pane fade  " id="Product" role="tabpanel">
                    {ticketList?.length !== 0 ? (
                      product?.map((ticket: any, i: any) => (
                        <ListItemCoponent
                          HandleCloseTicket={HandleCloseTicket}
                          i={i}
                          ticket={ticket}
                        />
                      ))
                    ) : (
                      <NodataContainer />
                    )}
                  </div>
                </div>
              </div>
              <div
                className="collapse show mt-2 p-2"
                id="tab-17"
                data-bs-parent="#tab-group-6"
              >
                <Link
                  className="card card-style w-100 mx-auto mb-5 py-3 "
                  onClick={() => setAddNewTicket(true)}
                  to="#"
                  style={{
                    backgroundColor: "rgb(238, 238, 238)",
                    filter: "drop-shadow(0px 3px 6px #0000001A)",
                  }}
                >
                  <div className="text-center ">
                    <div
                      className="flex"
                      style={{
                        fontSize: "11px",
                      }}
                    >
                      <img
                        src={colorticket}
                        style={{
                          width: "21px",
                          marginRight: "5px",
                        }}
                        alt="logo"
                      />
                      <span> {t("addnewticket")}</span>
                    </div>
                  </div>
                </Link>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};
export default TicketList;
