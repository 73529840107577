import { IonContent, IonPage } from "@ionic/react";
import RegisterForm from "../../components/registerform";

const SignUpPage: React.FC = () => {
  return (
    <IonPage>
      <IonContent fullscreen>
      <div id="appCapsule">
        <RegisterForm />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default SignUpPage;
