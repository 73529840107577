import { IonContent, IonPage } from "@ionic/react";
import { Footer } from "../../components";
import HeaderContainer from "../../components/Header";
import starb from "../../assets/images/starb.svg";
import { HandleTranslation } from "../../hooks";
import { useTranslation } from "react-i18next";

type OrderSummaryProps = {
  orderData: any;
};

const OrderSummary = ({ orderData }: OrderSummaryProps) => {
  const { t } = useTranslation();
  return (
    <div
      id="appCapsule"
      className="p-4"
      style={{
        paddingBottom: "200px",
      }}
    >
      <div className="w-50 mx-auto mb-3 text-center">
        <img
          src={
            orderData?.Product.image
              ? `https://api.eshailx.com/${orderData?.Product?.image}`
              : starb
          }
          alt="logo"
        />
      </div>
      <h1 className="para-style">
        <strong>
          {t("name")} :{" "}
          {HandleTranslation(
            orderData?.Product?.name,
            orderData?.Product?.nameAr
          )}{" "}
        </strong>
      </h1>
      <p className="para-style">
        {HandleTranslation(
          orderData?.Product?.description,
          orderData?.Product?.descriptionAr
        )}
      </p>

      <p className="para-style product-request-price">
        <strong>
          {t("points")} : {orderData?.Product?.points} = {t("points")} :{" "}
          {orderData?.Product?.price} $
        </strong>
      </p>

      <p className="para-style">
        {t("status")} :{" "}
        <div className="d-flex flex-column ms-5">
          <div className={`d-flex gap-5 align-items-center font-bold `}>
            <i
              className={`bi bi-clock-fill ${
                orderData?.status !== "canceled"
                  ? "delivered-statusicon"
                  : "waiting-statusicon"
              }`}
            ></i>{" "}
            <p
              className={`mb-0 ${
                orderData?.status !== "canceled"
                  ? "delivered"
                  : "waiting-delivery"
              }`}
            >
              {t("orderrecieved")}
            </p>
          </div>
          <div className="linked-delivery" />
          <div className="linked-delivery mb-0" />
          <div className="d-flex gap-5 align-items-center">
            <i
              className={`bi bi-map-fill ${
                orderData?.status !== "canceled"
                  ? "delivered-statusicon"
                  : "waiting-statusicon"
              }`}
            ></i>{" "}
            <p
              className={`mb-0 ${
                orderData?.status !== "canceled"
                  ? "delivered"
                  : "waiting-delivery"
              }`}
            >
              {orderData?.type === "pickup" ? t("purchased") : t("ontheway")}
            </p>
          </div>
          <div className="linked-delivery" />
          <div className="linked-delivery mb-0" />
          <div className="d-flex gap-5 align-items-center">
            {" "}
            <i
              className={`bi bi-emoji-smile-fill ${
                orderData?.status === "done"
                  ? "delivered-statusicon"
                  : "waiting-statusicon"
              }`}
            ></i>{" "}
            <p
              className={`mb-0 ${
                orderData?.status === "done" ? "delivered" : "waiting-delivery"
              }`}
            >
              {orderData?.type === "pickup" ? t("recieved") : t("delivered")}
            </p>
          </div>
        </div>
      </p>
    </div>
  );
};
export default OrderSummary;
