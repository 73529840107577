import React from 'react'
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { Footer } from '../../components/Footer';
import rightarrow from "../../assets/images/arrow-right.svg";
import arrow from "../../assets/images/arrowback.svg";
import  HeaderContainer  from '../../components/Header';
import NotificationCard from './NotificationCard';
import { NodataContainer } from "../../components/ActionSheets/NoData";

const NotificationsPage: React.FC = () => {
  return (
    <IonPage>
  <IonContent className="setting-bg" fullscreen> 
  <div id="appCapsule"  >
  <HeaderContainer headerTitle="Notifications"/> 
        <div className="section  merchant-list page-footer">
       
      <NodataContainer />
        </div>
        </div>
      </IonContent>
          <Footer />
</IonPage>
  )
}

export default NotificationsPage