import React, { useEffect, useRef, useState } from "react";
import merchantcover from "../../assets/images/merchant-cover.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  NavLink,
  Switch,
  Route,
  useRouteMatch,
  Redirect,
  useLocation,
  Link,
} from "react-router-dom";
import SwiperCore, { Pagination, type Swiper as SwiperRef } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import beanos from "../../assets/images/beanos.png";
import pizza from "../../assets/images/pizza.svg";
import starb from "../../assets/images/dummyicon.png";

// Import Swiper styles
import "swiper/css";
import AxiosApi from "../../utils/axios";
import { baseUrl } from "../../utils/config";
import { useSelector } from "react-redux";
import { LoadingContainer } from "../../components/ActionSheets/LoadingAction";
import { IonRefresher, IonRefresherContent } from "@ionic/react";
import { NodataContainer } from "../../components/ActionSheets/NoData";
import { HandleTranslation } from "../../hooks";

const Merchants: React.FC = () => {
  const [cleanCateg, setCleanCateg] = useState(true);
  const [catigories, setCatigories] = useState<any[]>([]);
  const [merchants, setMerchants] = useState<any[]>([]);
  const [cleanmerchant, setCleanMerchant] = useState(true);
  const [filteredMerchants, setFilteredMerchants] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [swiper, setSwiper] = useState<any>();
  const token = localStorage.getItem("token");

  useEffect(() => {
    cleanCateg &&
      AxiosApi({
        url: `${baseUrl}/api/public/catigory/list`,
        method: "post",
        data: { token, limit: 1000 },
      }).then((res) => {
        setCatigories(res?.data?.data?.rows);
        setLoading(false);
      });
    return () => {
      setCleanCateg(false);
    };
  }, [cleanCateg, token]);
  useEffect(() => {
    setLoading(true);
    cleanmerchant &&
      AxiosApi({
        url: `${baseUrl}/api/public/merchant/list`,
        method: "post",
        data: { token, limit: 1000 },
      }).then((res) => {
        setMerchants(res?.data.data.rows);
        setLoading(false);
      });
    return () => {
      setCleanMerchant(false);
    };
  }, [cleanmerchant, token]);

  const CategoryFilter = (id: any) => {
    // setHandleCateg(true);
    const filteredMerchants = merchants?.filter(
      (merchant: any) => merchant?.MerchantProfile?.categoryId === id
    );
    setFilteredMerchants(filteredMerchants);
  };

  useEffect(() => {
    const filteredMerchants = merchants?.filter(
      (merchant: any) =>
        merchant?.MerchantProfile?.categoryId ===
        catigories[swiper.activeIndex]?.id
    );

    setFilteredMerchants(filteredMerchants);
  }, [swiper, merchants, catigories]);

  function handleRefresh(event: any) {
    setTimeout(() => {
      AxiosApi({
        url: `${baseUrl}/api/public/catigory/list`,
        method: "post",
        data: { token, limit: 1000 },
      }).then((res) => {
        setCatigories(res?.data?.data?.rows);
      });
      AxiosApi({
        url: `${baseUrl}/api/public/merchant/list`,
        method: "post",
        data: { token, limit: 1000 },
      }).then((res) => {
        setMerchants(res?.data.data.rows);
        setLoading(false);
      });
      event.detail.complete();
    }, 2000);
  }

  return (
    <>
      {loading ? (
        <LoadingContainer />
      ) : (
        <>
          <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
            <IonRefresherContent refreshingSpinner="circles"></IonRefresherContent>
          </IonRefresher>
          <div className="">
            <div>
              <img
                className="center-item imaged w-90 cover-img"
                src={merchantcover}
                alt="fprint"
              />
            </div>
          </div>
          <div className="section  merchant-list page-footer">
            <div className="scrollmenu merchant-list sticky-bar">
              {catigories?.map((categ: any, i) => (
                <Link
                  key={categ?.id}
                  to="#"
                  id={`slide${i}`}
                  className={`${swiper.activeIndex === i ? "activemenu" : ""}`}
                  onClick={() => {
                    swiper.activeIndex = 0;
                    catigories.splice(0, 0, categ);
                    catigories.splice(i + 1, 1);
                    CategoryFilter(categ?.id);
                  }}
                >
                  {HandleTranslation(categ?.name, categ?.nameAr)}
                </Link>
              ))}
            </div>

            <Swiper
              onSwiper={setSwiper}
              pagination={false}
              modules={[Pagination]}
              centeredSlides={true}
              navigation={{
                prevEl: ".prev",
                nextEl: ".next",
              }}
              onSlideChange={(swiperCore) => {
                let { activeIndex } = swiperCore;

                let newMerchants = merchants.filter(
                  (merchant: any) =>
                    merchant.MerchantProfile.categoryId ===
                    catigories[activeIndex]?.id
                );
                setFilteredMerchants(newMerchants);
              }}
            >
              {catigories?.map((cat: any) => (
                <SwiperSlide key={cat.id}>
                  <div
                    className="row text-center m-0  "
                    style={{
                      minHeight: "350px",
                    }}
                  >
                    {filteredMerchants.length !== 0 &&
                    filteredMerchants.length !== 0 ? (
                      filteredMerchants?.map((merchant: any, i) => (
                        <div
                          key={merchant?.id}
                          className="col-4 merchant-logo-space"
                          id={`slide${i}`}
                        >
                          <Link to={`/merchantdetails/${merchant?.id}`}>
                            <img
                              src={
                                merchant?.MerchantProfile?.logo === null
                                  ? starb
                                  : `https://api.eshailx.com/${merchant?.MerchantProfile.logo}`
                              }
                              alt="logo"
                              className="merchant-logo imaged w76 h76"
                            />
                            <br />
                            <h6 className="merchant-name">{merchant?.name}</h6>
                          </Link>
                        </div>
                      ))
                    ) : (
                      <NodataContainer />
                    )}
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </>
      )}
    </>
  );
};

export default Merchants;
