// lang
export const CHANGE_LANG = "CHANGE_LANG";

// user
export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const UPDATE_USER = "UPDATE_USER";

// Toggle Spinner
export const UPDATE_SPINNER_STATUS = "UPDATE_SPINNER_STATUS";
export const CLEAR_SPINNER_STATUS = "CLEAR_SPINNER_STATUS";

// Upload Progress
export const SET_UPLOAD_PROGRESS = "SET_UPLOAD_PROGRESS";

// User Cart
export const UPDATE_CART = "UPDATE_CART";
export const CLEAR_CART = "CLEAR_CART";
export const UPDATE_WISHLIST = "UPDATE_WISHLIST";
export const CLEAR_WISHLIST = "CLEAR_WISHLIST";
