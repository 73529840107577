import { IonContent, IonPage, useIonToast } from "@ionic/react";
import AxiosApi from "../../utils/axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Footer } from "../../components";
import { LoadingContainer } from "../../components/ActionSheets/LoadingAction";
import HeaderContainer from "../../components/Header";
import { baseUrl } from "../../utils/config";
import { HandleTranslation } from "../../hooks";
import { useTranslation } from "react-i18next";

const FavouriteTags = () => {
  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState<any>([]);
  const [tagList, setTagList] = useState<any>([]);
  const [arr, setArr] = useState<any>([]);
  const token = localStorage.getItem("token");
  const history = useHistory();
  const FavouriteTagsList: any = localStorage.getItem("favourites_tagslist");
  const ParsedFavouritesTagList = JSON.parse(FavouriteTagsList);
  const [present] = useIonToast();
  const presentToast = (message: any) => {
    present({
      message: message,
      duration: 1000,
      position: "bottom",
    });
  };

  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    try {
      AxiosApi({
        url: `${baseUrl}/api/public/tag/list`,
        method: "post",
        data: { token, limit: 1000 },
      }).then((res) => {
        setTags(res?.data?.data?.rows);
        setLoading(false);
      });
    } catch (err: any) {
      console.log(err);
      setLoading(false);
    }
  }, [token]);
  useEffect(() => {
    try {
      AxiosApi({
        url: `${baseUrl}/api/user/favouriteTag/list`,
        method: "post",
        data: { token },
      }).then((res) => {
        setTagList(res?.data.data);
        setLoading(false);
      });
    } catch (err: any) {
      console.log(err);
      setLoading(false);
    }
  }, [token]);

  const HandleTagList = useCallback(
    (item: any) => {
      const duplicateItem = arr.findIndex(function (element: any) {
        if (element == item) return true;
        else return false;
      });
      if (duplicateItem == -1 && arr.length >= 6) {
        console.log("check");
        setArr(arr.splice(duplicateItem, 1));
      }
      if (duplicateItem == -1) {
        setArr([...arr, item]);
      } else {
        setArr(arr.filter((elem: any) => elem != item));

        localStorage.setItem("favourites_tagslist", JSON.stringify(arr));

        arr.length > 3 &&
          tagList.filter((tag: any) => tag?.tagId === item)[0]?.id &&
          presentToast("Removed Successfully");
        try {
          arr.length > 3 &&
            tagList.filter((tag: any) => tag?.tagId === item)[0]?.id &&
            AxiosApi.post(`${baseUrl}/api/user/favouriteTag/delete`, {
              token,
              id: tagList.filter((tag: any) => tag?.tagId === item)[0]?.id,
            });
        } catch (err: any) {
          console.log(err);
        }
      }
    },
    [arr, tagList, token]
  );

  useEffect(() => {
    setArr([]);
    const filteredTagList = tagList.map((elem: any, i: any) =>
      setArr((prev: any) => [...prev, elem?.tagId])
    );
  }, [tagList]);

  const Submit = useCallback(() => {
    if (arr.length < 3 || arr.length > 5) {
      return presentToast("You Can't Add More Than 5 or Less Than 3");
    }
    localStorage.setItem("favourites_tagslist", JSON.stringify(arr));
    try {
      AxiosApi.post(`${baseUrl}/api/user/favouriteTag/add`, {
        token,
        tagsIds: JSON.stringify([...arr]),
      }).then(() => {
        presentToast("Added Succesfully");
        history.push("/home");
      });
    } catch (err: any) {
      console.log(err);
    }
  }, [arr, presentToast, token]);
  if (arr.length === 6) {
    presentToast("you can't choose more than 5 tags");
  }
  return (
    <IonPage>
      <IonContent fullscreen>
        {loading ? (
          <LoadingContainer />
        ) : (
          <div id="appCapsule">
            <div className="p-3 mt-3 ">
              <HeaderContainer headerTitle={t("favtags")} />
              <div className="d-flex gap-2 flex-wrap favourite___tagslist">
                {tags?.map((tag: any) => (
                  <button
                    className={`badge ${
                      arr.some((elem: any) => elem === tag.id)
                        ? "badge-warning border"
                        : "border text-black"
                    }`}
                    key={tag?.id}
                    onClick={() => HandleTagList(tag?.id)}
                  >
                    {HandleTranslation(tag.name, tag.nameAr)}
                  </button>
                ))}
              </div>
              {arr.length === 6 || arr.length < 3 ? (
                <p className="text-danger h6">{t("faverror")} </p>
              ) : null}
              <button
                onClick={Submit}
                type="submit"
                className="btn btn-primary btn-block btn-lg mt-5 mb-2"
                disabled={arr.length === 6 || arr.length < 3 ? true : false}
              >
                {t("submit")}
              </button>
            </div>
          </div>
        )}
      </IonContent>
      <Footer />
    </IonPage>
  );
};
export default FavouriteTags;
