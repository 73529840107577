import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import FormError from "../FormError";
import { CreateAccountValidation } from "../../utils/validationSchema";
import AxiosApi from "../../utils/axios";
import { baseUrl } from "../../utils/config";
// import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router";
import hide from "../../assets/images/hide.svg";
import email from "../../assets/images/email.svg";
import logo from "../../assets/images/logo.svg";
import { LoadingContainer } from "../../components/ActionSheets/LoadingAction";
import PhoneInput from "react-phone-input-2";
import { useIonToast } from "@ionic/react";

const RegisterForm = () => {
  const [present] = useIonToast();
  const presentToast = (message: any) => {
    present({
      message: message,
      duration: 3000,
      position: "bottom",
    });
  };
  const history = useHistory();
  const [loadingset, setLoadingset] = useState(false);
  const [errorLogin, setErrorLogin] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [phone, setPhone] = useState<any>("");
  const [phoneErrMssg, setPhoneErrMssg] = useState<any>(false);
  const [country, setCountry] = useState<any>();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting, isValid, isDirty },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(CreateAccountValidation),
  });

  const onSubmit = (data: any) => {
    console.log(phone.length);
    if (
      phone.length == undefined ||
      phone.length < 5 ||
      phone.replace(country?.dialCode, "") == ""
    ) {
      return setPhoneErrMssg(true);
    }
    setLoadingset(true);
    setErrorLogin(false);
    data.userCurrncy = "usd";
    data.mobile = phone;
    AxiosApi({
      url: `${baseUrl}/api/user/signup`,
      method: "post",
      data: data,
    })
      .then((res) => {
        reset();
        setLoadingset(false);

        localStorage.setItem("token", res.data.data.token);
        history.push("/home");
        setPhone("");
      })
      .catch((err) => {
        setLoadingset(false);
        setErrorLogin(true);
        setErrorMessage(err?.response?.data?.message);
        presentToast(err?.response?.data?.message);
        setPhone("");
        setPhoneErrMssg(false);
      });
  };
  return (
    <>
      <div className="container p-0">
        <div className="section login-header">
          <img className="center-item" src={logo} alt="logo" />
          <h1 className="login-h1">Join Us</h1>
          <h6 className="login-h6">
            create your new account <br />
            and get the most exclusive deals
          </h6>
        </div>

        {loadingset === true ? (
          <LoadingContainer />
        ) : (
          <div className="section mt-1 mb-5">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group boxed">
                <label className="login-label">Name</label>
                <input
                  {...register("name")}
                  type="text"
                  placeholder="Enter Your Full Name"
                  className="form-control register-input"
                  id=""
                  aria-describedby=""
                />

                <ErrorMessage errors={errors} name="name" render={FormError} />
              </div>

              <div className="form-group boxed">
                <label className="login-label">Phone Number</label>
                {/* <input
                  {...register("mobile")}
                  type="number"
                  className="form-control register-input"
                  placeholder="Enter Your Phone Number"
                  id=""
                  aria-describedby=""
                /> */}
                <PhoneInput
                  inputClass="form-control w-100 register-input"
                  country="qa"
                  inputStyle={{
                    textIndent: "30px",
                  }}
                  value={phone}
                  onChange={(e: any, country) => {
                    setPhone(e);
                    setCountry(country);
                  }}
                  isValid={(value, country: any) => {
                    if (
                      value.match(
                        /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4,7}$/
                      )
                    ) {
                      return true;
                    } else if (value.length == 0) {
                      return "please enter your phone number";
                    } else {
                      return "Invalid value: " + value + ", " + country.name;
                    }
                  }}
                  disableCountryCode={true}
                />
                {/* <ErrorMessage
                  errors={errors}
                  render={FormError}
                  name="mobile"
                /> */}
                {phoneErrMssg && (
                  <span className="text-danger font-9">Invalid Phone Num</span>
                )}
              </div>

              <div className="form-group boxed">
                <label className="login-label">Password</label>
                <input
                  {...register("password", {
                    required: "this field is required",
                  })}
                  placeholder="Enter Your Password "
                  type="password"
                  className="form-control register-input"
                  id="exampleInputPassword1"
                />
                {/* <img
                  src={hide}
                  alt="hide"
                  className="absolute right-2 top-8 "
                /> */}
                <ErrorMessage
                  errors={errors}
                  render={FormError}
                  name="password"
                />
              </div>

              <div className="form-group boxed">
                <label className="login-label">Re-Type</label>

                <input
                  {...register("repassword")}
                  type="password"
                  placeholder="Re-type password"
                  className="form-control register-input"
                  id="exampleInputPassword1"
                />
                {/* <img
                  src={hide}
                  alt="hide"
                  className="absolute right-2 top-8 "
                /> */}
                <ErrorMessage
                  errors={errors}
                  render={FormError}
                  name="repassword"
                />
              </div>

              <div className="my-4 form-check ">
                <div className="d-flex items-center">
                  <input
                    {...register("check")}
                    type="checkbox"
                    className="form-check-input "
                    id="exampleCheck1"
                  />

                  <label
                    className="form-check-label ml-2 mt-1"
                    htmlFor="exampleCheck1"
                  >
                    I agree to Swaplater pay{" "}
                    <a className="toggle-cookies"> privacy policy</a>
                  </label>
                </div>

                <ErrorMessage errors={errors} render={FormError} name="check" />
              </div>

              {errorLogin == true ? (
                <div className="alert alert-outline-danger mb-1" role="alert">
                  {errorMessage}
                </div>
              ) : (
                <></>
              )}
              <br />
              <button
                className="btn btn-primary btn-block btn-lg"
                onSubmit={onSubmit}
              >
                Sign Up
              </button>

              <div>
                <br />
                <div>
                  <p className="login-p">
                    Already have account?
                    <Link to="login" className="login-label">
                      {" "}
                      Login
                    </Link>
                  </p>
                </div>
              </div>
            </form>

            <div id="cookies-box" className="cookies-modal">
              <h5 className="title">We use cookies</h5>
              <div className="mb-2">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
                non lacinia quam.
                <a href="#">Learn more</a>
              </div>
              <a
                href="#"
                className="btn btn-primary btn-block accept-cookies toggle-cookies"
              >
                I accept
              </a>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default RegisterForm;
