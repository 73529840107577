

export const presentToast = (present: any, message: string, color: string) => {
  present({
    message: message,
    duration: 3000,
    position: "bottom",
    color: color,
  });
};
