import { useEffect, useState } from "react";
import merchantcover from "../../assets/images/merchant-cover.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  NavLink,
  Switch,
  Route,
  useRouteMatch,
  Redirect,
  useLocation,
  Link,
} from "react-router-dom";
import SwiperCore, { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import beanos from "../../assets/images/beanos.png";
import pizza from "../../assets/images/pizza.svg";
import starb from "../../assets/images/dummyicon.png";
import OfferCard from "./OfferCard";
import hrtred from "../../assets/images/hrtred.svg";
import mac from "../../assets/images/mac.svg";
import product from "../../assets/images/product.svg";
import dataLoader from "../../assets/images/dataloader.gif";
// Import Swiper styles
import "swiper/css";
import AxiosApi from "../../utils/axios";
import { baseUrl } from "../../utils/config";
import { LoadingContainer } from "../../components/ActionSheets/LoadingAction";
import { IonRefresher, IonRefresherContent, useIonToast } from "@ionic/react";
import { HandleTranslation } from "../../hooks";
import { useTranslation } from "react-i18next";

const Offers: React.FC = () => {
  const [present] = useIonToast();
  const presentToast = (message: any) => {
    present({
      message: message,
      duration: 1000,
      position: "bottom",
    });
  };
  const { t } = useTranslation();
  const [cleanCateg, setCleanCateg] = useState(true);
  const [loadingData, setLoadingData] = useState(false);
  const [tags, setTags] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [swiper, setSwiper] = useState<any>();
  const [offers, setOffers] = useState<any>([]);
  const [id, setId] = useState<any>();
  const handleId = (id: any) => {
    setId(id);
  };
  const favorites: any = localStorage.getItem("favourites_offers");
  const newWish = JSON.parse(favorites);

  const pushNewItem = (data: any) => {
    console.log(data);
    const duplicateItem = newWish.findIndex(function (element: any) {
      if (element.id == data.id) return true;
      else return false;
    });

    if (duplicateItem == -1) {
      console.log(duplicateItem);
      console.log("add item");
      console.log(newWish);
      newWish.push(data);
      localStorage.setItem("favourites_offers", JSON.stringify(newWish));
    } else {
      newWish.splice(duplicateItem, 1);
      console.log("remove item");
      console.log(newWish);
      localStorage.setItem("favourites_offers", JSON.stringify(newWish));
    }
  };

  useEffect(() => {
    setLoading(true);
    cleanCateg &&
      AxiosApi({
        url: `${baseUrl}/api/public/tag/list`,
        method: "post",
        data: { token: "test" },
      }).then((res) => setTags(res?.data?.data?.rows));
    return () => {
      setCleanCateg(false);
      setLoading(false);
    };
  }, [cleanCateg]);
  useEffect(() => {
    tags.length !== 0 &&
      AxiosApi.post(`${baseUrl}/api/public/tagOffers/list`, {
        tagId: tags[0]?.id,
      })
        .then((res: any) => {
          setOffers(res?.data.data.rows);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
  }, [tags]);

  const TagFilter = (tagId: any) => {
    setLoadingData(true);
    AxiosApi.post(`${baseUrl}/api/public/tagOffers/list`, {
      tagId: tagId,
    }).then((res: any) => {
      setOffers(res?.data.data.rows);
      setLoadingData(false);
    });
  };

  function handleRefresh(event: any) {
    AxiosApi({
      url: `${baseUrl}/api/public/tag/list`,
      method: "post",
      data: { token: "test" },
    }).then((res) => setTags(res?.data?.data?.rows));

    AxiosApi.post(`${baseUrl}/api/public/tagOffers/list`, {
      tagId: tags[0]?.id,
    }).then((res: any) => {
      setOffers(res?.data.data.rows);
    });
    setTimeout(() => {
      // Any calls to load data go here
      event.detail.complete();
    }, 2000);
  }

  return (
    <>
      {loading ? (
        <LoadingContainer />
      ) : (
        <>
          <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
            <IonRefresherContent refreshingSpinner="circles"></IonRefresherContent>
          </IonRefresher>
          <div>
            <div>
              <img
                className="center-item imaged w-90 cover-img"
                src={merchantcover}
                alt="fprint"
              />
            </div>
          </div>
          <div className="section  merchant-list page-footer">
            <div className="scrollmenu merchant-list sticky-bar">
              {tags?.map((tag: any, i: any) => (
                <Link
                  key={tag?.id}
                  to="#"
                  className={`${swiper.activeIndex === i ? "activemenu" : ""}`}
                  onClick={() => {
                    swiper.activeIndex = 0;
                    tags.splice(0, 0, tag);
                    tags.splice(i + 1, 1);
                    TagFilter(tag?.id);
                  }}
                >
                  {HandleTranslation(tag?.name, tag?.nameAr)}
                </Link>
              ))}
            </div>

            {
              <Swiper
                onSwiper={setSwiper}
                pagination={false}
                modules={[Pagination]}
                centeredSlides={true}
                navigation={{
                  prevEl: ".prev",
                  nextEl: ".next",
                }}
                onSlideChange={(swiperCore) => {
                  let { activeIndex } = swiperCore;

                  TagFilter(tags?.[activeIndex]?.id);
                }}
              >
                {tags?.map((tag: any) => (
                  <SwiperSlide key={tag?.id}>
                    <div
                      className="row text-center"
                      style={{
                        minHeight: "350px",
                      }}
                    >
                      {loadingData ? (
                        <div className="text-center">
                          <img
                            src={dataLoader}
                            alt="loader"
                            className="w-50 h-50"
                          />
                        </div>
                      ) : (
                        offers?.map((offer: any) => (
                          <div
                            key={offer?.id}
                            className="col-6 mt-1 offercard-padding"
                            onClick={() => {
                              setId(offer?.id);
                            }}
                          >
                            <div className="card product-card" key={offer}>
                              <div className="card-body">
                                <img
                                  className="brand-position"
                                  src={`${
                                    offer?.Offer.images === null
                                      ? product
                                      : `https://api.eshailx.com/${offer?.Offer?.images[0]}`
                                  }   `}
                                  alt="logo"
                                />
                                <div>
                                  <h5 className="offercard-title ">
                                    {HandleTranslation(
                                      offer?.Offer?.title,
                                      offer?.Offer?.titleAr
                                    )}
                                  </h5>
                                  <h5 className="offercard-earn text-center ">
                                    {t("earn")}{" "}
                                    <span className="offercard-earnpoints">
                                      {offer?.Offer?.points}
                                    </span>{" "}
                                    {t("point")}
                                    {Date.parse(String(new Date())) >
                                    Date.parse(offer?.Offer?.endDate) ? (
                                      <span
                                        className="text-danger  d-block"
                                        style={{
                                          fontSize: "11px",
                                        }}
                                      >
                                        {t("expiredoffer")}
                                      </span>
                                    ) : (
                                      <i
                                        className={`bi ${
                                          newWish?.findIndex(
                                            (elem: any) => elem.id === offer?.id
                                          ) == -1
                                            ? `bi-heart`
                                            : `bi-heart-fill`
                                        }  `}
                                        style={{
                                          color: "#ff5700",
                                          fontSize: "13px",
                                        }}
                                        onClick={() => {
                                          pushNewItem(offer);
                                          newWish?.findIndex(
                                            (elem: any) => elem.id === offer?.id
                                          ) == -1
                                            ? presentToast(
                                                "Offer Removed Successfully"
                                              )
                                            : presentToast(
                                                "Offer Added Successfully"
                                              );
                                          window.location.reload();
                                        }}
                                      ></i>
                                    )}
                                  </h5>

                                  <img
                                    className="product-position"
                                    src={` ${
                                      offer?.Offer?.merchant?.image === null
                                        ? mac
                                        : `https://api.eshailx.com/${offer?.Offer?.merchant?.image}`
                                    }`}
                                    alt="mac"
                                  />
                                </div>
                                <p className="offercard-desc">
                                  {HandleTranslation(
                                    offer?.Offer?.description,
                                    offer?.Offer?.descriptionAr
                                  )}
                                </p>
                                <Link
                                  to={`/offers/${offer?.Offer?.id}`}
                                  className="btn btn-sm btn-primary btn-block blue-btn"
                                >
                                  {t("earnpoints")}
                                </Link>
                              </div>
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                    <br />
                  </SwiperSlide>
                ))}
              </Swiper>
            }
          </div>
        </>
      )}
    </>
  );
};

export default Offers;
