import AxiosApi from "../../utils/axios";
import { useEffect, useState } from "react";
import { baseUrl } from "../../utils/config";
import label from "../../assets/images/label.svg";
import starb from "../../assets/images/starb.svg";
import ticket from "../../assets/images/ticket.svg";
import { Link } from "react-router-dom";
import { NodataContainer } from "../../components/ActionSheets/NoData";
import { useIonToast } from "@ionic/react";
import { LoadingContainer } from "../../components/ActionSheets/LoadingAction";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { HandleTranslation } from "../../hooks";

const ProductRequestList: React.FC = ({ reload }: any) => {
  const token = localStorage.getItem("token");
  const [productList, setProductList] = useState<any>();
  const [productListPickUp, setProductListPickup] = useState<any>();
  const [refunnedPrseed, setRefunnedPrseed] = useState(false);
  const [productDetails, setProductDetails] = useState(false);
  const [productInfo, setProductInfo] = useState<any>();
  const [comment, setComment] = useState();
  const [loading, setLoading] = useState(false);
  const [pickupLoading, setPickupLoading] = useState(false);

  const [present] = useIonToast();
  const { t } = useTranslation();
  const presentToast = (message: any) => {
    present({
      message: message,
      duration: 3000,
      position: "bottom",
    });
  };
  const getProductList = () => {
    setLoading(true);
    AxiosApi.post(`${baseUrl}/api/user/product/req/list`, {
      token,
      type: "delivery",
    }).then((res: any) => {
      setProductList(res.data.data);
      setLoading(false);
    });
  };
  const getProductListPickup = () => {
    setPickupLoading(true);
    AxiosApi.post(`${baseUrl}/api/user/product/req/list`, {
      token,
      type: "pickup",
    }).then((res: any) => {
      setProductListPickup(res.data.data);
      setPickupLoading(false);
    });
  };

  const HandleProduct = (product: any) => {
    setProductDetails(true);
    setLoading(true);
    AxiosApi.post(`${baseUrl}/api/user/product/req/details`, {
      token,
      id: product?.id,
    }).then((res: any) => {
      setProductInfo(res?.data?.data);
      setLoading(false);
    });
  };
  const HandleDeleteProduct = (product: any) => {
    AxiosApi.post(`${baseUrl}/api/user/product/req/cancel`, {
      token,
      id: product?.id,
    }).then((res: any) => {
      HandleProduct(product);
      presentToast("Product Request Has Been Canceled");
    });
  };
  const HandleRefundProduct = (product: any) => {
    setRefunnedPrseed(true);
    if (comment === "") {
      return;
    }
    AxiosApi.post(`${baseUrl}/api/user/req/refund`, {
      token,
      reqId: product?.id,
      comment,
    }).then((res: any) => {
      HandleProduct(product);
      HandleProduct(product);
      presentToast("Refund Request Has Been Sent");
    });
  };

  return (
    <>
      <button
        type="button"
        className="btn relative btn-green"
        data-bs-toggle="offcanvas"
        data-bs-target="#productlist"
        onClick={() => {
          getProductList();
          getProductListPickup();
        }}
      >
        {t("orderhistory")}{" "}
        <img className="img-absolut" src={ticket} alt="ticket" />
      </button>

      <div
        className="offcanvas offcanvas-bottom action-sheet section"
        id="productlist"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title">
            {" "}
            {productDetails ? (
              <Link to="#" onClick={() => setProductDetails(false)}>
                <i className="bi bi-arrow-left-short"></i> Back
              </Link>
            ) : (
              <>
                <img className="" src={label} alt="label" />
                <Link to="#" href="#" className="" data-bs-dismiss="offcanvas">
                  {t("orderhistory")}
                </Link>
              </>
            )}
          </h5>
        </div>
        {!loading ? (
          !productDetails ? (
            <div className="offcanvas-body">
              <div className="action-sheet-content">
                <div className="requests">
                  <div className="col-12 mt-2">
                    <ul className="nav nav-tabs capsuled" role="tablist">
                      <li className="nav-item">
                        <a
                          className="nav-link active "
                          data-bs-toggle="tab"
                          href="#deliveryrequest"
                          role="tab"
                        >
                          {t("delivery")}{" "}
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#pickuprequest"
                          role="tab"
                        >
                          {t("pickup")}{" "}
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content mt-2">
                      <div
                        className="tab-pane fade active show"
                        id="deliveryrequest"
                        role="tabpanel"
                      >
                        {pickupLoading ? (
                          <LoadingContainer />
                        ) : (
                          <div className="row text-center">
                            {productList &&
                            productList?.productRequests?.rows?.length !== 0 ? (
                              productList?.productRequests?.rows?.map(
                                (list: any) => (
                                  <div
                                    className="history-card splide_grey position-relative"
                                    key={Math.random()}
                                  >
                                    <div className="row">
                                      <div className="col-3">
                                        <div className="circle">
                                          <img
                                            className=""
                                            src={
                                              list.Product?.image
                                                ? `https://api.eshailx.com/${list?.Product?.image}`
                                                : starb
                                            }
                                            alt="starb"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-5 px-0 slide-box-container">
                                        <h4>
                                          {" "}
                                          {HandleTranslation(
                                            list?.Product.name,
                                            list?.Product.nameAr
                                          )}
                                        </h4>
                                        <h5 className="mb-0">
                                          {HandleTranslation(
                                            list?.Product.points,
                                            Number(
                                              list?.Product.points
                                            ).toLocaleString("ar-u-nu-arab")
                                          )}
                                          {t("point")}
                                        </h5>
                                      </div>

                                      <div className="col-4 px-0">
                                        <button
                                          className="btn btn-spend"
                                          onClick={() => HandleProduct(list)}
                                        >
                                          {t("details")}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )
                            ) : (
                              <NodataContainer />
                            )}
                          </div>
                        )}
                      </div>
                      <div
                        className="tab-pane fade"
                        id="pickuprequest"
                        role="tabpanel"
                      >
                        <div className="row text-center">
                          {pickupLoading ? (
                            <LoadingContainer />
                          ) : productListPickUp?.productRequests?.rows
                              ?.length !== 0 ? (
                            productListPickUp?.productRequests?.rows?.map(
                              (list: any) => (
                                <div
                                  className="history-card splide_grey position-relative"
                                  key={Math.random()}
                                >
                                  <div className="row">
                                    <div className="col-3">
                                      <div className="circle">
                                        <img
                                          className=""
                                          src={
                                            list?.Product.image
                                              ? `https://api.eshailx.com/${list?.Product?.image}`
                                              : starb
                                          }
                                          alt="starb"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-5 px-0 slide-box-container">
                                      <h4>
                                        {" "}
                                        {HandleTranslation(
                                          list?.Product.name,
                                          list?.Product.nameAr
                                        )}
                                      </h4>
                                      <h5 className="mb-0">
                                        {HandleTranslation(
                                          list?.Product.points,
                                          Number(
                                            list?.Product.points
                                          ).toLocaleString("ar-u-nu-arab")
                                        )}
                                        {t("point")}
                                      </h5>
                                    </div>
                                    <div className="col-4 px-0">
                                      <button
                                        className="btn btn-spend "
                                        onClick={() => HandleProduct(list)}
                                      >
                                        {t("details")}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )
                            )
                          ) : (
                            <NodataContainer />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{
                paddingBottom: "200px",
              }}
            >
              <div className="w-50 mx-auto mb-3 text-center">
                <img
                  src={
                    productInfo?.Product.image
                      ? `https://api.eshailx.com/${productInfo?.Product?.image}`
                      : starb
                  }
                  alt="logo"
                />
              </div>
              <h1 className="para-style">
                <strong>Name : {productInfo?.Product?.name} </strong>
              </h1>
              <p className="para-style">{productInfo?.Product?.description}</p>

              <p className="para-style product-request-price">
                <strong>
                  Points : {productInfo?.Product?.points} = Price :{" "}
                  {productInfo?.Product?.price} $
                </strong>
              </p>
              <p className="para-style product-request-price">
                <strong>
                  Date : {moment(productInfo?.createdAt).format("DD-MM-YYYY")}
                </strong>
              </p>
              {productInfo?.type === "pickup" ? (
                <>
                  <p className="para-style product-request-price">
                    <strong>Branch : {productInfo?.Branch?.branchName}</strong>
                  </p>
                  <p className="para-style product-request-price">
                    <strong>Address : {productInfo?.Branch?.address}</strong>
                  </p>
                </>
              ) : (
                <>
                  <p className="para-style product-request-price">
                    <strong>
                      Address : {productInfo?.UserAddress?.Address.address1}{" "}
                      {productInfo?.UserAddress?.Address.address2}
                    </strong>
                  </p>
                  <p className="para-style product-request-price">
                    <strong>
                      City : {productInfo?.UserAddress?.Address.city}{" "}
                    </strong>
                  </p>
                  <p className="para-style product-request-price">
                    <strong>
                      Area : {productInfo?.UserAddress?.Address.area}{" "}
                    </strong>
                  </p>
                </>
              )}

              <div className="para-style">
                Status :{" "}
                <div className="d-flex flex-column ms-5">
                  <div className={`d-flex gap-5 align-items-center font-bold `}>
                    <i
                      className={`bi bi-clock-fill ${
                        productInfo?.status !== "canceled" &&
                        productInfo?.status !== "refund.done"
                          ? "delivered-statusicon"
                          : "waiting-statusicon"
                      }`}
                    ></i>{" "}
                    <p
                      className={`mb-0 ${
                        productInfo?.status !== "canceled" &&
                        productInfo?.status !== "refund.done"
                          ? "delivered"
                          : "waiting-delivery"
                      }`}
                    >
                      order recieved
                    </p>
                  </div>
                  <div className="linked-delivery" />
                  <div className="linked-delivery mb-0" />
                  <div className="d-flex gap-5 align-items-center">
                    <i
                      className={`bi bi-map-fill ${
                        productInfo?.status !== "canceled" &&
                        productInfo?.status !== "refund.done"
                          ? "delivered-statusicon"
                          : "waiting-statusicon"
                      }`}
                    ></i>{" "}
                    <p
                      className={`mb-0 ${
                        productInfo?.status !== "canceled" &&
                        productInfo?.status !== "refund.done"
                          ? "delivered"
                          : "waiting-delivery"
                      }`}
                    >
                      {productInfo?.type === "pickup"
                        ? "Purchased"
                        : "one the way"}
                    </p>
                  </div>
                  <div className="linked-delivery" />
                  <div className="linked-delivery mb-0" />
                  <div className="d-flex gap-5 align-items-center">
                    {" "}
                    <i
                      className={`bi bi-emoji-smile-fill ${
                        productInfo?.status === "done"
                          ? "delivered-statusicon"
                          : "waiting-statusicon"
                      }`}
                    ></i>{" "}
                    <p
                      className={`mb-0 ${
                        productInfo?.status === "done"
                          ? "delivered"
                          : "waiting-delivery"
                      }`}
                    >
                      {productInfo?.type === "pickup"
                        ? "recieved"
                        : "delivered"}
                    </p>
                  </div>
                </div>
              </div>

              {productInfo?.status === "pending" ? (
                <div className="text-center">
                  {" "}
                  <button
                    className="btn text-center btn-danger product-request-btn"
                    onClick={() => HandleDeleteProduct(productInfo)}
                  >
                    Cancel order
                  </button>
                </div>
              ) : (
                productInfo?.status === "canceled" && (
                  <p className="text-center text-danger">Order Canceled</p>
                )
              )}
              {productInfo?.status === "done" && productInfo?.isRefund && (
                <div className="text-center">
                  <button
                    className="btn text-center btn-danger product-request-btn "
                    onClick={() => HandleRefundProduct(productInfo)}
                  >
                    Refund
                  </button>
                  {refunnedPrseed && (
                    <div>
                      <textarea
                        className="form-control login-input"
                        name=""
                        id=""
                        cols={30}
                        rows={3}
                        onChange={(e: any) => setComment(e.target.value)}
                      ></textarea>

                      <p className="text-danger">Please Enter Comment</p>
                    </div>
                  )}
                </div>
              )}
              {productInfo?.status === "refund.pending" && (
                <p className="text-center text-danger">
                  Please Go To The Nearest Merchant Store To Confirm Your Refund
                </p>
              )}
              {productInfo?.status === "refund.done" && (
                <p className="text-center text-danger">
                  This Product Has Been Refunded
                </p>
              )}
            </div>
          )
        ) : (
          <LoadingContainer />
        )}
      </div>
    </>
  );
};

export default ProductRequestList;
