import { IonContent, IonPage } from "@ionic/react";
import AxiosApi from "../../utils/axios";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Footer } from "../../components";
import { LoadingContainer } from "../../components/ActionSheets/LoadingAction";
import { NodataContainer } from "../../components/ActionSheets/NoData";
import HeaderContainer from "../../components/Header";
import { baseUrl } from "../../utils/config";
import transactionicon from "../../assets/images/transaction.png";

const Transactions = () => {
  const token = localStorage.getItem("token");
  const [transactionList, setTransactionList] = useState<any>();
  const [loading, setLoading] = useState(false);
  const GetTransactionsList = useCallback(() => {
    setLoading(true);
    try {
      AxiosApi.post(`${baseUrl}/api/user/transaction/list`, { token }).then(
        (res) => {
          setTransactionList(res?.data.data.rows);
          setLoading(false);
        }
      );
    } catch (err: any) {
      setLoading(false);
      console.log(err);
    }
  }, [token]);

  useEffect(() => {
    GetTransactionsList();
  }, [GetTransactionsList]);

  return (
    <IonPage>
      <HeaderContainer headerTitle="Transactions" />
      <IonContent fullscreen>
        {loading ? (
          <LoadingContainer />
        ) : (
          <div id="appCapsule ">
            <div className="my-5 py-5 px-4">
              {transactionList?.length !== 0 ? (
                transactionList?.map((transaction: any, i: any) => (
                  <Link
                    to={`/transaction/details/${transaction?.id}`}
                    key={transaction?.id}
                  >
                    <div className="p-1 border-bottom mb-2 transaction-list row align-items-center">
                      <div className="col-9">
                        <h5 className="mt-0">{i + 1} - </h5>
                        <p className="para-bold-style  mb-0">
                          date :{" "}
                          {moment(transaction?.createdAt).format("DD/MM/YYYY")}{" "}
                        </p>
                        <p className="para-bold-style  mb-0">
                          price : {transaction?.price} ${" "}
                        </p>
                        <p className="para-bold-style  mb-0">
                          amount : {transaction?.points}P{" "}
                        </p>
                      </div>
                      <img
                        src={transactionicon}
                        alt="transaction"
                        className="h-4  col-3"
                      />
                    </div>
                  </Link>
                ))
              ) : (
                <NodataContainer />
              )}
            </div>
          </div>
        )}
      </IonContent>
      <Footer />
    </IonPage>
  );
};

export default Transactions;
