import React, { useCallback, useEffect, useState } from "react";
import HeaderContainer from "../../components/Header";
import Merchants from "./MerchantsList";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
  useIonToast,
} from "@ionic/react";
import { Footer } from "../../components/Footer";
import starb from "../../assets/images/dummyicon.png";
import stars from "../../assets/images/stars.svg";
import product from "../../assets/images/products.svg";
import offers from "../../assets/images/offers.svg";
import place from "../../assets/images/place.svg";
import hrtred from "../../assets/images/hrtred.svg";
import fries from "../../assets/images/fries.svg";
import naveg from "../../assets/images/naveg.svg";
import combo from "../../assets/images/combo.svg";
import offerg from "../../assets/images/offerg.svg";
import navgreen from "../../assets/images/navgreen.svg";
import GoogleMapReact from "google-map-react";
import { NodataContainer } from "../../components/ActionSheets/NoData";

import AxiosApi from "../../utils/axios";
import { baseUrl } from "../../utils/config";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { LoadingContainer } from "../../components/ActionSheets/LoadingAction";
import Map from "../../common/Map";
import { useTranslation } from "react-i18next";
import { HandleTranslation } from "../../hooks";
const MerchantDetailsPage: React.FC = () => {
  const [merchantDetails, setMerchantDetails] = useState<any>([]);
  const [products, setProcuts] = useState<any>([]);
  const [branchId, setBranchId] = useState<any>();
  const [handleProductId, setHandleProductId] = useState<any>();
  const [handleOffersId, setHandleOffersId] = useState<any>();
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  const merchantId: any = useParams();

  const [branchPosition, setBranchPosition] = useState(null);
  const favoritesOffers: any = localStorage.getItem("favourites_offers");
  const favoritesProducts: any = localStorage.getItem("favourites_products");
  const newWishOffer = JSON.parse(favoritesOffers);
  const newWishProduct = JSON.parse(favoritesProducts);
  const { t } = useTranslation();
  const pushProductItem = (data: any) => {
    const duplicateItem = newWishProduct.findIndex(function (element: any) {
      if (element.id == data.id) return true;
      else return false;
    });

    if (duplicateItem == -1) {
      console.log(duplicateItem);
      console.log("add item");
      console.log(newWishProduct);
      newWishProduct.push(data);
      localStorage.setItem(
        "favourites_products",
        JSON.stringify(newWishProduct)
      );
    } else {
      newWishProduct.splice(duplicateItem, 1);
      console.log("remove item");
      console.log(newWishProduct);
      localStorage.setItem(
        "favourites_products",
        JSON.stringify(newWishProduct)
      );
    }
  };
  const pushOfferItem = (data: any) => {
    console.log(data);
    const duplicateItem = newWishOffer.findIndex(function (element: any) {
      if (element.id == data.id) return true;
      else return false;
    });

    if (duplicateItem == -1) {
      console.log(duplicateItem);
      console.log("add item");
      console.log(newWishOffer);
      newWishOffer.push(data);
      localStorage.setItem("favourites_offers", JSON.stringify(newWishOffer));
    } else {
      newWishOffer.splice(duplicateItem, 1);
      console.log("remove item");
      console.log(newWishOffer);
      localStorage.setItem("favourites_offers", JSON.stringify(newWishOffer));
    }
  };

  useEffect(() => {
    setLoading(true);
    AxiosApi({
      url: `${baseUrl}/api/public/merchant/details`,
      method: "post",
      data: { merchantId: merchantId.id },
    }).then((res) => {
      setMerchantDetails(res?.data?.data);
      setLoading(false);
    });
  }, [merchantId.id]);

  useEffect(() => {
    AxiosApi({
      url: `${baseUrl}/api/user/product/list`,
      method: "post",
      data: { token, merchantId: merchantId.id },
    }).then((res) => {
      setProcuts(res?.data.data.rows);
      setLoading(false);
    });
  }, [merchantId.id, token]);

  function handleRefresh(event: any) {
    setTimeout(() => {
      AxiosApi({
        url: `${baseUrl}/api/public/merchant/details`,
        method: "post",
        data: { merchantId: merchantId.id },
      }).then((res) => {
        setMerchantDetails(res?.data?.data);
      });
      AxiosApi({
        url: `${baseUrl}/api/user/product/list`,
        method: "post",
        data: { token, merchantId: merchantId.id },
      }).then((res) => {
        setProcuts(res?.data.data.rows);
      });
      event.detail.complete();
    }, 2000);
  }
  const [present] = useIonToast();
  const presentToast = (message: any) => {
    present({
      message: message,
      duration: 1000,
      position: "bottom",
    });
  };
  return (
    <IonPage>
      <IonContent fullscreen>
        {loading === true ? (
          <LoadingContainer />
        ) : (
          <div id="appCapsule">
            <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
              <IonRefresherContent refreshingSpinner="circles"></IonRefresherContent>
            </IonRefresher>
            <HeaderContainer
              headerTitle={merchantDetails?.merchantDetails?.name}
            />

            <div className="row p-3 merchant-det">
              <div className="text-center mt-1">
                <img
                  src={
                    merchantDetails?.merchantDetails?.image !== null
                      ? `https://api.eshailx.com/${merchantDetails?.merchantDetails?.image}`
                      : starb
                  }
                  alt="logo"
                  className=" imaged w100"
                />
              </div>

              <div className="col-8">
                <h5 className="text-left">
                  {merchantDetails?.merchantDetails?.name}
                </h5>
                <p className="desc p-0 text-left">
                  {merchantDetails?.merchantDetails?.MerchantProfile?.hotLine}
                </p>
                <img src={stars} alt="image" className="" />
              </div>

              <div className="col-12 mt-2">
                <ul className="nav nav-tabs capsuled" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active "
                      data-bs-toggle="tab"
                      href="#offers"
                      role="tab"
                    >
                      {t("offers")}{" "}
                      <img className="tabs-img" src={offerg} alt="offerg" />
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-bs-toggle="tab"
                      href="#productS"
                      role="tab"
                    >
                      {t("products")}{" "}
                      <img className="tabs-img" src={product} alt="products" />
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-bs-toggle="tab"
                      href="#branch"
                      role="tab"
                    >
                      {t("branches")}{" "}
                      <img className="tabs-img" src={place} alt="place" />
                    </a>
                  </li>
                </ul>
                <div className="tab-content mt-2">
                  <div
                    className="tab-pane fade active show"
                    id="offers"
                    role="tabpanel"
                  >
                    <div className="row text-center">
                      {merchantDetails?.merchantDetails?.Offers.length !== 0 ? (
                        merchantDetails?.merchantDetails?.Offers?.map(
                          (offer: any) => (
                            <div
                              className="col-6 mt-2 offercard-padding"
                              key={offer?.id}
                              onClick={() => {
                                setHandleOffersId(offer?.id);
                              }}
                            >
                              <div className="card product-card">
                                <div className="card-body">
                                  <img
                                    className="brand-position"
                                    src={` ${
                                      offer?.images === null
                                        ? combo
                                        : `https://api.eshailx.com/${offer?.images[0]}`
                                    }`}
                                    alt="mac"
                                  />

                                  <div>
                                    <h5 className="offercard-title">
                                      {HandleTranslation(
                                        offer?.title,
                                        offer?.titleAr
                                      )}
                                    </h5>
                                    <h5 className="offercard-earn">
                                      {t("earn")}{" "}
                                      <span className="offercard-earnpoints">
                                        {HandleTranslation(
                                          offer?.points,
                                          Number(offer?.points).toLocaleString(
                                            "ar-u-nu-arab"
                                          )
                                        )}
                                      </span>{" "}
                                      {t("points")}
                                    </h5>
                                    {Date.parse(String(new Date())) >
                                    Date.parse(offer?.endDate) ? (
                                      <span
                                        className="text-danger ms-2 "
                                        style={{
                                          fontSize: "11px",
                                        }}
                                      >
                                        {t("expiredoffer")}
                                      </span>
                                    ) : (
                                      <i
                                        className={`bi ${
                                          newWishOffer.findIndex(
                                            (elem: any) => elem.id === offer?.id
                                          ) == -1
                                            ? `bi-heart`
                                            : `bi-heart-fill`
                                        }  `}
                                        style={{
                                          color: "#ff5700",
                                          fontSize: "13px",
                                        }}
                                        onClick={() => {
                                          pushOfferItem(offer);
                                          newWishOffer.findIndex(
                                            (elem: any) => elem.id === offer?.id
                                          ) == -1
                                            ? presentToast(
                                                "Offer Removed Successfully"
                                              )
                                            : presentToast(
                                                "Offer Added Successfully"
                                              );
                                          window.location.reload();
                                        }}
                                      ></i>
                                    )}

                                    <img
                                      className="product-position"
                                      src={`${
                                        offer?.images === null
                                          ? fries
                                          : `https://api.eshailx.com/${merchantDetails?.merchantDetails.image}`
                                      }   `}
                                      alt="logo"
                                    />
                                    {/* <img className="product-position" src={products} alt="product" /> */}
                                  </div>
                                  <p className="offercard-desc offercard2">
                                    {HandleTranslation(
                                      offer?.description,
                                      offer?.descriptionAr
                                    )}
                                  </p>
                                  <Link
                                    to={`/offers/${offer?.id}`}
                                    className="btn btn-sm btn-primary btn-block blue-btn"
                                  >
                                    {t("earnpoints")}
                                  </Link>
                                </div>
                              </div>
                            </div>
                          )
                        )
                      ) : (
                        <NodataContainer />
                      )}
                    </div>
                  </div>
                  <div className="tab-pane fade" id="productS" role="tabpanel">
                    <div className="row text-center">
                      {products.length !== 0 ? (
                        products?.map((product: any) => (
                          <div
                            key={product?.id}
                            className="col-6 mt-2 offercard-padding"
                            onClick={() => {
                              setHandleProductId(product?.id);
                            }}
                          >
                            <div className="card product-card">
                              <div className="card-body">
                                <img
                                  className="brand-position"
                                  src={
                                    product?.image === null
                                      ? fries
                                      : `https://api.eshailx.com/${product?.image}`
                                  }
                                  alt="fries"
                                />
                                <div>
                                  <h5 className="offercard-title">
                                    {HandleTranslation(
                                      product?.name,
                                      product?.nameAr
                                    )}
                                  </h5>
                                  <h5 className="offercard-earn">
                                    {t("getitfor")}{" "}
                                    <span className="offercard-earnpoints">
                                      {HandleTranslation(
                                        product?.points,
                                        Number(product?.points).toLocaleString(
                                          "ar-u-nu-arab"
                                        )
                                      )}
                                    </span>{" "}
                                    {t("point")}
                                  </h5>
                                  {product?.stockAmount == 0 ? (
                                    <span
                                      className="text-danger  d-block"
                                      style={{
                                        fontSize: "11px",
                                      }}
                                    >
                                      {t("noamount")}{" "}
                                    </span>
                                  ) : null}
                                  <i
                                    className={` bi ${
                                      newWishProduct.findIndex(
                                        (elem: any) => elem.id === product?.id
                                      ) == -1
                                        ? `bi-heart`
                                        : `bi-heart-fill`
                                    }  `}
                                    style={{
                                      color: "#ff5700",
                                      fontSize: "13px",
                                    }}
                                    onClick={() => {
                                      pushProductItem(product);
                                      newWishOffer.findIndex(
                                        (elem: any) => elem.id === product?.id
                                      ) == -1
                                        ? presentToast(
                                            "Product Removed Successfully"
                                          )
                                        : presentToast(
                                            "Product Added Successfully"
                                          );
                                      window.location.reload();
                                    }}
                                  ></i>

                                  {/* <img className="product-position" src={products} alt="product" /> */}
                                </div>
                                <p className="offercard-desc offercard2">
                                  {HandleTranslation(
                                    product?.description,
                                    product?.descriptionAr
                                  )}
                                </p>
                                <Link
                                  to={`/product/${product?.id}`}
                                  className="btn btn-sm btn-primary btn-block blue-btn"
                                >
                                  {t("buy")}
                                </Link>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <NodataContainer />
                      )}
                    </div>
                  </div>
                  <div className="tab-pane fade" id="branch" role="tabpanel">
                    <div className="row text-center">
                      {merchantDetails?.merchantDetails?.Branches.length !==
                      0 ? (
                        merchantDetails?.merchantDetails?.Branches?.map(
                          (branch: any, i: any) => (
                            <div
                              key={branch?.id}
                              className={`col-12 mt-2  ${
                                branchId === branch?.id ? "nav-box" : "nav-box2"
                              }`}
                              onClick={() => {
                                setBranchId(branch?.id);
                              }}
                            >
                              <div className="row">
                                <div className="col-2 p-2 text-left">
                                  <img
                                    src={navgreen}
                                    alt="navgreen"
                                    className=""
                                  />
                                </div>
                                <div className="col-8 px-0">
                                  <h5>{branch?.branchName}</h5>
                                  <p>
                                    {HandleTranslation(
                                      branch?.address,
                                      branch?.addressAr
                                    )}{" "}
                                    {t("telephone")} :{" "}
                                    <span className="green">
                                      {HandleTranslation(
                                        branch?.phone,
                                        Number(branch?.phone).toLocaleString(
                                          "ar-u-nu-arab"
                                        )
                                      )}
                                    </span>
                                  </p>
                                </div>
                                <div
                                  className="col-2 p-2"
                                  data-bs-toggle="offcanvas"
                                  data-bs-target="#actionSheetContent7"
                                  onClick={() => {
                                    setBranchPosition(branch);
                                  }}
                                >
                                  <img src={naveg} alt="naveg" className="" />
                                </div>
                              </div>
                            </div>
                          )
                        )
                      ) : (
                        <NodataContainer />
                      )}
                      <div
                        className="offcanvas offcanvas-bottom action-sheet section"
                        id="actionSheetContent7"
                      >
                        <div className="offcanvas-header">
                          <h5 className="offcanvas-title">
                            <a
                              href="#"
                              className=""
                              data-bs-dismiss="offcanvas"
                            ></a>
                          </h5>
                        </div>
                        <div className="offcanvas-body">
                          <div className="action-sheet-content">
                            <div className="map-dimenssions">
                              <Map branch={branchPosition} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </IonContent>
      <Footer />
    </IonPage>
  );
};

export default MerchantDetailsPage;

const Marker = ({ text }: any) => {
  return (
    <div className="SuperAwesomePin">
      <img
        height="25"
        width="25"
        src="https://img.icons8.com/color/48/000000/marker--v1.png"
        alt="location"
      />
    </div>
  );
};
