import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { TRANSLATIONS_EN } from "./en.js";
import { TRANSLATIONS_AR } from "./ar.js";
import LanguageDetector from "i18next-browser-languagedetector";

// the translations
// (tip move them in a JSON file and import them)

i18n
  .use(initReactI18next)
  .use(LanguageDetector) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: TRANSLATIONS_EN,
      },
      ar: {
        translation: TRANSLATIONS_AR,
      },
    },
    lng: localStorage.getItem("i18nextLng") || "en",
    detection: {
      order: ["localStorage"],
    },
    nsSeparator: false,
    keySeparator: ".", // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
