import React from 'react'
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { Footer } from '../../components/Footer';
import rightarrow from "../../assets/images/arrow-right.svg";
import arrow from "../../assets/images/arrowback.svg";
import  HeaderContainer  from '../../components/Header';
import { NodataContainer } from "../../components/ActionSheets/NoData";

const ChatbotPage: React.FC = () => {

  


  return (
    <IonPage>
  <IonContent className="setting-bg" fullscreen> 

  <div id="appCapsule"  >
  <HeaderContainer headerTitle="Chatbot"/> 
        <div className="section  merchant-list page-footer">
        </div>
        </div>
        
          <Footer />
          </IonContent>
</IonPage>
  )
}

export default ChatbotPage

