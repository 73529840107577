import React from "react";

type Props = {
  message: any;
};

export const FormError: React.FC<Props> = ({ message }) => {
  return (
    <div className="invalid-feedback d-block first-letter-capitalized">
      {message}
    </div>
  );
};

export default FormError;
