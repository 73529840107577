import axios, { AxiosError, AxiosResponse } from "axios";

const AxiosApi = axios.create({
  headers: {
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
    "Access-Control-Allow-Headers":
      "Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length",
  },
});

AxiosApi.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    console.log();
    if (error.response?.status === 401) {
      localStorage.clear();
      window.location.href = "/login";
      throw error;
    } else if (error) {
      throw error;
    }
  }
);

export default AxiosApi;
