import {
  IonItem,
  IonList,
  IonSelect,
  IonSelectOption,
  useIonToast,
} from "@ionic/react";
import AxiosApi from "../../utils/axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { baseUrl } from "../../utils/config";
import arrow from "../../assets/images/arrowback.svg";
import circles from "../../assets/images/fading.gif";
import defaultcolorticket from "../../assets/images/defaultcolorticket.png";
const CreateTicketForm = ({ addTicket }: any) => {
  const token = localStorage.getItem("token");
  const [selectOfferData, setOfferSelectData] = useState<any>();
  const [selectProductData, setSelectProductData] = useState<any>();
  const [loading, setLoading] = useState<any>(false);
  const [getTypeValue, setGetTypeValue] = useState("general");
  const [typeId, setTypeId] = useState<any>();

  const [ticketMessage, setTicketMessage] = useState();
  const options = [{ name: "general" }, { name: "offer" }, { name: "product" }];
  const [present] = useIonToast();
  const presentToast = (message: any) => {
    present({
      message: message,
      duration: 1000,
      position: "bottom",
    });
  };

  useEffect(() => {
    getTypeValue == "offer" && setLoading(true);
    AxiosApi({
      url: `${baseUrl}/api/user/list/req`,
      method: "post",
      data: { token },
    }).then((res) => {
      setOfferSelectData(res?.data.data.rows);
      setLoading(false);
    });
  }, [getTypeValue, token]);
  useEffect(() => {
    getTypeValue === "product" && setLoading(true);
    AxiosApi.post(`${baseUrl}/api/user/product/req/list`, { token }).then(
      (res: any) => {
        setSelectProductData([...res.data.data.productRequests.rows]);
        setLoading(false);
      }
    );
  }, [getTypeValue, token]);

  const HandleCreateTicket = (e: any) => {
    e.preventDefault();
    try {
      AxiosApi.post(`${baseUrl}/api/user/ticket/create`, {
        token,
        portalType: "user",
        ticketType: getTypeValue,
        ticketTypeId: typeId,
        ticketBody: ticketMessage,
      }).then(() => {
        presentToast("Ticket Created Successfuly");
        addTicket(false);
      });
    } catch (err: any) {
      presentToast(err?.response?.data.message);
    }
  };

  return (
    <div className="mx-auto">
      <div className=" p-3 text-center w-100 mt-5">
        <div className="menu-size">
          <div className="d-flex  mt-3 py-1">
            <div className="align-self-center">
              <Link
                to="#"
                onClick={() => {
                  addTicket(false);
                }}
                className="ps-1"
                shadow-0=""
              >
                <img src={arrow} alt="back" />
              </Link>
            </div>
            <div className="align-self-center ms-3">
              <h1
                className="m-0 main-clr"
                style={{
                  fontWeight: "700",
                  fontSize: "24px",
                  lineHeight: "30px",
                }}
              >
                Create Ticket
              </h1>
            </div>
          </div>
        </div>
        <form className="text-center">
          <IonList className="bg-transparent0">
            <IonItem className="placeholder:bg-white w-100">
              <div className="bg-white w-100">
                <IonSelect
                  interface="action-sheet"
                  placeholder="Choose Type Of Ticket"
                  className="w-100"
                  onIonChange={(e: any) => {
                    setGetTypeValue(e.detail.value);
                  }}
                >
                  {options?.map((option: any) => (
                    <div key={option?.name}>
                      <IonSelectOption value={option.name} key={option}>
                        {option.name}
                      </IonSelectOption>
                    </div>
                  ))}
                </IonSelect>
              </div>
            </IonItem>
          </IonList>
          <div className="my-3"></div>
          {loading && getTypeValue === "offer" ? (
            <img src={circles} alt="circles" />
          ) : getTypeValue === "offer" ? (
            <IonList className="bg-transparent">
              <IonItem className="placeholder:bg-white w-100">
                <div className="bg-white w-100">
                  <IonSelect
                    interface="action-sheet"
                    placeholder={"Choose Your Offer"}
                    className="w-100"
                    onIonChange={(e: any) => {
                      setTypeId(e.detail.value);
                    }}
                  >
                    {selectOfferData?.map((data: any) => (
                      <div key={data?.id}>
                        <IonSelectOption value={data?.id} key={data?.id}>
                          {data?.offerDetail.title}
                        </IonSelectOption>
                      </div>
                    ))}
                  </IonSelect>
                </div>
              </IonItem>
            </IonList>
          ) : null}
          {loading && getTypeValue === "product" ? (
            <img src={circles} alt="circles" />
          ) : getTypeValue === "product" ? (
            <IonList className="bg-transparent">
              <IonItem className="placeholder:bg-white w-100">
                <div className="bg-white w-100">
                  <IonSelect
                    interface="action-sheet"
                    placeholder={"Choose Your Product"}
                    className="w-100"
                    onIonChange={(e: any) => {
                      setTypeId(e.detail.value);
                    }}
                  >
                    {selectProductData?.map((data: any) => (
                      <div key={data?.id}>
                        <IonSelectOption value={data?.id} key={data?.id}>
                          {data?.Product?.name}
                        </IonSelectOption>
                      </div>
                    ))}
                  </IonSelect>
                </div>
              </IonItem>
            </IonList>
          ) : null}
          <div className="text-center p-0">
            <textarea
              placeholder="Type Your Ticket Here ...."
              rows={10}
              required
              cols={40}
              className="form-control mt-3"
              id="exampleFormControlTextarea1"
              onChange={(e: any) => {
                setTicketMessage(e.target.value);
              }}
            />
          </div>
          <div className="text-center w-100 ">
            <button
              type="submit"
              onClick={HandleCreateTicket}
              className="btn btn-primary btn-block btn-lg mt-3"
            >
              <img
                src={defaultcolorticket}
                style={{
                  width: "21px",
                  marginRight: "5px",
                }}
                alt="logo"
              />
              Create
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default CreateTicketForm;
