import { IonContent, IonPage, useIonToast } from "@ionic/react";
import AxiosApi from "../../utils/axios"
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { Footer } from "../../components";
import { LoadingContainer } from "../../components/ActionSheets/LoadingAction";
import HeaderContainer from "../../components/Header";
import { baseUrl } from "../../utils/config";
import methods from "../../assets/images/methods.svg";
import mastercard from "../../assets/images/mastercard.png";
import visa from "../../assets/images/visa.png";
import cash from "../../assets/images/cash.png";

const MyDebtsDetails = () => {
  const [present] = useIonToast();
  const presentToast = (message: any) => {
    present({
      message: message,
      duration: 3000,
      position: "bottom",
    });
  };
  const history = useHistory();
  const token = localStorage.getItem("token");
  const [loadingset, setLoadingset] = useState(false);
  const [details, setDetails] = useState<any>();
  const [cardNum, setCardNum] = useState<any>();
  const [dueDate, setDueDate] = useState<any>();
  const [showmore, setShowMore] = useState<any>(false);
  const [cardlist, setCardList] = useState<any>();
  const [handleShowingCardNum, setHandleShowCardNum] = useState<any>(1);
  const [cardId, setCardId] = useState<any>();

  useEffect(() => {
    setCardId(cardlist?.filter((card: any) => card?.isDefault == true)[0]?.id);
  }, [cardlist]);

  useEffect(() => {
    let dueDate = localStorage.getItem("debtMonth");
    setDueDate(dueDate);
  }, []);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const MyDebtsDetails = useCallback(() => {
    setLoadingset(true);
    try {
      AxiosApi
        .post(`${baseUrl}/api/user/installment/details`, {
          token,
          dueDate,
        })
        .then((res) => {
          setDetails(res.data.data);
          setLoadingset(false);
        });
    } catch (err: any) {
      console.log(err);
      setLoadingset(false);
    }
  }, [dueDate, token]);

  useEffect(() => {
    MyDebtsDetails();
  }, [MyDebtsDetails]);

  const PayDebt = () => {
    const data = {
      token,
    };
    try {
      AxiosApi
        .post(`${baseUrl}/api/user/installment/pay`, {
          token,
          cardId: cardId,
          paymentType: "stripe",
          paymentId: "gh-ftukekqljvnnhaiith",
          dueDate: dueDate,
        })
        .then((res) => {
          presentToast("Successfully");
          reset();
          history.push("/myDebts");
          setLoadingset(false);
        })
        .then(() => {
          window.location.reload();
        });
    } catch (err: any) {
      console.log(err);
      presentToast(err?.response?.data.message);
      setLoadingset(false);
    }
  };
  useEffect(() => {
    AxiosApi({
      url: `${baseUrl}/api/user/card/list`,
      method: "post",
      data: { token },
    })
      .then((res) => {
        setCardList(
          res?.data.data.sort((a: any, b: any) =>
            a.isDefault === true ? -1 : 1
          )
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, [history, token]);

  return (
    <IonPage>
      <IonContent fullscreen>
        {loadingset ? (
          <LoadingContainer />
        ) : (
          <div id="appCapsule" className="mydebt-container">
            <HeaderContainer headerTitle="My Debts Details" />
            <div className="mydebt-details p-3 ">
              <h1 className="">{dueDate}</h1>
              {details?.map((dat: any) => (
                <Link
                  key={Math.random()}
                  to="/debtDetails"
                  className="d-flex justify-content-between list"
                >
                  <div className="d-flex align-items-center gap-2">
                    <i className="bi bi-credit-card"></i>
                    <div>
                      <p className="mb-0">{dat?.name}</p>
                    </div>
                  </div>
                  {dat?.isPayed == false ? (
                    <div className="d-flex align-items-center gap-2">
                      <button className="paynow-active">
                        {dat?.installmentAmount} $
                      </button>
                      <i className="bi bi-arrow-right-short"></i>
                    </div>
                  ) : null}
                </Link>
              ))}
            </div>
            <div className="row mt-2 p-3">
              <div className="col-12 ">
                <div className="payment-methods">
                  <div>
                    <h5 className="opacity-50 mb-3">Payment Methods</h5>
                    <div className="row">
                      {cardlist
                        ?.slice(0, handleShowingCardNum)
                        .map((card: any) => (
                          <div
                            className={`          ${
                              cardId === card?.id
                                ? "active-payment-card"
                                : "border"
                            } 
              
         
                       
                       row mb-2 align-items-center p-2`}
                            key={card?.id}
                            onClick={() => setCardId(card?.id)}
                          >
                            <img className="col-2" src={visa} alt="visa" />
                            <div className=" col-9">
                              <p className="payment_methods-list mb-0">
                                {" "}
                                {card?.nameOnCard}
                              </p>
                              <span className="payment_methods-list">
                                {" "}
                                {card?.cardNumber}
                              </span>
                            </div>
                          </div>
                        ))}
                      {cardlist?.length > 1 &&
                        (showmore === false ? (
                          <div className="text-center">
                            <button
                              className="btn btn-primary btn-block w-75"
                              onClick={() => {
                                setShowMore(true);
                                setHandleShowCardNum(cardlist.length);
                              }}
                            >
                              <i className="bi bi-plus-circle-dotted"></i> show
                              more
                            </button>
                          </div>
                        ) : (
                          <div className="mx-auto text-center">
                            <button
                              className="btn btn-primary btn-block  w-75"
                              onClick={() => {
                                setShowMore(false);
                                setHandleShowCardNum(1);
                              }}
                            >
                              <i className="bi bi-dash-circle-dotted"></i> show
                              less
                            </button>
                          </div>
                        ))}
                      <button
                        onClick={PayDebt}
                        className="btn btn-primary btn-block btn-lg mt-5 mb-2 "
                      >
                        Pay Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </IonContent>
      <Footer />
    </IonPage>
  );
};
export default MyDebtsDetails;
