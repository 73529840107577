import React, { useEffect, useState } from "react";
import HeaderContainer from "../../components/Header";
import Offers from "./OffersList";
import {
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
  useIonToast,
} from "@ionic/react";
import { Footer } from "../../components/Footer";
import starb from "../../assets/images/dummyicon.png";
import stars from "../../assets/images/stars.svg";
import { LoadingContainer } from "../../components/ActionSheets/LoadingAction";
import qr from "../assets/images/qr.svg";
import { BarcodeScanner } from "@awesome-cordova-plugins/barcode-scanner";
import { Haptics, ImpactStyle } from "@capacitor/haptics";
import { Controller, useForm } from "react-hook-form";
import expired from "../../assets/images/expired.png";

// Import Swiper styles
import "swiper/css";
import { useHistory, useParams } from "react-router";
import AxiosApi from "../../utils/axios";
import { baseUrl } from "../../utils/config";
import { Link } from "react-router-dom";
import moment from "moment";
import { Rating } from "@mui/material";
import { HandleTranslation } from "../../hooks";
import { useTranslation } from "react-i18next";

const OfferDetails: React.FC = () => {
  const [present] = useIonToast();
  const [loadingset, setLoadingset] = useState(false);
  const history = useHistory();
  const offerId: any = useParams();
  const [details, setDetails] = useState<any>([]);
  const [branchList, setBranchList] = useState([]);
  const [merchant, setMerchantDetails] = useState<any>();
  const [offers, setOffers] = useState([]);
  const token = localStorage.getItem("token");
  const [branch, setBranch] = useState("");
  const [orderList, setOrderList] = useState<any[]>([]);
  const [reqId, setReqId] = useState();
  const [data, setData] = useState<any>();
  const { t } = useTranslation();
  const presentToast = (message: any) => {
    present({
      message: message,
      duration: 3000,
      position: "bottom",
    });
  };

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    // resolver: yupResolver(CreateUserValidation),
  });

  const hapticsVibrate = async () => {
    await Haptics.vibrate();
  };

  useEffect(() => {
    offerId.id && setLoadingset(true);
    AxiosApi({
      url: `${baseUrl}/api/public/offer/details`,
      method: "post",
      data: { offerId: offerId.id, token },
    })
      .then((res) => {
        console.log(res.data);
        setDetails(res?.data?.data);
        setBranchList(res?.data?.data?.branchs);
        setLoadingset(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingset(false);
      });
  }, [offerId, token]);

  useEffect(() => {
    details?.merchantId &&
      AxiosApi({
        url: `${baseUrl}/api/public/merchant/details`,
        method: "post",
        data: { merchantId: details?.merchantId },
      })
        .then((res) => {
          setMerchantDetails(res?.data.data.merchantDetails);
          setLoadingset(false);
        })
        .catch((err) => {
          console.log(err);
          setLoadingset(false);
        });
  }, [details?.merchantId, token]);

  useEffect(() => {
    AxiosApi({
      url: `${baseUrl}/api/public/allOffers/list`,
      method: "post",
      data: { token, limit: 300 },
    }).then((res) => {
      setOffers(res?.data?.data?.rows);
      setLoadingset(false);
    });
  }, [token]);
  useEffect(() => {
    AxiosApi({
      url: `${baseUrl}/api/user/list/req`,
      method: "post",
      data: { token },
    }).then((res) => {
      setOrderList(res?.data.data.rows);
      setLoadingset(false);
    });
  }, [token]);
  let PurchesedOffer: any = orderList.filter(
    (list: any) => list.offerId === offerId.id
  );

  const buyOffer = () => {
    if (branch === "") {
      return;
    }

    AxiosApi({
      url: `${baseUrl}/api/user/create/req`,
      method: "post",
      data: { token, offerId: offerId.id, branchId: branch },
    })
      .then((res) => {
        setReqId(res?.data.data.id);
        presentToast("You Got This Offer Successfully");
      })
      .then(() => {
        AxiosApi({
          url: `${baseUrl}/api/user/list/req`,
          method: "post",
          data: { token },
        }).then((res) => {
          setOrderList(res?.data.data.rows);
          setLoadingset(false);
        });
        PurchesedOffer = orderList.filter(
          (list: any) => list.offerId === offerId.id
        );
      })
      .catch((err: any) => {
        presentToast(err?.response?.data.message);
        console.log(err?.response?.data.message);
      });
  };

  useEffect(() => {
    const body = {
      token,
      reqId: PurchesedOffer.length === 0 ? reqId : PurchesedOffer[0].id,
    };

    body.reqId &&
      AxiosApi({
        url: `${baseUrl}/api/user/req/details`,
        method: "post",
        data: body,
      }).then((res) => {
        setData(res?.data.data);
        setLoadingset(false);
      });
  }, [PurchesedOffer.id, PurchesedOffer.length, reqId, token]);

  let date = null;
  date = data?.offerDetail.endDate && new Date(data?.offerDetail.endDate);
  let endDate = moment(date).format("DD/MM/YYYY");

  const openScanner = () => {
    AxiosApi.post(`${baseUrl}/api/user/req/approve`, {
      token,
      reqId: PurchesedOffer.length === 0 ? reqId : PurchesedOffer[0].id,
    }).then(() => {
      AxiosApi({
        url: `${baseUrl}/api/user/list/req`,
        method: "post",
        data: { token },
      }).then((res) => {
        setOrderList(res?.data.data.rows);
        setLoadingset(false);
      });
      PurchesedOffer = orderList.filter(
        (list: any) => list.offerId === offerId.id
      );
    });
  };

  const Approve = async () => {
    const data = await BarcodeScanner.scan();
    // console.log(`Barcode data: ${data.text}`);
    if (data) {
      AxiosApi.post(`${baseUrl}/api/user/req/approve`, {
        token,
        reqId: data.text,
      })
        .then(() => {
          hapticsVibrate();
          AxiosApi({
            url: `${baseUrl}/api/user/list/req`,
            method: "post",
            data: { token },
          }).then((res) => {
            setOrderList(res?.data.data.rows);
            setLoadingset(false);
          });
          PurchesedOffer = orderList.filter(
            (list: any) => list.offerId === offerId.id
          );
        })
        .catch(() => {
          window.location.reload();
        });
    }
  };

  let expiredDate =
    Date.parse(details?.endDate) < Date.parse(String(new Date()));

  function handleRefresh(event: any) {
    setTimeout(() => {
      AxiosApi({
        url: `${baseUrl}/api/public/offer/details`,
        method: "post",
        data: { offerId: offerId.id, token },
      })
        .then((res) => {
          console.log(res.data);
          setDetails(res?.data?.data);
          setBranchList(res?.data?.data?.branchs);
        })
        .catch((err) => {
          console.log(err);
        });
      AxiosApi({
        url: `${baseUrl}/api/public/merchant/details`,
        method: "post",
        data: { merchantId: details?.merchantId },
      })
        .then((res) => {
          setMerchantDetails(res?.data.data.merchantDetails);
        })
        .catch((err) => {
          console.log(err);
        });
      AxiosApi({
        url: `${baseUrl}/api/public/allOffers/list`,
        method: "post",
        data: { token, limit: 300 },
      }).then((res) => {
        setOffers(res?.data?.data?.rows);
        setLoadingset(false);
      });
      AxiosApi({
        url: `${baseUrl}/api/user/list/req`,
        method: "post",
        data: { token },
      }).then((res) => {
        setOrderList(res?.data.data.rows);
        setLoadingset(false);
      });

      event.detail.complete();
    }, 2000);
  }

  return (
    <IonPage>
      <IonContent fullscreen>
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent refreshingSpinner="circles"></IonRefresherContent>
        </IonRefresher>
        <div id="appCapsule">
          <HeaderContainer headerTitle="Offers" />
          {loadingset === true ? (
            <LoadingContainer />
          ) : (
            <div className="row p-3 merchant-det">
              <div className="text-center mt-1 ">
                <img
                  src={
                    merchant?.image === null
                      ? starb
                      : `https://api.eshailx.com/${merchant?.image}`
                  }
                  alt="logo"
                  className=" imaged w100 "
                />
              </div>

              <div className="col-8">
                <h5 className="text-left"> {merchant?.name}</h5>
                <p className="offerdetails-desc p-0">{merchant?.hotLine}</p>
                <Rating
                  name="read-only"
                  defaultValue={details?.rate}
                  readOnly
                  precision={0.5}
                />
              </div>

              <div className="ofdet-box d-flex p-0 mt-2">
                <div className="col-2 blue-box">{t("offer")}</div>
                <div className="col-10 blue-box-det text-left d-flex">
                  <p>{HandleTranslation(details?.title, details.titleAr)}</p>
                </div>
              </div>
              <div className={HandleTranslation("ltr", "rtl")}>
                <h6 className="mt-3 mb-0 product-header-desp">
                  {t("description")} :
                </h6>
                <p className="offerdetails-desc">
                  {" "}
                  {HandleTranslation(
                    details?.description,
                    details?.descriptionAr
                  )}
                </p>
              </div>
              <div>
                {!expiredDate ? (
                  PurchesedOffer.length > 0 ? (
                    <div className="mt-1 pb-3">
                      <br />{" "}
                      <span className="text-danger">
                        <div className="alert alert-info mb-1" role="alert">
                          {PurchesedOffer[0].status === "pending"
                            ? t("stillpending")
                            : PurchesedOffer[0].status === "done"
                            ? t("gothisoffer")
                            : ``}
                        </div>
                      </span>
                      <div
                        className={`div-box section pending-offer ${HandleTranslation(
                          "ltr",
                          "rtl"
                        )}`}
                      >
                        <h6 className="text-center">
                          <strong>{t("requestdetails")}</strong>
                        </h6>
                        <h6 className="div-box-text">
                          <strong>{t("branchname")}:</strong>{" "}
                          {HandleTranslation(
                            PurchesedOffer[0]?.Branch.address,
                            PurchesedOffer[0]?.Branch.addressAr
                          )}
                        </h6>
                        <hr />
                        <div className="orange ">
                          <h6 className="div-box-text">
                            {" "}
                            {HandleTranslation(
                              PurchesedOffer[0]?.offerDetail.description,
                              PurchesedOffer[0]?.offerDetail.descriptionAr
                            )}{" "}
                          </h6>
                        </div>

                        <div className="row info-list">
                          <div className="col-12">
                            <h6 className="div-box-text">
                              {" "}
                              <strong>{t("pointsearned")}: </strong>
                              {HandleTranslation(
                                PurchesedOffer[0]?.offerDetail.points,
                                Number(
                                  PurchesedOffer[0]?.offerDetail.points
                                ).toLocaleString("ar-u-nu-arab")
                              )}
                            </h6>
                          </div>
                        </div>

                        <div className="row info-list">
                          <div className="col-12">
                            <h6 className="div-box-text">
                              <strong> {t("date")}: </strong>
                              {date ? (
                                <span>
                                  {PurchesedOffer[0]?.status === "pending"
                                    ? endDate
                                    : PurchesedOffer[0] &&
                                      moment(
                                        PurchesedOffer[0]?.createdAt
                                      ).format("DD-MM-YYYY")}
                                </span>
                              ) : (
                                ``
                              )}
                            </h6>
                          </div>
                          <div className="col-12">
                            <h6 className="div-box-text-orange">
                              <strong> {t("status")}: </strong>{" "}
                              {PurchesedOffer[0]?.status}
                            </h6>
                          </div>
                        </div>
                      </div>
                      {PurchesedOffer[0]?.status === t("pending") ? (
                        <>
                          <button
                            type="submit"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#actionSheetContent"
                            className="btn btn-primary btn-block btn-lg mt-5 mb-2"
                            onClick={Approve}
                          >
                            {t("verify")} & {t("accept")}
                          </button>
                          <br />
                        </>
                      ) : (
                        ``
                      )}
                    </div>
                  ) : (
                    <form onSubmit={handleSubmit(buyOffer)}>
                      <IonList className="bg-transparent">
                        <IonItem className="placeholder:bg-white w-100">
                          <div className="bg-white w-100">
                            <IonSelect
                              interface="action-sheet"
                              placeholder="please select a branch"
                              className="w-100"
                              color=""
                              onIonChange={(e: any) =>
                                setBranch(e.detail.value)
                              }
                            >
                              {branchList &&
                                branchList.map((branch: any) => (
                                  <IonSelectOption
                                    value={branch?.id}
                                    key={branch?.id}
                                  >
                                    {HandleTranslation(
                                      branch.branchName,
                                      branch.branchNameAr
                                    )}
                                  </IonSelectOption>
                                ))}
                            </IonSelect>
                          </div>
                        </IonItem>
                      </IonList>
                      <div className="text-center w-100 ">
                        <button
                          type="submit"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#actionSheetContent"
                          className="btn btn-primary btn-block btn-lg"
                        >
                          {t("getthisoffer")}
                        </button>
                      </div>
                    </form>
                  )
                ) : (
                  <div className="text-center">
                    <img src={expired} alt="expired" />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </IonContent>
      <Footer />
    </IonPage>
  );
};

export default OfferDetails;
