import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Footer } from "../../components/Footer";
import rightarrow from "../../assets/images/arrow-right.svg";
import arrow from "../../assets/images/arrowback.svg";
import {
  NavLink,
  Switch,
  Route,
  useRouteMatch,
  Redirect,
  useLocation,
  Link,
  useHistory,
} from "react-router-dom";
import ProductRequestList from "../productrequestlist";
import { LoadingContainer } from "../../components/ActionSheets/LoadingAction";
import CashOut from "../cashout";
import { useTranslation } from "react-i18next";

const SettingsPage: React.FC = () => {
  const history = useHistory();

  const logoutfunc = () => {
    localStorage.removeItem("token");
    history.push("/login");
  };
  const { t } = useTranslation();
  return (
    <IonPage>
      <IonContent className="setting-bg" fullscreen>
        <div id="" className="my-3">
          <div className="section  merchant-list page-footer">
            <div className="section">
              <div className="row align-items-center mb-2">
                <div className="col-1">
                  <Link to="/home" className="headerButton">
                    <img className="" src={arrow} alt="heart" />
                  </Link>
                </div>
                <div className="col-6">
                  <h6 className="setting-text-header">{t("menu")}</h6>
                </div>
              </div>

              <div className="row setting-card">
                <div className="col-3">
                  <i className="bi bi-person-bounding-box icon-header"></i>
                </div>
                <div className="col-6">
                  <h6 className="setting-text">{t("welcometo")} swaplater</h6>
                  <h6 className="setting-text-white">
                    {t("getoffers")} & {t("purchaseproducts")}
                  </h6>
                </div>
                <div className="col-3">
                  <Link to="/profile">
                    <button className="btn btn-edit">{t("profile")}</button>
                  </Link>
                </div>
              </div>

              <div className="row">
                <br />
                <br />
                <div className="col">
                  <p className="setting-title">
                    {t("menu")} & {t("setting")}
                  </p>
                </div>
              </div>

              <ul className="listview image-listview">
                <li>
                  <ProductRequestList />
                </li>
                <li>
                  <Link to="/merchants">
                    <div className="item setting-list">
                      <i className="bi bi-shop icon-color"></i>
                      <div className="in">
                        <div>
                          <h6 className="setting-list-text text-left">
                            {" "}
                            {t("merchants")}
                          </h6>
                        </div>
                        <img src={rightarrow} alt="" className="float-right" />
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="/favouritetags">
                    <div className="item setting-list">
                      <i className="bi bi-bookmark-heart icon-color"></i>
                      <div className="in">
                        <div>
                          <h6 className="setting-list-text text-left">
                            {" "}
                            {t("favtags")}
                          </h6>
                        </div>
                        <img src={rightarrow} alt="" className="float-right" />
                      </div>
                    </div>
                  </Link>
                </li>
              </ul>

              <div className="row">
                <div className="col">
                  <br />
                  <p className="setting-title">
                    {t("profile")} & {t("setting")}
                  </p>
                </div>
              </div>
              <ul className="listview image-listview pb-3">
                {/* <li>
                  <CashOut />
                </li> */}
                <li>
                  <Link to="/profile">
                    <div className="item setting-list">
                      <i className="bi bi-person icon-color"></i>
                      <div className="in">
                        <div>
                          <h6 className="setting-list-text text-left">
                            {" "}
                            {t("profile")}
                          </h6>
                        </div>
                        <img src={rightarrow} alt="" className="float-right" />
                      </div>
                    </div>
                  </Link>
                </li>

                <li>
                  <Link to="/notifications">
                    <div className="item setting-list">
                      <i className="bi bi-bell icon-color"></i>
                      <div className="in">
                        <div>
                          <h6 className="setting-list-text text-left">
                            {" "}
                            {t("notification")}
                          </h6>
                        </div>
                        <img src={rightarrow} alt="" className="float-right" />
                      </div>
                    </div>
                  </Link>
                </li>

                <li>
                  <Link to="/favourites">
                    <div className="item setting-list">
                      <i className="bi bi-heart icon-color"></i>
                      <div className="in">
                        <div>
                          <h6 className="setting-list-text text-left">
                            {" "}
                            {t("favourites")}
                          </h6>
                        </div>
                        <img src={rightarrow} alt="" className="float-right" />
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="/tickets">
                    <div className="item setting-list">
                      <i className="bi bi-ticket icon-color" />
                      <div className="in">
                        <div>
                          <h6 className="setting-list-text text-left">
                            {" "}
                            {t("tickets")}
                          </h6>
                        </div>
                        <img src={rightarrow} alt="" className="float-right" />
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="/paymentmethods">
                    <div className="item setting-list">
                      <i className="bi bi-credit-card-2-back icon-color"></i>
                      <div className="in">
                        <div>
                          <h6 className="setting-list-text text-left">
                            {" "}
                            {t("paymentmethods")}
                          </h6>
                        </div>
                        <img src={rightarrow} alt="" className="float-right" />
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="/mydebts">
                    <div className="item setting-list">
                      <i className="bi bi-credit-card-2-front icon-color"></i>

                      <div className="in">
                        <div>
                          <h6 className="setting-list-text text-left">
                            {" "}
                            {t("mydebts")}{" "}
                          </h6>
                        </div>
                        <img src={rightarrow} alt="" className="float-right" />
                      </div>
                    </div>
                  </Link>
                </li>

                <li>
                  <Link to="/changelanguage">
                    <div className="item setting-list">
                      <i className="bi bi-clock-history icon-color"></i>
                      <div className="in">
                        <div>
                          <h6 className="setting-list-text text-left">
                            {" "}
                            {t("changelang")}{" "}
                          </h6>
                        </div>
                        <img src={rightarrow} alt="" className="float-right" />
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="/changepassword">
                    <div className="item setting-list">
                      <i className="bi bi-clock-history icon-color"></i>
                      <div className="in">
                        <div>
                          <h6 className="setting-list-text text-left">
                            {" "}
                            {t("changepw")}{" "}
                          </h6>
                        </div>
                        <img src={rightarrow} alt="" className="float-right" />
                      </div>
                    </div>
                  </Link>
                </li>

                <li>
                  <div className="item setting-list" onClick={logoutfunc}>
                    <i className="bi bi-gear icon-color"></i>
                    <div className="in">
                      <div>
                        <h6 className="setting-list-text text-left">
                          {" "}
                          {t("logout")}
                        </h6>
                      </div>
                      <img src={rightarrow} alt="" className="float-right" />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </IonContent>
      <Footer />
    </IonPage>
  );
};

export default SettingsPage;
