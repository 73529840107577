import { Route, Switch } from "react-router-dom";
import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

import { ProtectedRoute } from "./components/routes";
import SignUpPage from "./pages/register/Register";
import LoginPage from "./pages/login/Login";
import WelcomePage from "./pages/welcome/Welcome";
import HomePage from "./pages/home/index";
import MerchantPage from "./pages/merchants/Merchants";
import MerchantDetailsPage from "./pages/merchants/MerchantDetails";
import OffersPage from "./pages/offers/Offers";
import OfferDetailsPage from "./pages/offers/OfferDetails";
import ProductsPage from "./pages/products/Products";
import SettingsPage from "./pages/settings/Settings";
import NotificationsPage from "./pages/notifications/Notifications";
import ChatbotPage from "./pages/chatbot/Chatbot";
import FavouritesPage from "./pages/favourites";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import ForgotPasswordPage from "./pages/forgotpassword";
import ChangePasswordPage from "./pages/changepassword";
import ProductDetailsPage from "./pages/productdetails";
import ProfilePage from "./pages/profile";
import AddAddressForm from "./components/ActionSheets/AddAddressForm";
import UpDateAddressForm from "./components/ActionSheets/UpdateAddressForm";
import CheckOutPage from "./pages/checkout";
import MyDeptsPage from "./pages/mydebts";
import MyDebtsDetails from "./pages/mydebts/MyDebtsDetails";
import FavouriteTags from "./pages/favouritetags";
import AddPaymentMethod from "./pages/addpaymentmethod";
import PaymentMethods from "./pages/paymentmethods/paymentmethods";

import OrderSummary from "./pages/OrderSummary/OrderSummary";
import TransactionDetails from "./pages/Transactions/TransactionsDetails";
import Transactions from "./pages/Transactions/index";
import PushNotification from "./utils/pushToken";
import { Capacitor } from "@capacitor/core";
import TicketList from "./pages/tickets/TicketList";
import Tickets from "./pages/tickets";
import TicketDetails from "./pages/tickets/TicketDetails";
import i18n from "./i18n/i18n";
import { useEffect } from "react";
import Changelang from "./pages/changelanguage";
const isPushNotificationsAvailable =
  Capacitor.isPluginAvailable("PushNotifications");

setupIonicReact();
const App: React.FC = () => {
  return (
    <IonApp>
      <IonReactRouter>
        {isPushNotificationsAvailable && <PushNotification />}
        <IonRouterOutlet>
          <Switch>
            <Route exact path="/register">
              <SignUpPage />
            </Route>

            <Route exact path="/login">
              <LoginPage />
            </Route>

            <ProtectedRoute exact path="/offers">
              <OffersPage />
            </ProtectedRoute>
            <ProtectedRoute exact path="/changelanguage">
              <Changelang />
            </ProtectedRoute>
            <ProtectedRoute exact path="/favourites">
              <FavouritesPage />
            </ProtectedRoute>
            <ProtectedRoute exact path="/favouritetags">
              <FavouriteTags />
            </ProtectedRoute>

            <ProtectedRoute exact path="/chatbot">
              <ChatbotPage />
            </ProtectedRoute>
            <ProtectedRoute exact path="/addpaymentmethod">
              <AddPaymentMethod />
            </ProtectedRoute>

            <ProtectedRoute exact path="/paymentmethods">
              <PaymentMethods />
            </ProtectedRoute>

            <ProtectedRoute exact path="/transactions">
              <Transactions />
            </ProtectedRoute>
            <ProtectedRoute exact path="/transaction/details/:id">
              <TransactionDetails />
            </ProtectedRoute>

            <ProtectedRoute exact path="/products">
              <ProductsPage />
            </ProtectedRoute>

            <ProtectedRoute exact path="/merchants">
              <MerchantPage />
            </ProtectedRoute>

            <ProtectedRoute exact path="/merchantdetails/:id">
              <MerchantDetailsPage />
            </ProtectedRoute>

            <ProtectedRoute exact path="/setting">
              <SettingsPage />
            </ProtectedRoute>

            <ProtectedRoute exact path="/notifications">
              <NotificationsPage />
            </ProtectedRoute>

            <ProtectedRoute exact path="/offers/:id">
              <OfferDetailsPage />
            </ProtectedRoute>

            <ProtectedRoute exact path="/home">
              <HomePage />
            </ProtectedRoute>
            <ProtectedRoute exact path="/product/:id">
              <ProductDetailsPage />
            </ProtectedRoute>
            <Route exact path="/forgotpassword">
              <ForgotPasswordPage />
            </Route>
            {/* <ProtectedRoute exact path="/addnewaddress">
            <AddAddressForm />
          </ProtectedRoute> */}
            <ProtectedRoute exact path="/changeaddress">
              <UpDateAddressForm />
            </ProtectedRoute>
            <ProtectedRoute exact path="/profile">
              <ProfilePage />
            </ProtectedRoute>
            <ProtectedRoute exact path="/checkout">
              <CheckOutPage />
            </ProtectedRoute>
            <ProtectedRoute exact path="/mydebts">
              <MyDeptsPage />
            </ProtectedRoute>
            <ProtectedRoute exact path="/tickets">
              <Tickets />
            </ProtectedRoute>
            <ProtectedRoute exact path="/ticket/:id">
              <TicketDetails />
            </ProtectedRoute>
            <ProtectedRoute exact path="/debtDetails">
              <MyDebtsDetails />
            </ProtectedRoute>

            <ProtectedRoute exact path="/changepassword">
              <ChangePasswordPage />
            </ProtectedRoute>
            <Route exact path="/">
              <LoginPage />
            </Route>
          </Switch>
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
