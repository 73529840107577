import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonFooter,
  IonList,
  IonCard,
  IonCardContent,
  IonItem,
  IonLabel,
  IonListHeader,
  IonText,
  useIonToast,
} from "@ionic/react";
import {
  PushNotificationSchema,
  PushNotifications,
  Token,
  ActionPerformed,
} from "@capacitor/push-notifications";

import api from "../services/api";
import { useHistory } from "react-router-dom";

export default function PushNotification() {
  const nullEntry: any[] = [];
  const [notifications, setnotifications] = useState(nullEntry);
  const history = useHistory();
  const [present] = useIonToast();

  useEffect(() => {
    console.log("-----".repeat(25));
    console.log(JSON.stringify(notifications));
    console.log("-----".repeat(25));

    PushNotifications.checkPermissions().then((res) => {
      if (res.receive !== "granted") {
        PushNotifications.requestPermissions().then((res) => {
          if (res.receive === "denied") {

            present({
                message: "Push Notification permission denied",
                duration: 1500,
                position: 'bottom'
              });
          } else {

            present({
                message: "Push Notification permission granted",
                duration: 1500,
                position: 'bottom'
              });
            register();
          }
        });
      } else {
        register();
      }
    });
  }, []);

  useEffect(() => {
    console.log("-----".repeat(25));
    console.log(JSON.stringify(notifications));
    console.log("-----".repeat(25));

    // showToast(notifications[0].body)
  }, [notifications]);

  const register = () => {
    // Register to receive push notifaction
    PushNotifications.register();

    // On success, we should be able to receive notifications
    PushNotifications.addListener("registration", (token: Token) => {
      present({
        message: "Push registration success",
        duration: 1500,
        position: 'bottom'
      });
      // save token to the user
      console.log("$$$$$".repeat(25));
      console.log(JSON.stringify(token));
      console.log("$$$$$".repeat(25));
      token && localStorage.setItem("pushToken", `${token.value}`);
    });

    // issue with the pushNotifications registering
    PushNotifications.addListener("registrationError", (error: any) => {
      alert("Error on registration: " + JSON.stringify(error));
    });

    // listen to notifications when the app is running or on the background
    PushNotifications.addListener(
      "pushNotificationReceived",
      (notification: PushNotificationSchema) => {
        setnotifications((notifications) => [
          ...notifications,
          {
            id: notification.id,
            title: notification.title,
            body: notification.body,
            targetId: notification.data?.id,
            type: notification.data?.type,
          },
        ]);
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener(
      "pushNotificationActionPerformed",
      (notification: ActionPerformed) => {
        setnotifications((notifications) => [
          ...notifications,
          {
            id: notification.notification.data.id,
            title: notification.notification.data.title,
            body: notification.notification.data.body,
            type: "action",
          },
        ]);
        history.push(`/product/${notification.notification.data?.id}`);
      }
    );
  };

  return (
    <IonPage id="main">
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle slot="start">Push Notifications</IonTitle>
        </IonToolbar>
        <IonToolbar color="light">
          <IonTitle slot="start">SwapLater</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <div>
          <IonList>
            <IonCard>
              <IonCardContent>register for notifications</IonCardContent>
            </IonCard>
          </IonList>
        </div>
        <IonListHeader mode="ios" lines="full">
          <IonLabel>Notifications</IonLabel>
        </IonListHeader>
        {notifications.length !== 0 && (
          <IonList>
            {notifications.map((notification: any) => (
              <IonItem key={notification.id}>
                <IonLabel>
                  <IonText>
                    <h3 className="notif-title">{notification.title}</h3>
                  </IonText>
                  <p>{notification.body}</p>
                  {notification.type === "foreground" && (
                    <p>This data was received in foreground</p>
                  )}
                  {notification.type === "action" && (
                    <p>This data was received on tap</p>
                  )}
                </IonLabel>
              </IonItem>
            ))}
          </IonList>
        )}
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButton color="success" expand="full" onClick={register}>
            Register for Notifications
          </IonButton>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
}
