import { ErrorMessage } from "@hookform/error-message";
import { IonItem, IonList, IonSelect, IonSelectOption } from "@ionic/react";
import AxiosApi from "../../utils/axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { baseUrl } from "../../utils/config";
import FormError from "../FormError";
import { useTranslation } from "react-i18next";

const CreateProfileForm = ({ update }: any) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [genderType, setGender] = useState();
  const token = localStorage.getItem("token");
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(CreateUserValidation),
  });
  const gender = [
    { label: "male", id: Math.random() },
    { label: "female", id: Math.random() },
  ];
  const onSubmit = (data: any) => {
    if (!genderType) {
      return;
    }
    data.gender = genderType;
    AxiosApi.post(`${baseUrl}/api/user/create/userProfile`, { ...data, token })
      .then((res: any) => {
        reset();
        update(true);
      })
      .catch((err: any) => {
        console.log(err?.response?.data.message);
      });
  };

  return (
    <div className="content mt-0">
      <form onSubmit={handleSubmit(onSubmit)} className="px-3 text-left">
        <div className="form-group boxed">
          <label className="login-label">{t("age")}</label>
          <div className="input-wrapper">
            <input
              type="number"
              className="form-control login-input"
              placeholder="age"
              {...register("age", {
                required: "this field is required",
              })}
            />
            <ErrorMessage errors={errors} name="age" render={FormError} />
          </div>
        </div>
        <div className="pb-1"></div>
        <IonList className="bg-transparent">
          <IonItem className="placeholder:bg-white w-100">
            <div className="bg-white w-100">
              <IonSelect
                interface="action-sheet"
                placeholder={t("gender")}
                className="w-100"
                color=""
                onIonChange={(e: any) => setGender(e.detail.value)}
              >
                {gender.map((gender: any) => (
                  <IonSelectOption value={gender.label} key={gender?.id}>
                    {gender.label}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </div>
          </IonItem>
        </IonList>
        {/* <div className="form-group boxed">
          <label className="login-label">Gender</label>
          <div className="input-wrapper">
            <input
              type="text"
              className="form-control login-input"
              placeholder="gender"
              {...register("gender", {
                required: "this field is required",
              })}
            />
            <ErrorMessage errors={errors} name="gender" render={FormError} />
          </div>
        </div> */}
        <div className="pb-1"></div>
        <div className="form-group boxed">
          <label className="login-label">{t("nationality")}</label>
          <div className="input-wrapper">
            <input
              type="text"
              className="form-control login-input"
              placeholder={t("nationality")}
              {...register("nationality", {
                required: "this field is required",
              })}
            />
            <ErrorMessage
              errors={errors}
              name="nationality"
              render={FormError}
            />
          </div>
        </div>

        <button
          className=" btn btn-primary btn-block btn-lg mt-3"
          style={{ width: "100%" }}
        >
          {t("submit")}
        </button>
      </form>
    </div>
  );
};
export default CreateProfileForm;
