/* eslint-disable no-case-declarations */
import { UPDATE_CART, CLEAR_CART, UPDATE_WISHLIST, CLEAR_WISHLIST } from "../types";

const INITIAL_STATE = {
  cart: [],
  wishlist: [],
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case UPDATE_CART:
      console.log("action", action);
      return { ...state, cart: action.data };
    case CLEAR_CART:
      return { ...state, cart: [] };
    case UPDATE_WISHLIST:
      return { ...state, wishlist: action.data };
    case CLEAR_WISHLIST:
      return { ...state, wishlist: [] };
    default:
      return state;
  }
};
