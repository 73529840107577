import {
  IonContent,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  useIonToast,
} from "@ionic/react";
import { Footer } from "../../components";
import HeaderContainer from "../../components/Header";
import product from "../../assets/images/products.svg";
import van from "../../assets/images/van.png";
import AxiosApi from "../../utils/axios";
import { baseUrl } from "../../utils/config";
import { useEffect, useState } from "react";
import fries from "../../assets/images/fries.svg";
import AddPointsActionSheet from "../../components/ActionSheets/AddPointsActionSheet";
import { useHistory, useLocation } from "react-router";
import OrderSummary from "../OrderSummary/OrderSummary";
import AddPointsElement from "../../components/stripe/addPointsElement";
import { HandleTranslation } from "../../hooks";
import { NodataContainer } from "../../components/ActionSheets/NoData";
import { useTranslation } from "react-i18next";

const CheckOutPage = () => {
  let addres: any = localStorage.getItem("adress");
  let address = JSON.parse(addres);
  let branch: any = localStorage.getItem("branch");
  let branches = JSON.parse(branch);
  const [loadingset, setLoadingset] = useState(false);
  const history = useHistory();
  const [installments, setinstallments] = useState<any>([]);
  const [selectInstallment, setSelectIntsallment] = useState();
  const [planId, setPlanId] = useState<any>();
  const [user, setUser] = useState<any>();
  const [orderSummary, setOrderSummary] = useState(false);
  const [summaryData, setSummaryData] = useState<any>();
  const [method, setMethod] = useState("cash");
  const [Data, setData] = useState<any>();
  const [checkout, setCheckOut] = useState<any>();
  const location = useLocation();
  const [showmore, setShowMore] = useState<any>(false);
  const [cardlist, setCardList] = useState<any>();
  const [update, isUpdate] = useState(!orderSummary);
  const { t } = useTranslation();
  useEffect(() => {
    const productData: any = localStorage.getItem("productData");
    let Data = JSON.parse(productData);
    setData(Data);
    console.log(Data);
  }, [location.key]);
  useEffect(() => {
    const checkoutData: any = localStorage.getItem("checkoutData");
    let checkout = JSON.parse(checkoutData);
    setCheckOut(checkout);
    console.log(checkout);
  }, [location.key]);

  const token = localStorage.getItem("token");
  const [present] = useIonToast();
  const presentToast = (message: any) => {
    present({
      message: message,
      duration: 3000,
      position: "bottom",
    });
  };

  useEffect(() => {
    setOrderSummary(false);
  }, [checkout]);
  useEffect(() => {
    setLoadingset(true);
    const planList = setTimeout(() => {
      AxiosApi({
        url: `${baseUrl}/api/user/company/plan/list`,
        method: "post",
        data: { token },
      })
        .then((res: any) => {
          setinstallments(res?.data.data.rows);
          setLoadingset(false);
        })
        .catch((err) => {
          setLoadingset(false);
          console.log(err);
        });
    }, 100);
    return () => clearTimeout(planList);
  }, [token]);
  let checkBalance = Number(user?.balance) < Number(Data?.points);
  let checkInstallment =
    Number(user?.AgentEmployees?.installmentPointsBalance) <
    Number(Data?.price);

  const CheckOut = () => {
    if (method === "cash" && checkBalance) {
      return;
    }
    if (method === "installments" && checkInstallment) {
      return presentToast("please check your installment balance");
    }
    if (method === "installments" && !planId) {
      return presentToast("please choose a plan");
    }
    if (method === "cash") {
      checkout.paymentType = "cash";
    } else {
      checkout.paymentType = "installments";
    }

    if (checkout.paymentType === "installments") {
      checkout.planId = planId;
    }

    AxiosApi.post(`${baseUrl}/api/user/product/req/create`, { ...checkout })
      .then((res: any) => {
        presentToast("Thank you for purchase product");
        setSummaryData(res?.data.data);
      })
      .then(() => {
        setOrderSummary(true);
      })
      .catch((err: any) => {
        console.log(err);
        presentToast(err?.response?.data?.message);
        setOrderSummary(false);
      });
  };

  useEffect(() => {
    AxiosApi({
      url: `${baseUrl}/api/user/me`,
      method: "post",
      data: { token },
    })
      .then((res) => {
        setUser(res?.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token]);

  function handleRefresh(event: any) {
    AxiosApi({
      url: `${baseUrl}/api/user/me`,
      method: "post",
      data: { token },
    })
      .then((res) => {
        setUser(res?.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    AxiosApi({
      url: `${baseUrl}/api/user/company/plan/list`,
      method: "post",
      data: { token },
    })
      .then((res: any) => {
        setinstallments(res?.data.data.rows);
        setLoadingset(false);
      })
      .catch((err) => {
        setLoadingset(false);
        console.log(err);
      });
  }

  useEffect(() => {
    AxiosApi({
      url: `${baseUrl}/api/user/card/list`,
      method: "post",
      data: { token },
    })
      .then((res) => {
        setCardList(
          res?.data.data.sort((a: any, b: any) =>
            a.isDefault === true ? -1 : 1
          )
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, [history, token]);

  return (
    <IonPage>
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent refreshingSpinner="circles"></IonRefresherContent>
        </IonRefresher>
        <div id="appCapsule">
          <HeaderContainer
            headerTitle={orderSummary ? t("ordersymmary") : t("checkout")}
          />
          {orderSummary ? (
            <OrderSummary orderData={summaryData} />
          ) : (
            <section className="checkout p-3">
              <div className="product-box d-flex mt-2 align-items-center ">
                <div className="img-container d-flex justify-content-center align-items-center me-1">
                  <img
                    src={
                      Data?.image === null
                        ? fries
                        : `https://api.eshailx.com/${Data?.image}`
                    }
                    alt="logo"
                  />
                </div>
                <div className="me-5 w-50">
                  <h2 className="product-name">
                    {HandleTranslation(Data?.name, Data?.nameAr)}
                  </h2>
                  <p className="product-desc">
                    {HandleTranslation(Data?.description, Data?.descriptionAr)}
                  </p>
                  {/* <i className="product-icon">icon</i> */}
                </div>
                <div className="price-box">
                  <p className="price mb-0">{t("price")}</p>
                  <span className="product-price">
                    {HandleTranslation(
                      Data?.price,
                      Number(Data?.price).toLocaleString("ar-u-nu-arab")
                    )}
                    $
                  </span>
                </div>
              </div>
              <div className="d-flex  gap-4 text-left address-box mb-1 ">
                {checkout?.type === "delivery" ? (
                  <>
                    <div className="vanbox">
                      <img alt="logo" src={van} />
                    </div>
                    <div className="d-flex flex-column">
                      <div className="d-flex  justify-content-between ">
                        <p
                          style={{
                            color: "#1C1C1C",
                            fontSize: "11px",
                            fontWeight: "500",
                          }}
                        >
                          {t("deliverytohome")}
                        </p>
                      </div>
                      <p
                        className="m-0 position relative d-flex gap-2"
                        style={{
                          color: "#1C1C1C",
                          fontSize: "9px",
                        }}
                      >
                        <i
                          className="bi bi-geo-alt-fill "
                          style={{
                            color: "#211CA0",
                            fontSize: "15px",
                          }}
                        />{" "}
                        <p
                          style={{
                            color: "#1C1C1C",
                            fontSize: "9px",
                          }}
                        >
                          {checkout?.type === "delivery" &&
                            address?.Address.address1}{" "}
                          {checkout?.type === "delivery" &&
                            address?.Address.address2}
                        </p>
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="vanbox justify-content-center d-flex">
                      <i className="bi bi-geo-alt w-25"></i>
                    </div>
                    <div className="d-flex flex-column">
                      <div className="d-flex  justify-content-between ">
                        <p
                          style={{
                            color: "#1C1C1C",
                            fontSize: "11px",
                            fontWeight: "500",
                          }}
                        >
                          {t("pickupfrom")}
                        </p>
                      </div>
                      <p
                        className="m-0 position relative d-flex gap-2"
                        style={{
                          color: "#1C1C1C",
                          fontSize: "9px",
                        }}
                      >
                        <i
                          className="bi bi-geo-alt-fill "
                          style={{
                            color: "#211CA0",
                            fontSize: "15px",
                          }}
                        />{" "}
                        <p
                          style={{
                            color: "#1C1C1C",
                            fontSize: "9px",
                          }}
                        >
                          {checkout?.type === "pickup" &&
                            HandleTranslation(
                              branches?.branchName,
                              branches?.branchNameAr
                            )}{" "}
                        </p>
                      </p>
                    </div>
                  </>
                )}
              </div>

              <ul className="nav nav-tabs capsuled" role="tablist">
                <li className="nav-item">
                  <a
                    className={`nav-link ${method === "cash" ? "active" : ""} `}
                    data-bs-toggle="tab"
                    href="#Information"
                    role="tab"
                    onClick={() => setMethod("cash")}
                  >
                    {t("information")}{" "}
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      method === "installments" ? "active" : ""
                    } `}
                    data-bs-toggle="tab"
                    href="#Installment"
                    onClick={() => setMethod("installments")}
                    role="tab"
                  >
                    {t("installment")}{" "}
                  </a>
                </li>
              </ul>
              <div className="tab-content mt-1 ">
                <div
                  className="tab-pane fade active show"
                  id="Information"
                  role="tabpanel"
                >
                  <div className="information p-1">
                    <div
                      className="py-3"
                      style={{
                        borderBottom: "2px dashed #211CA0",
                      }}
                    >
                      <div className="d-flex justify-content-between">
                        <div className="mb-2">
                          <p className="main-head mb-0">
                            {t("merchantname")}:{" "}
                          </p>{" "}
                          <div className="d-flex align-items-center gap-1">
                            <img src={product} alt="img" />{" "}
                            <p className="main-det mb-0">
                              {Data?.merchant.name}
                            </p>
                          </div>
                        </div>
                        <div>
                          <p className="main-head mb-0">Points Price</p>{" "}
                          <div className="d-flex align-items-center gap-1">
                            <p className="main-det mb-0">
                              {HandleTranslation(
                                Data?.points,
                                Number(Data?.points).toLocaleString(
                                  "ar-u-nu-arab"
                                )
                              )}
                            </p>
                            P
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div>
                          {" "}
                          <p className="main-head mb-0">
                            {t("estimetedship")}:
                          </p>{" "}
                          <div className="d-flex align-items-center gap-1">
                            <i
                              style={{
                                color: "#211CA0",
                              }}
                              className="bi bi-clock"
                            ></i>{" "}
                            <p className="main-det mb-0">
                              10:00 AM, 15 June 2022
                            </p>
                          </div>
                        </div>
                        <div>
                          {" "}
                          <p className="main-head mb-0">
                            {t("deliverytype")}:
                          </p>{" "}
                          <div className="d-flex align-items-center">
                            <img
                              src={van}
                              style={{
                                width: "30px",
                              }}
                              alt="img"
                            />{" "}
                            <p className="main-det ms-1 mb-0">
                              {checkout?.type}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h1 className="subtotal">
                      {t("subtotoal")} :<span> {Data?.price} $</span>
                    </h1>
                  </div>
                </div>
                <div
                  className="tab-pane fade "
                  id="Installment"
                  role="tabpanel"
                >
                  {installments !== undefined && installments.length !== 0 ? (
                    <div className="Installment">
                      <div>
                        <p className="para-style">
                          {t("companyname")} : {installments[0]?.Company.nameEn}
                        </p>
                        <p className="para-style">
                          {t("companyaddress")} :{" "}
                          {installments[0]?.Company.addressEn}
                        </p>
                        <p className="para-style">
                          {t("companyphone")} : {installments[0].Company.phone}
                        </p>
                      </div>
                      {installments?.map((installment: any) => (
                        <>
                          <div
                            key={Math.random()}
                            className={`${
                              installment.id === selectInstallment
                                ? "active-address-box"
                                : "inactive-address-box"
                            } justify-content-between d-flex align-items-center mb-2 `}
                            onClick={() => {
                              setSelectIntsallment(installment?.id);
                              setPlanId(installment?.Plan.id);
                            }}
                          >
                            <div className="d-flex flex-column align-items-start ">
                              <p className="mb-0">
                                {installment.Plan.name} {t("installment")}
                              </p>
                              <span> {t("description")} </span>
                            </div>
                            <button>{t("select")}</button>
                          </div>
                        </>
                      ))}
                    </div>
                  ) : (
                    <p className="para-style text-center">
                      <NodataContainer />
                    </p>
                  )}
                </div>
                <button
                  onClick={CheckOut}
                  type="submit"
                  className="py-3 font-15 my-2 w-100 product-btn"
                  data-bs-toggle={
                    checkBalance && method === "cash" && "offcanvas"
                  }
                  data-bs-target={
                    checkBalance && method === "cash" && "#actionSheetContent3"
                  }
                >
                  {t("checkout")}
                </button>
              </div>
            </section>
          )}
        </div>
        <AddPointsElement user={user} noPoints={true} />
      </IonContent>
      <Footer />
    </IonPage>
  );
};

export default CheckOutPage;
