import { IonContent, IonPage } from "@ionic/react";
import AxiosApi from "../../utils/axios";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Footer } from "../../components";
import { LoadingContainer } from "../../components/ActionSheets/LoadingAction";
import HeaderContainer from "../../components/Header";
import { baseUrl } from "../../utils/config";

const TransactionDetails = () => {
  const token = localStorage.getItem("token");
  const TransactionId: any = useParams();
  const [transactionDetails, setTransactionDetails] = useState<any>();
  const [loading, setLoading] = useState(false);

  const GetTransactionsList = useCallback(() => {
    setLoading(true);
    try {
      AxiosApi.post(`${baseUrl}/api/user/transaction/details`, {
        token,
        id: TransactionId?.id,
      }).then((res) => {
        setTransactionDetails(res?.data.data);
        setLoading(false);
      });
    } catch (err: any) {
      console.log(err);
      setLoading(false);
    }
  }, [TransactionId?.id, token]);

  useEffect(() => {
    GetTransactionsList();
  }, [GetTransactionsList]);

  console.log(transactionDetails);
  return (
    <IonPage>
      <HeaderContainer headerTitle="Transaction Details" />
      <IonContent fullscreen>
        {loading ? (
          <LoadingContainer />
        ) : (
          <div id="appCapsule">
            <Link to="#" className="p-2 mt-2">
              <div className="p-3">
                <h5 className="border-bottom pb-2">Buy Points</h5>
                <p className="para-bold-style font-weight-bold mb-0">
                  Payment Type : {transactionDetails?.paymentType}
                </p>
                <p className="para-bold-style font-weight-bold mb-0">
                  Card Number : {transactionDetails?.card?.cardNumber}{" "}
                </p>
                <p className="para-bold-style font-weight-bold mb-0">
                  Card Name : {transactionDetails?.card?.nameOnCard}
                </p>

                <p className="para-bold-style font-weight-bold mb-0">
                  Date :{" "}
                  {moment(transactionDetails?.createdAt).format("DD/MM/YYYY")}{" "}
                </p>
                <p className="para-bold-style font-weight-bold mb-0">
                  Price : {transactionDetails?.price} $
                </p>
                <p className="para-bold-style font-weight-bold mb-0">
                  Amount : {transactionDetails?.points}P
                </p>
              </div>
            </Link>
          </div>
        )}
      </IonContent>
      <Footer />
    </IonPage>
  );
};

export default TransactionDetails;
