import { useState } from "react";
import GoogleMapReact from "google-map-react";

const Map = ({ branch }: any) => {
  console.log(branch)
  const defaultProps = {
    center: {
      lat: branch?.lat,
      lng: branch?.long,
    },
    zoom: 14,
  };

  return (
    <GoogleMapReact
      center={defaultProps.center}
      defaultZoom={defaultProps.zoom}
      bootstrapURLKeys={{
        key: "AIzaSyDxsUzIfLpefNwPISG4wYuo_qrrKHuMUKQ",
      }}
      yesIWantToUseGoogleMapApiInternals={true}
      onClick={(e: any) => console.log(e)}
    >
      <Marker
        lat={defaultProps.center.lat}
        lng={defaultProps.center.lng}
        text="check"
      />
    </GoogleMapReact>
  );
};

export default Map;

const Marker = ({ text }: any) => {
  return (
    <div className="SuperAwesomePin">
      <img
        height="25"
        width="25"
        src="https://img.icons8.com/color/48/000000/marker--v1.png"
        alt="location"
      />
    </div>
  );
};
