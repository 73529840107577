import { IonContent, IonPage } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoginValidation } from "../../utils/validationSchema";
import api from "../../services/api";
import { updateSpinnerStatus, loginUser } from "../../redux/actions";
import fprint from "../../assets/images/fprint.png";
import logo from "../../assets/images/logo.svg";
import { StatusBar, Style } from "@capacitor/status-bar";
import { SuccessMessageContainer } from "../../components/SuccesssMessage";
import { LoadingContainer } from "../../components/ActionSheets/LoadingAction";

import { ErrorMessage } from "@hookform/error-message";
import FormError from "../../components/FormError";
import AxiosApi from "../../utils/axios"
import { useDispatch, useSelector } from "react-redux";
import login from "../../redux/reducers/user";
import { baseUrl } from "../../utils/config";
import PhoneInput from "react-phone-input-2";
import HeaderContainer from "../../components/Header";

const ForgotPasswordPage: React.FC = () => {
  const history = useHistory();
  // const token = useSelector((state: any) => state.loginreducer.token);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const [loadingset, setLoadingset] = useState(false);
  const [phone, setPhone] = useState<any>();
  const [countryCode, setCountryCode] = useState();

  const setStatusBarStyleLight = async () => {
    await StatusBar.setStyle({ style: Style.Light });
  };
  StatusBar.setOverlaysWebView({ overlay: true });

  setStatusBarStyleLight();
  const [message, setMessage] = useState("");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = (data: any) => {
    data.countryCode = `+${countryCode}`;
    data.mobile = countryCode + phone;
    setLoadingset(true);
    AxiosApi({
      url: `${baseUrl}/api/user/forgetPassword`,
      method: "post",
      data: { ...data },
    })
      .then((res) => {
        setPhone("");
        setLoadingset(false);
        history.push("/login");
      })
      .catch((err) => {
        setMessage(err?.response?.data?.message);
        setLoadingset(false);
        setPhone("");
        console.log(err);
      });
  };

  return (
    <IonPage>
      <IonContent fullscreen>
        <HeaderContainer />
        <div className="pt-5">
          <div>
            <div className="section login-header">
              <img className="center-item" src={logo} alt="logo" />
              <h1 className="login-h1">Forgot Password</h1>
              <h6 className="login-h6">
                you will recieve a message on your
                <br /> mobile number!
              </h6>
            </div>

            {loadingset === true ? (
              <LoadingContainer />
            ) : (
              <div className="section mt-1 mb-5 header-padding">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group boxed">
                    <label className="login-label">Phone Number</label>
                    <div className="input-wrapper">
                      {/* <input
                        type="text"
                        className="form-control login-input"
                        placeholder="+96123456789"
                        {...register("username", {
                          required: "this field is required",
                        })}
                      /> */}
                      <PhoneInput
                        inputClass="form-control w-100 login-input"
                        country="qa"
                        inputProps={{
                          name: "phone",
                          required: true,
                          autoFocus: true,
                        }}
                        inputStyle={{
                          textIndent: "30px",
                        }}
                        onChange={(e: any, country: any) => {
                          setPhone(e.replace(country.dialCode, ""));
                          setCountryCode(country.dialCode);
                        }}
                      />
                      {/* <ErrorMessage
                        errors={errors}
                        name="username"
                        render={FormError}
                      /> */}
                      <i className="clear-input">
                        {/* <ion-icon name="close-circle"></ion-icon> */}
                      </i>
                    </div>
                  </div>

                  <div>
                    <br />
                    <button
                      type="submit"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#actionSheetContent"
                      className="btn btn-primary btn-block btn-lg"
                    >
                      Confirm
                    </button>
                  </div>
                  {message ? (
                    <div
                      className="alert alert-outline-danger mt-1 text-center"
                      role="alert"
                    >
                      {message}
                    </div>
                  ) : null}
                </form>
              </div>
            )}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ForgotPasswordPage;
