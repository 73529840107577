import React from "react";
import { Link, useHistory } from "react-router-dom";
import { IMAGES } from "../common";
import alert from "../assets/images/alert.svg";
import heart from "../assets/images/heart.svg";
import arrow from "../assets/images/arrowback.svg";
import bot from "../assets/images/bot.png";

type HeaderProps = {
  headerTitle?: any;
};

const HeaderContainer = ({ headerTitle }: HeaderProps) => {
  const history = useHistory();

  return (
    <div className="section full">
      <div className="appHeader transparent ">
        <div className="left">
          <Link
            to="#"
            onClick={() => history.goBack()}
            className="headerButton"
          >
            <img className="" src={arrow} alt="heart" />
          </Link>
        </div>
        <div className="main-color pageTitle header-title w-50 text-center">
          {headerTitle}
        </div>
        <div className="right">
          <Link to="/favourites" className="headerButton">
            <img className="" src={heart} alt="heart" />
          </Link>
          <Link to="/notifications" className="headerButton">
            <img className="" src={alert} alt="alert" />
          </Link>

          {/* <a className="headerButton">
            <img
              className=""
              src={bot}
              alt="bot"
              onClick={() => {
                window.botpressWebChat.sendEvent({ type: "show" });
              }}
            />
          </a> */}
        </div>
      </div>
    </div>
  );
};

export default HeaderContainer;

declare global {
  interface Window {
    // ⚠️ notice that "Window" is capitalized here
    botpressWebChat: any;
  }
}
