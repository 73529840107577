export const TRANSLATIONS_AR = {
  Register: "Create Account",
  Name: "Name",
  Mobile: "Mobile",
  Password: "Password",
  Currency: "Currency",
  Oops: "Oops",
  UnexpectedError: "Unexpected Error",
  LogIn: "Log In",
  havenotAccount: "Don't have account?",
  Alreadyhaveaccount: "Already have account?",
  LetGetStarted: "Let's Get Started",
  CreateAccount: "Create Account",
  haveanaccount: "have an account?",
  yourpoints: "عدد النقاط",
  points: "النقاط",
  total: "المجموع",
  point: "نقطة",
  managepoints: "التحكم فى النقاط",
  balance: "رصيدك",
  cash: "نقدا",
  addpoints: "أضف نقاط",
  requests: "طلبات",
  requestshistory: "سجل الطلبات",
  orderhistory: "سجل الطلبات",
  nodata: "لا يوجد شىء",
  offers: "العروض",
  products: "المنتجات",
  spend: "اشترى",
  newoffers: "عروض جديدة",
  daysleft: "أيام متبقية",
  aday: "يوم",
  hi: "أهلا",
  whatoffer: "ما العرض الذى تريده اليوم ؟",
  menu: "القائمة",
  welcometo: "مرحبا بك",
  getoffers: "أحصل على عروض",
  purchaseproducts: "أشترى منتجات",
  profile: "الملف الشخصى",
  setting: "الاعدادات",
  merchants: "التجار",
  favtags: "الأقسام المفضلة",
  notification: "الأشعارات",
  favourites: "المفضل",
  tickets: "المراسلات ",
  paymentmethods: "وسائل الدفع",
  mydebts: "كروت الدفع",
  changepw: "تغيير كلمة السر",
  logout: "تسجيل الخروج",
  cashout: "سحب النقود",
  delivery: "توصيل",
  pickup: "من الفرع",
  details: "تفاصيل",
  branches: "فروع",
  earn: "أحصل على",
  expiredoffer: "عرض منتهى",
  earnpoints: "الحصول على النقاط",
  noamount: "لا يوجد ",
  buy: "اشترى",
  telephone: "رقم الهاتف",
  getitfor: "أحصل عليه ب",
  description: "الوصف",
  branchname: "اسم الفرع",
  status: "الحاله",
  pending: "انتظار",
  accept: "قبول",
  getthisoffer: "أحصل على العرض",
  stillpending: " تحت المراجعة الرجاء التوثيق و القبول",
  gothisoffer: "انت بالفعل اديك هذا العرض تصفح سجل طلباتك",
  requestdetails: "تفاصيل الطلب",
  pointsearned: "النقاط المحصول عليها",
  date: "التاريخ",
  recomproducts: "المنتجات المرجحة",
  buynow: "اشترى الأن",
  pleasechooseadd: " من فضل اختار او اضف عنوان",
  deliverytohome: "توصيل للمنزل",
  addnewaddress: "أضف عنوان جديد",
  branchaddress: "عنوان الفرع",
  changeaddress: "تغيير العنوان",
  changebranch: "الفرع تغيير",
  ordersummary: "تفاصيل الاوردر",
  checkout: "تسجبل الاوردر",
  pickupfrom: "استلمه من",
  information: "المعلومات",
  installment: "التقسيط",
  merchantname: "اسم التاجر",
  estimetedship: "تاريخ الوصول",
  deliverytype: "نوع التوصيل",
  subtotoal: "المجموع",
  companyname: " اسم الشركة",
  companyaddress: "عنوان الشركة",
  companyphone: " رقم الشركة",
  select: "اختار",
  purchased: "اشتريت",
  ontheway: "ف الطريق",
  recieved: "تم الاستلام",
  delivered: "تو التوصيل",
  orderrecieved: "تلقينا الطلب",
  faverror: "يجب ان يكون لديك 3 عاى الاقل و 5 بالاكثر",
  submit: "تأكيد",
  addresses: "العناوين",
  change: "تغيير",
  createprofile: "تسجيل ملف شخصى",
  updateprofile: "تحديث الملف الشخصى",
  age: "السن",
  gender: "الجنس",
  nationality: "الجنسية",
  limit: "حدود",
  installmentsavailablebalance: "الرصيد المتاح للتقسيط",
  addaddress: "أضف عنوان",
  address1: "عنوان1",
  address2: "عنوان2",
  city: "المدينة",
  area: "المنطقة",
  redeempoints: "أحصل على النقاط",
  addnewticket: "أضف مراسلة جديدة",
  closeticket: "قفل المراسلة",
  closed: "أغلقت",
  ticketmessage: "المراسلات",
  tickettype: "نوع الشكوة",
  ticketnumber: "رقم الشكوة",
  paymentoptions: "تحكمات الدفع",
  personalbusiness: "عمل شخصى",
  showalltransactions: "أظهر جميع التعاملات",
  addnewcard: "أضف كرت جديد",
  currentpw: "الباسورد الحالى",
  newpw: "الباسورد الجديد",
  verifypw: "تأكيد الباسورد",
  youwillchangepw: " ستقوم بتغيير كلمة السر",
  ofyouracc: "للحساب",
  loading: "تحميل",
  pleasewait: "برجاء الانتظار",
  productreview: "مرجع المنتج",
  offerreview: "مرجع العرض",
  clicktorate: "أضغط للتقييم",
  rateproduct: "تقييم المنتج",
  rateoffer: "تقييم العرض",
  errpoints: " ليس لديك نقاط كافية للشراء",
  orbuypackage: "أو أشترى باقة",
  paymentmethodserr: "ليس لديك وسائل دفع",
  gotopayment: "اذهب لصفحة وسائل الدفع",
  showmore: "أظهر المزيد",
  showless: "أظهر أقل",
  paynow: "أدفع الأن",
  totalearned: "العدد الكلى",
  packages: "باقات",
  male: "رجل",
  female: "أنثى",
  updateaddress: "تعديل العنوان",
  changelang: "تغيير اللغة",
};
