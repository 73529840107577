import { IonContent, IonPage, useIonToast } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  ChangePasswordValidation,
  LoginValidation,
} from "../../utils/validationSchema";
import api from "../../services/api";
import { updateSpinnerStatus, loginUser } from "../../redux/actions";
import fprint from "../../assets/images/fprint.png";
import logo from "../../assets/images/logo.svg";
import { StatusBar, Style } from "@capacitor/status-bar";
import { SuccessMessageContainer } from "../../components/SuccesssMessage";
import { LoadingContainer } from "../../components/ActionSheets/LoadingAction";

import { ErrorMessage } from "@hookform/error-message";
import FormError from "../../components/FormError";
import AxiosApi from "../../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import login from "../../redux/reducers/user";
import { baseUrl } from "../../utils/config";
import { Footer } from "../../components";
import HeaderContainer from "../../components/Header";

const ChangePasswordPage: React.FC = () => {
  const token = localStorage.getItem("token");
  const history = useHistory();
  // const token = useSelector((state: any) => state.loginreducer.token);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const [loadingset, setLoadingset] = useState(false);

  const setStatusBarStyleLight = async () => {
    await StatusBar.setStyle({ style: Style.Light });
  };
  StatusBar.setOverlaysWebView({ overlay: true });

  setStatusBarStyleLight();
  const [message, setMessage] = useState("");
  const [present] = useIonToast();
  const presentToast = (message: any) => {
    present({
      message: message,
      duration: 3000,
      position: "bottom",
    });
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(ChangePasswordValidation),
  });

  const onSubmit = (data: any) => {
    setLoadingset(true);
    AxiosApi({
      url: `${baseUrl}/api/user/changePassword`,
      method: "post",
      data: { ...data, token },
    })
      .then((res) => {
        reset();
        presentToast("Password Changed Successfully");
        setLoadingset(false);
      })
      .catch((err) => {
        setMessage(err?.response?.data?.message);
        setLoadingset(false);
        presentToast(err?.response?.data?.message);
      });
  };

  const { t } = useTranslation();

  return (
    <IonPage>
      <IonContent fullscreen>
        <HeaderContainer />
        <div className="pt-5">
          <div>
            <div className="section login-header">
              <img className="center-item" src={logo} alt="logo" />
              <h1 className="login-h1">{t("changepw")}</h1>
              <h6 className="login-h6">
                {t("youwillchangepw")}
                <br />
                {t("ofyouracc")} !
              </h6>
            </div>

            {loadingset === true ? (
              <LoadingContainer />
            ) : (
              <div className="section mt-1 mb-5 header-padding">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group boxed">
                    <label className="login-label">{t("currentpw")}</label>
                    <div className="input-wrapper">
                      <input
                        type="password"
                        className="form-control login-input"
                        placeholder="******"
                        {...register("currentPassword", {
                          required: "this field is required",
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="currentPassword"
                        render={FormError}
                      />
                      <i className="clear-input">
                        {/* <ion-icon name="close-circle"></ion-icon> */}
                      </i>
                    </div>
                  </div>
                  <div className="form-group boxed">
                    <label className="login-label">{t("newpw")}</label>
                    <div className="input-wrapper">
                      <input
                        type="password"
                        className="form-control login-input"
                        placeholder="******"
                        {...register("newPassword", {
                          required: "this field is required",
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="newPassword"
                        render={FormError}
                      />
                      <i className="clear-input">
                        {/* <ion-icon name="close-circle"></ion-icon> */}
                      </i>
                    </div>
                  </div>
                  <div className="form-group boxed">
                    <label className="login-label">{t("verifypw")}</label>
                    <div className="input-wrapper">
                      <input
                        type="password"
                        className="form-control login-input"
                        placeholder="******"
                        {...register("verifyPassword", {
                          required: "this field is required",
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="verifyPassword"
                        render={FormError}
                      />
                      <i className="clear-input">
                        {/* <ion-icon name="close-circle"></ion-icon> */}
                      </i>
                    </div>
                  </div>

                  <div className="pb-5">
                    <br />
                    <button
                      type="submit"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#actionSheetContent"
                      className="btn btn-primary btn-block btn-lg mb-3"
                    >
                      {t("submit")}
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </IonContent>
      <Footer />
    </IonPage>
  );
};

export default ChangePasswordPage;
