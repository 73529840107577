import React, { useEffect, useState } from "react";
import HeaderContainer from "../../components/Header";
import { IonRefresher, IonRefresherContent, useIonToast } from "@ionic/react";

import {
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Footer } from "../../components/Footer";
import fries from "../../assets/images/fries.svg";
import stars from "../../assets/images/stars.svg";
import { LoadingContainer } from "../../components/ActionSheets/LoadingAction";
import qr from "../assets/images/qr.svg";
import { BarcodeScanner } from "@awesome-cordova-plugins/barcode-scanner";
import { Haptics, ImpactStyle } from "@capacitor/haptics";
import { Controller, useForm } from "react-hook-form";
import offerg from "../../assets/images/offerg.svg";
import product from "../../assets/images/products.svg";
import van from "../../assets/images/van.png";
import Map from "../../common/Map";
// Import Swiper styles
import "swiper/css";
import { useHistory, useParams } from "react-router";
import AxiosApi from "../../utils/axios";
import { baseUrl } from "../../utils/config";
import { Link } from "react-router-dom";
import AddAddressForm from "../../components/ActionSheets/AddAddressForm";
import UpDateAddressForm from "../../components/ActionSheets/UpdateAddressForm";
import AddPointsActionSheet from "../../components/ActionSheets/AddPointsActionSheet";
import { Rating } from "@mui/material";
import { useTranslation } from "react-i18next";
import { HandleTranslation } from "../../hooks";

const ProductDetailsPage: React.FC = () => {
  const [present] = useIonToast();
  const [branchPosition, setBranchPosition] = useState(null);

  const [loadingset, setLoadingset] = useState(false);
  const history = useHistory();
  const productId: any = useParams();
  const [details, setDetails] = useState<any>([]);
  const [branchList, setBranchList] = useState<any>([]);
  const [merchant, setMerchantDetails] = useState<any>();
  const [products, setProducts] = useState([]);
  const token = localStorage.getItem("token");
  const [address, setAddress] = useState<any>();
  const [orderList, setOrderList] = useState<any[]>([]);
  const [pending, setPending] = useState<any>(false);
  const [reqId, setReqId] = useState();
  const [data, setData] = useState<any>();
  const [addresses, setAddresses] = useState<any>();
  const [user, setUser] = useState<any>();
  const [way, setWay] = useState("delivery");
  const [branch, setBranch] = useState<any>(
    branchList[0] && branchList[0] ? branchList[0] : []
  );
  const [forceupdate, setForceUpdate] = useState<any>();
  const [errorSelectAdress, SetErrorSelectAdress] = useState(false);
  const [errorSelectBranch, SetErrorSelectBranch] = useState(false);
  const [installments, setinstallments] = useState<any>();
  const [addAddress, setAddAddress] = useState<any>(false);
  const presentToast = (message: any) => {
    present({
      message: message,
      duration: 3000,
      position: "bottom",
    });
  };

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    // resolver: yupResolver(CreateUserValidation),
  });

  const openScanner = async () => {
    await hapticsVibrate();
    const data = await BarcodeScanner.scan();
    console.log(`Barcode data: ${data.text}`);

    AxiosApi({
      url: `${baseUrl}/api/user/req/approve`,
      method: "post",
      data: data,
    })
      .then((res) => {
        const token = res?.data?.data?.token;
        // dispatch(login(token));
      })
      .catch((err) => {
        setLoadingset(false);
        console.log(err);
      });
  };
  useEffect(() => {
    setLoadingset(true);
    AxiosApi({
      url: `${baseUrl}/api/user/me`,
      method: "post",
      data: { token },
    })
      .then((res) => {
        setUser(res?.data.data);
      })
      .catch((err) => {
        setLoadingset(false);

        console.log(err);
      });
  }, [token]);
  useEffect(() => {
    AxiosApi({
      url: `${baseUrl}/api/user/company/plan/list`,
      method: "post",
      data: { token },
    })
      .then((res: any) => {
        setinstallments(res?.data.data.rows);
      })
      .catch((err) => {
        setLoadingset(false);
        console.log(err);
      });
  }, [token]);
  useEffect(() => {
    AxiosApi({
      url: `${baseUrl}/api/user/address/list`,
      method: "post",
      data: { token },
    })
      .then((res: any) => {
        setAddresses(res?.data.data.rows);
      })
      .catch((err) => {
        setLoadingset(false);
        console.log(err);
      });
  }, [token, forceupdate]);

  const hapticsVibrate = async () => {
    await Haptics.vibrate();
  };

  useEffect(() => {
    setLoadingset(true);
    productId.id &&
      AxiosApi({
        url: `${baseUrl}/api/user/product/details`,
        method: "post",
        data: { id: productId.id, token },
      }).then((res) => {
        console.log(res.data);
        setDetails(res?.data?.data);

        setLoadingset(false);
      });
  }, [productId.id, token]);

  useEffect(() => {
    details?.merchantId &&
      AxiosApi({
        url: `${baseUrl}/api/public/merchant/details`,
        method: "post",
        data: { merchantId: details?.merchantId },
      }).then((res) => {
        setMerchantDetails(res?.data.data.merchantDetails);
        setBranchList(res?.data?.data?.merchantDetails.Branches);
        setLoadingset(false);
      });
  }, [details?.merchantId, token]);

  useEffect(() => {
    AxiosApi({
      url: `${baseUrl}/api/user/product/list`,
      method: "post",
      data: { token, limit: 300 },
    }).then((res) => {
      setProducts(res?.data?.data?.rows);
      setLoadingset(false);
    });
  }, [token]);

  const buyProduct = () => {
    let data =
      way === "delivery"
        ? {
            token,
            productId: productId.id,
            type: way,
            addressId: addresses && (address?.id || addresses[0]?.id),
          }
        : {
            token,
            productId: productId.id,
            type: way,
            branchId: branchList && (branch?.id || merchant?.Branches[0]?.id),
          };
    if (way === "delivery" && data.addressId === undefined) {
      return SetErrorSelectAdress(true);
    }
    if (way === "pickup" && data.branchId === undefined) {
      return SetErrorSelectBranch(true);
    }
    localStorage.setItem("checkoutData", JSON.stringify(data));

    localStorage.setItem("productData", JSON.stringify(details));
    way === "delivery" &&
      addresses[0] !== undefined &&
      localStorage.setItem("adress", JSON.stringify(address || addresses[0]));

    way === "pickup" &&
      branchList[0] !== undefined &&
      localStorage.setItem(
        "branch",
        JSON.stringify(branch.length !== 0 ? branch : branchList[0])
      );
    history.push("/checkout");
  };

  const { t } = useTranslation();
  function handleRefresh(event: any) {
    setTimeout(() => {
      AxiosApi({
        url: `${baseUrl}/api/user/me`,
        method: "post",
        data: { token },
      })
        .then((res) => {
          setUser(res?.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
      AxiosApi({
        url: `${baseUrl}/api/user/address/list`,
        method: "post",
        data: { token },
      })
        .then((res: any) => {
          setAddresses(res?.data.data.rows);
        })
        .catch((err) => {
          console.log(err);
        });
      AxiosApi({
        url: `${baseUrl}/api/user/product/details`,
        method: "post",
        data: { id: productId.id, token },
      }).then((res) => {
        console.log(res.data);
        setDetails(res?.data?.data);

        setLoadingset(false);
      });
      AxiosApi({
        url: `${baseUrl}/api/public/merchant/details`,
        method: "post",
        data: { merchantId: details?.merchantId },
      }).then((res) => {
        setMerchantDetails(res?.data.data.merchantDetails);
        setBranchList(res?.data?.data?.merchantDetails.Branches);
        setLoadingset(false);
      });
      AxiosApi({
        url: `${baseUrl}/api/user/product/list`,
        method: "post",
        data: { token, limit: 300 },
      }).then((res) => {
        setProducts(res?.data?.data?.rows);
        setLoadingset(false);
      });
      event.detail.complete();
    }, 2000);
  }
  console.log(details?.rate);
  return (
    <IonPage>
      <IonContent fullscreen>
        {addAddress ? (
          <div className="my-5 pb-3">
            <AddAddressForm
              update={setForceUpdate}
              setAddAddress={setAddAddress}
            />
          </div>
        ) : (
          <div id="appCapsule">
            <HeaderContainer headerTitle="Products" />
            <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
              <IonRefresherContent refreshingSpinner="circles"></IonRefresherContent>
            </IonRefresher>
            {loadingset === true ? (
              <LoadingContainer />
            ) : (
              <div className="row p-3 product-det">
                <div className="text-center mt-1 ">
                  <img
                    src={
                      details?.image === null
                        ? fries
                        : `https://api.eshailx.com/${details?.image}`
                    }
                    alt="logo"
                    className=" imaged w100"
                  />
                </div>

                <div className="col-8">
                  <h5 className="text-left"> {details?.name}</h5>
                  <p className="details-desc ps-0">
                    {/* {details?.description} */}
                    {t("hotline")} : {merchant?.hotLine}
                  </p>
                  <p className="details-desc ps-0">
                    {/* {details?.description} */}
                    {t("name")} : {merchant?.name}
                  </p>
                  {/* <img src={stars} alt="logo" className="" /> */}
                  <Rating
                    name="read-only"
                    defaultValue={details?.rate}
                    readOnly
                    precision={0.5}
                  />
                </div>
                <div className="ofdet-box d-flex p-0 mt-2">
                  <div className="col-5 blue-box"> {t("getitfor")}</div>
                  <div className="col-9 green-box-det pe-0    text-end">
                    <div className=" text-end">
                      {" "}
                      {details?.points} ={" "}
                      <span className="productdet-price">
                        {details?.price}$
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  <h6 className="mt-3 mb-0 product-header-desp">
                    {t("description")} :{" "}
                  </h6>
                  <span className="product-desp">{details?.description}</span>
                </div>
                {details?.stockAmount != 0 ? (
                  <div className="col-12 mt-2">
                    <ul className="nav nav-tabs capsuled" role="tablist">
                      <li className="nav-item">
                        <a
                          className={`nav-link  ${
                            way === "delivery" ? "active" : ``
                          }`}
                          data-bs-toggle="tab"
                          href="#delivery"
                          role="tab"
                          onClick={() => setWay("delivery")}
                        >
                          {t("delivery")}{" "}
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={`nav-link  ${
                            way === "pickup" ? "active" : ``
                          }`}
                          data-bs-toggle="tab"
                          href="#pickup"
                          role="tab"
                          onClick={() => setWay("pickup")}
                        >
                          {t("pickup")}{" "}
                        </a>
                      </li>
                    </ul>

                    <div className="tab-content ">
                      <div
                        className="tab-pane fade active show"
                        id="delivery"
                        role="tabpanel"
                      >
                        <div className="row text-center w-100">
                          <IonList className="bg-transparent">
                            <IonItem className="placeholder:bg-white w-100">
                              <div className="bg-white w-100">
                                <IonSelect
                                  interface="action-sheet"
                                  placeholder={t("changeaddress")}
                                  className="w-100"
                                  onIonChange={(e: any) => {
                                    setAddress(e.detail.value);
                                  }}
                                >
                                  {addresses &&
                                    addresses?.map((address: any) => (
                                      <div key={address?.id}>
                                        <IonSelectOption
                                          value={address}
                                          key={address?.id}
                                        >
                                          {address?.Address?.address1}
                                          {` `}
                                          {address?.Address?.address2}
                                        </IonSelectOption>
                                      </div>
                                    ))}
                                </IonSelect>
                              </div>
                            </IonItem>
                          </IonList>
                          {errorSelectAdress && addresses?.length === 0 ? (
                            <span className="text-danger">
                              {t("pleasechooseadd")}
                            </span>
                          ) : (
                            ``
                          )}
                          <div className="d-flex  gap-4 text-left address-box ">
                            <div className="vanbox">
                              <img alt="logo" src={van} />
                            </div>
                            <div className="d-flex flex-column">
                              <div className="d-flex  justify-content-between ">
                                <p
                                  style={{
                                    color: "#1C1C1C",
                                    fontSize: "11px",
                                  }}
                                >
                                  {t("deliverytohome")}
                                </p>
                              </div>
                              <p
                                className="m-0 position relative d-flex gap-2"
                                style={{
                                  color: "#1C1C1C",
                                  fontSize: "9px",
                                }}
                              >
                                <i
                                  className="bi bi-geo-alt-fill "
                                  style={{
                                    color: "#211CA0",
                                    fontSize: "15px",
                                  }}
                                />{" "}
                                <p>
                                  {(address &&
                                    `${address?.Address?.address1} ${address?.Address?.address2}`) ||
                                    (addresses &&
                                      addresses[0]?.Address?.address1 !==
                                        undefined &&
                                      `${addresses[0]?.Address?.address1} ${addresses[0]?.Address?.address2}`)}
                                </p>
                              </p>
                            </div>
                          </div>

                          <div
                            className="collapse show mt-2"
                            id="tab-17"
                            data-bs-parent="#tab-group-6"
                          >
                            <div
                              className="card card-style w-100 mx-auto mb-0 py-3"
                              style={{
                                backgroundColor: "rgb(238, 238, 238)",
                                filter: "drop-shadow(0px 3px 6px #0000001A)",
                              }}
                            >
                              <div
                                className="text-center "
                                onClick={() => setAddAddress(true)}
                              >
                                <Link
                                  to="#"
                                  className="flex"
                                  style={{
                                    fontSize: "11px",
                                  }}
                                >
                                  <i className="font-16 bi bi-house-fill" />
                                  <span> {t("addnewaddress")}</span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="pickup"
                        role="tabpanel"
                      >
                        <div className="row text-center">
                          <IonList className="bg-transparent">
                            <IonItem className="placeholder:bg-white w-100">
                              <div className="bg-white w-100">
                                <IonSelect
                                  interface="action-sheet"
                                  placeholder={t("changebranch")}
                                  className="w-100"
                                  onIonChange={(e: any) => {
                                    setBranch(e.detail.value);
                                  }}
                                >
                                  {branchList &&
                                    branchList?.map((branch: any) => (
                                      <div key={branch?.id}>
                                        <IonSelectOption
                                          value={branch}
                                          key={branch?.id}
                                        >
                                          {branch?.branchName}
                                        </IonSelectOption>
                                      </div>
                                    ))}
                                </IonSelect>
                              </div>
                            </IonItem>
                          </IonList>
                          {errorSelectBranch && branchList?.length === 0 ? (
                            <span className="text-danger">
                              please check merchant branches
                            </span>
                          ) : (
                            ``
                          )}
                          <div
                            className="w-100 text-left p-2 position-relative"
                            style={{
                              height: "159px",
                              border: "1px solid #211CA0",
                              borderRadius: "10px",
                            }}
                          >
                            <div>
                              <p
                                className="mb-0"
                                style={{
                                  color: "#211CA0",
                                  fontSize: "11px",
                                  fontWeight: "500",
                                }}
                              >
                                {t("branchaddress")} :
                              </p>
                              <span
                                style={{
                                  color: "#1C1C1C",
                                  fontSize: "11px",
                                }}
                              >
                                {branchList &&
                                  (branch?.address || branchList[0]?.address)}
                              </span>
                              <br />
                            </div>
                            <div className="geo-before"></div>
                            <div className="position-absolute right-0 d-flex justify-content-center align-items-center pickup-location-box">
                              <i
                                className="bi bi-geo-alt-fill "
                                style={{
                                  color: "#211CA0",
                                  fontSize: "27px",
                                }}
                                data-bs-toggle="offcanvas"
                                data-bs-target="#actionSheetContent"
                                onClick={() => {
                                  branch.length !== 0
                                    ? setBranchPosition(branch)
                                    : setBranchPosition(branchList[0]);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <p className="text-danger text-center">{t("noamount")}</p>
                )}
                <h6
                  style={{
                    color: "#092C4C",
                    fontSize: "13px",
                    marginTop: "30px",
                  }}
                >
                  {t("recomproducts")} :
                </h6>
                {
                  <div className="carousel-single splide">
                    <div className="splide__track mb-3">
                      <ul className="splide__list">
                        {products?.map((product: any) => (
                          <li
                            id="slide-auto"
                            key={product?.id}
                            className="splide__slide splide_grey"
                          >
                            <div className="row">
                              <div className="col-3">
                                <div className="circle">
                                  <img
                                    className=""
                                    src={
                                      product?.image === null
                                        ? fries
                                        : ` https://api.eshailx.com/${product?.image}`
                                    }
                                    alt="starb"
                                  />
                                </div>
                              </div>
                              <div className="col-5 slide-box-container">
                                <h4>{product?.merchant?.name}</h4>
                                <h5>
                                  {HandleTranslation(
                                    product?.points,
                                    Number(product?.points).toLocaleString(
                                      "ar-u-nu-arab"
                                    )
                                  )}{" "}
                                  {t("point")}
                                </h5>
                              </div>
                              <div className="col-4 px-0">
                                {" "}
                                <button
                                  className="btn btn-spend"
                                  onClick={() => {
                                    history.push(`/product/${product?.id}`);
                                  }}
                                >
                                  {t("spend")}
                                </button>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                }
                {details?.stockAmount != "0" ? (
                  <button
                    onClick={buyProduct}
                    className="py-3 font-15 mb-1 product-btn text-center"
                  >
                    {t("buynow")}
                  </button>
                ) : null}
              </div>
            )}
          </div>
        )}

        <div
          className="offcanvas offcanvas-bottom action-sheet section"
          id="actionSheetContent"
        >
          <div className="offcanvas-header">
            <h5 className="offcanvas-title">
              <a href="#" className="" data-bs-dismiss="offcanvas"></a>
            </h5>
          </div>
          <div className="offcanvas-body">
            <div className="action-sheet-content">
              <div className="map-dimenssions">
                <Map branch={branchPosition} />
              </div>
            </div>
          </div>
        </div>
      </IonContent>
      <Footer />
    </IonPage>
  );
};

export default ProductDetailsPage;
