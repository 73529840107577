import React from "react";
import loading from "../../assets/images/loading.svg";
import { useTranslation } from "react-i18next";

export const LoadingContainer: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="text-center">
      <br />
      <h4 className="loading-text-header">
        {t("loading")} <br />
        <span className="loading-text-header-sm">{t("pleasewait")}</span>
      </h4>
      <img src={loading} className="text-center svg" />
    </div>
  );
};
