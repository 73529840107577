import {
  IonItem,
  IonList,
  IonSelect,
  IonSelectOption,
  useIonToast,
} from "@ionic/react";
import plus from "../../assets/images/plus.svg";
import wallet from "../../assets/images/wallet.svg";
import addd from "../../assets/images/addd.svg";
import methods from "../../assets/images/methods.svg";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { baseUrl } from "../../utils/config";
import AxiosApi from "../../utils/axios";
import { useForm } from "react-hook-form";
import mastercard from "../../assets/images/mastercard.png";
import visa from "../../assets/images/visa.png";
import cash from "../../assets/images/cash.png";
import StripeCVC from "../stripe/addPointsElement";
import { IonIcon } from "@ionic/react";
import { arrowBackOutline } from "ionicons/icons";
import {
  CardCvcElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useTranslation } from "react-i18next";
const AddPointsActionSheet = ({ user, noPoints, setError, error }: any) => {
  const [present] = useIonToast();

  const presentToast = (message: string, color: string) => {
    present({
      message: message,
      duration: 3000,
      position: "bottom",
      color: color,
    });
  };
  const token = localStorage.getItem("token");
  const [numOfPoints, setNumOfPoints] = useState(25);
  const [packages, setPackage] = useState<number>(0);
  const [addWay, setAddWay] = useState("points");
  const [cardId, setCardId] = useState<any>();
  const [clientSecret, setClientSecret] = useState<string>();
  const [paymentId, setPaymentId] = useState<string>();
  const [showmore, setShowMore] = useState<any>(false);
  const { t } = useTranslation();
  const [cardlist, setCardList] = useState<any>();
  const [handleShowingCardNum, setHandleShowCardNum] = useState<any>(1);
  const history = useHistory();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const incPoints = () => {
    setNumOfPoints((prevState) => prevState + 25);
  };
  useEffect(() => {
    setCardId(cardlist?.filter((card: any) => card?.isDefault == true)[0]?.id);
    setPaymentId(
      cardlist?.filter((card: any) => card?.isDefault == true)[0]?.paymentId
    );
  }, [cardlist]);

  const decPoints = () => {
    if (numOfPoints <= 25) {
      return console.log("u cant buy less than 25 points");
    }
    setNumOfPoints((prevState) => prevState - 25);
  };
  let totalEarned;
  if (addWay === "points") {
    totalEarned = Number(user?.balance) + numOfPoints;
  } else if (addWay === "packages") {
    totalEarned = Number(user?.balance) + Number(packages);
  }
  const initializePayment = async (id: any) => {
    await AxiosApi.post(`${baseUrl}/api/user/card/setupPayment`, {
      token,
      points: addWay === "points" ? Number(numOfPoints) : Number(packages),
      cardId: cardId,
      price: addWay === "points" ? numOfPoints / 5 : packages / 5,
    })
      .then((result) => {
        setClientSecret(result?.data?.data?.client_secret);
      })
      .catch((err: any) => {
        console.log("-----".repeat(25));
        console.log(err);
        console.log("-----".repeat(25));
        presentToast(err?.response?.data.message, "danger");
      });
  };
  const elements: any = useElements();
  const stripe: any = useStripe();

  useEffect(() => {
    let cvc = elements?.getElement(CardCvcElement);
    cvc &&
      cvc.on("change", (e: any) => {
        if (e.error || !e.complete) {
          setError(true);
        } else {
          setError(false);
        }
      });
  }, [clientSecret]);

  console.log("-----".repeat(25));
  console.log(paymentId);
  console.log("-----".repeat(25));
  const buyPoints = async () => {
    if (clientSecret) {
      if (error) {
        presentToast("please submit correct CVC", "danger");
      }
      let cvc = elements?.getElement(CardCvcElement);

      const confirm = await stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentId,
        payment_method_options: {
          card: {
            cvc: cvc,
          },
        },
      });
      if (confirm?.error) {
        presentToast(confirm?.error?.message, "danger");
      }
      if (confirm?.paymentIntent?.status == "succeeded") {
        try {
          AxiosApi.post(`${baseUrl}/api/user/addPoints`, {
            token,
            points:
              addWay === "points" ? Number(numOfPoints) : Number(packages),
            cardId: cardId,
            clientSecret: clientSecret,
            price: addWay === "points" ? numOfPoints / 5 : packages / 5,
            paymentType: "stripe",
            paymentId: confirm?.paymentIntent.id,
          })
            .then(() => {
              presentToast("Points Added Successfully", "success");
              setTimeout(() => {
                window.location.reload();
              }, 1500);
            })
            .catch((err: any) => {
              presentToast(err?.response?.data.message, "danger");
            });
        } catch (err: any) {
          presentToast(err?.response?.data.message, "danger");
        }
      }
    }
  };

  useEffect(() => {
    AxiosApi({
      url: `${baseUrl}/api/user/card/list`,
      method: "post",
      data: { token },
    })
      .then((res) => {
        setCardList(
          res?.data.data.sort((a: any, b: any) =>
            a.isDefault === true ? -1 : 1
          )
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, [history, token]);

  return (
    <div
      className="offcanvas offcanvas-bottom action-sheet p-0"
      id={noPoints ? "actionSheetContent3" : "actionSheetContent2"}
    >
      <div className="offcanvas-header text-center">
        {noPoints ? (
          <p className="text-danger text-center font-9">{t("errpoints")}</p>
        ) : null}
        <img
          className="p-0"
          src={addd}
          alt="addd"
          onClick={() => setClientSecret("")}
        />{" "}
        {t("addpoints")}{" "}
      </div>

      <div className="offcanvas-body">
        <div className="action-sheet-content">
          <div className="requests">
            {!clientSecret && (
              <div className="row m-2 add-points-cover">
                <div className="col-12 mt-1 mb-0">
                  <img className="wallet-img" src={wallet} alt="wallet" />
                  <span className="sub-title">{t("yourpoints")}</span>
                </div>
                <div className="col-6 ">
                  <div className="points-box">
                    <div className="point-title">{t("points")}</div>
                    <div className="total-earned">{t("totalearned")} !</div>
                    <div className="total-points">
                      {user && totalEarned}
                      <span>{t("point")}</span>
                    </div>
                  </div>
                </div>
                <div className="col-6 ">
                  <div className="d-flex justify-content-between gap-2 align-items-center ">
                    <label className="radio">
                      <input
                        name="radio"
                        type="radio"
                        defaultChecked
                        onChange={() => {
                          setAddWay("points");
                        }}
                      />
                      <span></span>
                    </label>
                    <button
                      onClick={decPoints}
                      className={`dec-points `}
                      style={{
                        pointerEvents: `${
                          addWay === "packages" ? "none" : "auto"
                        }`,
                      }}
                    >
                      -
                    </button>
                    <span className="points-quantity">
                      {" "}
                      {user && numOfPoints}
                    </span>
                    <button
                      onClick={incPoints}
                      className="inc-points"
                      style={{
                        pointerEvents: `${
                          addWay === "packages" ? "none" : "auto"
                        }`,
                      }}
                    >
                      +
                    </button>
                  </div>

                  <div className="form-group basic">
                    <div className="input-wrapper mt-2">
                      <label className="form-label mb-1" htmlFor="packages">
                        {t("orbuypackage")}
                      </label>
                      <div className="d-flex justify-content-between gap-2 align-items-center ">
                        <label className="radio">
                          <input
                            name="radio"
                            type="radio"
                            onChange={() => {
                              setAddWay("packages");
                            }}
                          />
                          <span></span>
                        </label>

                        <IonList
                          className="bg-transparent"
                          style={{
                            pointerEvents: `${
                              addWay === "points" ? "none" : `auto`
                            }`,
                          }}
                        >
                          <IonItem className="placeholder:bg-white w-100">
                            <div
                              className={`bg-white w-100 ${
                                addWay === "point" ? "user-select-none" : ``
                              }`}
                            >
                              <IonSelect
                                interface="action-sheet"
                                placeholder={t("packages")}
                                className="w-100"
                                color=""
                                onIonChange={(e: any) => {
                                  setPackage(e.detail.value);
                                }}
                              >
                                <IonSelectOption
                                  value="500"
                                  className="text-[#a5a5a5]"
                                >
                                  500
                                </IonSelectOption>
                                <IonSelectOption
                                  className="text-[#a5a5a5]"
                                  value="1000"
                                >
                                  1000
                                </IonSelectOption>
                                <IonSelectOption
                                  className="text-[#a5a5a5]"
                                  value="2000"
                                >
                                  2000
                                </IonSelectOption>
                                <IonSelectOption
                                  className="text-[#a5a5a5]"
                                  value="3000"
                                >
                                  3000
                                </IonSelectOption>
                                <IonSelectOption
                                  className="text-[#a5a5a5]"
                                  value="4000"
                                >
                                  4000
                                </IonSelectOption>
                                <IonSelectOption
                                  className="text-[#a5a5a5]"
                                  value="5000"
                                >
                                  5000
                                </IonSelectOption>
                              </IonSelect>
                            </div>
                          </IonItem>
                        </IonList>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="row mt-2 p-3">
              <div className="col-12 ">
                <div className="payment-methods">
                  <div>
                    {clientSecret && (
                      <i className="clear-input">
                        {/*@ts-ignore*/}
                        <IonIcon
                          icon={arrowBackOutline}
                          onClick={() => setClientSecret("")}
                        ></IonIcon>
                      </i>
                    )}
                    <h5 className="opacity-50 mb-3">{t("paymentmethods")}</h5>
                    <div className="row">
                      {clientSecret ? (
                        cardlist
                          .filter(
                            (selectedCard: any) => selectedCard.id == cardId
                          )
                          .map((card: any) => {
                            return (
                              <div
                                className={`          ${
                                  cardId === card?.id
                                    ? "active-payment-card"
                                    : "border"
                                } 
                     row mb-2 align-items-center p-2`}
                                key={card?.id}
                                onClick={() => {
                                  setCardId(card?.id);
                                  setPaymentId(card?.paymentId);
                                }}
                              >
                                <img className="col-2" src={visa} alt="visa" />
                                <div className=" col-6">
                                  <p className="payment_methods-list mb-0">
                                    {" "}
                                    {card?.nameOnCard}
                                  </p>
                                  <span className="payment_methods-list">
                                    {" "}
                                    {card?.cardNumber}
                                  </span>
                                </div>
                                {clientSecret && cardId == card?.id && (
                                  <div className="col">
                                    <CardCvcElement />
                                  </div>
                                )}
                              </div>
                            );
                          })
                      ) : cardlist?.length !== 0 ? (
                        cardlist
                          ?.slice(0, handleShowingCardNum)
                          .map((card: any) => (
                            <div
                              className={`          ${
                                cardId === card?.id
                                  ? "active-payment-card"
                                  : "border"
                              } 
                       row mb-2 align-items-center p-2`}
                              key={card?.id}
                              onClick={() => {
                                setCardId(card?.id);
                                setPaymentId(card?.paymentId);
                              }}
                            >
                              <img className="col-2" src={visa} alt="visa" />
                              <div className=" col-6">
                                <p className="payment_methods-list mb-0">
                                  {" "}
                                  {card?.nameOnCard}
                                </p>
                                <span className="payment_methods-list">
                                  {" "}
                                  {card?.cardNumber}
                                </span>
                              </div>
                              {clientSecret && cardId == card?.id && (
                                <div className="col">
                                  <CardCvcElement />
                                </div>
                              )}
                            </div>
                          ))
                      ) : (
                        <div className="text-center ">
                          <strong className="text-danger">
                            {t("paymentmethodserr")}
                          </strong>
                          <Link
                            to="/paymentmethods"
                            className="d-block para-style"
                          >
                            {t("gotopayment")}
                          </Link>
                        </div>
                      )}
                      {cardlist?.length > 1 &&
                        (showmore === false ? (
                          <div className="text-center">
                            <button
                              className="btn btn-primary btn-block w-75"
                              onClick={() => {
                                setShowMore(true);
                                setHandleShowCardNum(cardlist.length);
                              }}
                            >
                              <i className="bi bi-plus-circle-dotted"></i>{" "}
                              {t("showmore")}
                            </button>
                          </div>
                        ) : (
                          <div className="mx-auto text-center">
                            <button
                              className="btn btn-primary btn-block  w-75"
                              onClick={() => {
                                setShowMore(false);
                                setHandleShowCardNum(1);
                              }}
                            >
                              <i className="bi bi-dash-circle-dotted"></i>{" "}
                              {t("showless")}
                            </button>
                          </div>
                        ))}
                      <div className="row mt-2 col-12 mx-auto ">
                        <div className=" mx-auto">
                          {clientSecret ? (
                            <button
                              type="submit"
                              className="btn btn-primary btn-primary2 btn-block "
                              onClick={buyPoints}
                              disabled={cardlist?.length === 0 ? true : false}
                            >
                              {t("paynow")}
                              {addWay === "points" ? (
                                <label className="total-pay text-white">
                                  $ {addWay === "points" ? numOfPoints / 5 : ``}
                                </label>
                              ) : (
                                <label className="total-pay text-white">
                                  $ {addWay === "packages" ? packages / 5 : ``}
                                </label>
                              )}
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="btn btn-primary btn-primary2 btn-block "
                              onClick={initializePayment}
                              disabled={cardlist?.length === 0 ? true : false}
                            >
                              {t("paynow")}
                              {addWay === "points" ? (
                                <label className="total-pay">
                                  {addWay === "points" ? numOfPoints / 5 : ``}
                                </label>
                              ) : (
                                <label className="total-pay">
                                  {addWay === "packages" ? packages / 5 : ``}
                                </label>
                              )}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPointsActionSheet;
