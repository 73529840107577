import React, { useEffect, useState } from "react";
import i18n from "../../i18n/i18n";
import { useTranslation } from "react-i18next";
import { IonContent, IonPage } from "@ionic/react";
import { HandleTranslation } from "../../hooks";
import { Footer } from "../../components";

const Changelang = () => {
  const [lang, setLang] = useState<any>("");
  const { t } = useTranslation();

  useEffect(() => {
    let language: any = i18n.language === "en" ? "ar" : "en";
    setLang(language);
  }, []);

  const chooseLang = (e: any) => {
    let oppLang = e === "en" ? "ar" : "en";

    i18n.changeLanguage(e);
    setLang(oppLang);
  };
  return (
    <IonPage>
      <IonContent fullscreen>
        <div id="appCapsule">
          <h3 className="text-center my-5">{t("changelang")}</h3>
          <div className="container">
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                value=""
                name="lang"
                defaultChecked={i18n.language === "en" ? true : false}
                id="flexCheckDefault"
                onChange={(e) => chooseLang("en")}
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                English
              </label>
            </div>
            <div
              className="divider my-4"
              style={{ backgroundColor: `#161d6f` }}
            ></div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                value=""
                name="lang"
                id="flexCheckChecked"
                defaultChecked={i18n.language === "ar" ? true : false}
                onChange={(e) => chooseLang("ar")}
              />
              <label className="form-check-label" htmlFor="flexCheckChecked">
                اللغة العربية
              </label>
            </div>
          </div>
        </div>
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default Changelang;
