import { IonContent, IonPage } from "@ionic/react";
import AxiosApi from "../../utils/axios"
import { useCallback, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Footer } from "../../components";
import AddPointsActionSheet from "../../components/ActionSheets/AddPointsActionSheet";
import { LoadingContainer } from "../../components/ActionSheets/LoadingAction";
import HeaderContainer from "../../components/Header";
import { baseUrl } from "../../utils/config";
import moment from "moment";
import { NodataContainer } from "../../components/ActionSheets/NoData";

const MyDeptsPage = () => {
  const token = localStorage.getItem("token");
  const [loadingset, setLoadingset] = useState(false);

  const history = useHistory();
  const [deuList, setDueList] = useState<any>();

  let InstalmmentDueList = useCallback(() => {
    setLoadingset(true);
    try {
      AxiosApi
        .post(`${baseUrl}/api/user/installment/due/list`, { token })
        .then((res) => {
          setDueList(res.data.data);

          setLoadingset(false);
        });
    } catch (err: any) {
      console.log(err);
      setLoadingset(false);
    }
  }, [token]);

  useEffect(() => {
    InstalmmentDueList();
  }, [InstalmmentDueList]);

  return (
    <IonPage>
      <IonContent fullscreen>
        {loadingset ? (
          <LoadingContainer />
        ) : (
          <div id="appCapsule">
            <HeaderContainer headerTitle="My Debts" />
            <div className="mydebt p-3 mt-3">
              {deuList && deuList?.length !== 0 ? (
                deuList?.map((dat: any) => (
                  <button
                    key={Math.random()}
                    disabled={
                      moment(new Date()).format("MM/YYYY") ==
                      moment(dat?.dueDate).format("MM/YYYY")
                        ? false
                        : true
                    }
                    className="d-flex justify-content-between list w-100 align-items-center"
                    onClick={() => {
                      history.push("/debtDetails");
                      localStorage.setItem("debtMonth", dat?.dueDate);
                    }}
                  >
                    <div className="d-flex align-items-center gap-2">
                      <i className="bi bi-credit-card"></i>
                      <div>
                        <p className="mb-0">{dat?.dueDate}</p>
                        {/* <p className="mb-0">{dat.price}</p> */}
                      </div>
                    </div>
                    {dat?.isPayed == false ? (
                      <div className="d-flex align-items-center gap-2">
                        <button
                          className={`${
                            moment(new Date()).format("MM/YYYY") ==
                            moment(dat?.dueDate).format("MM/YYYY")
                              ? "paynow-active"
                              : `paynow-inactive`
                          }`}
                        >
                          {dat?.installmentAmount} $
                        </button>
                        <i className="bi bi-arrow-right-short"></i>
                      </div>
                    ) : null}
                  </button>
                ))
              ) : (
                <>
                  <NodataContainer />
                </>
              )}
            </div>
          </div>
        )}

        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default MyDeptsPage;
