import { IonContent, IonPage, useIonToast } from "@ionic/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { Footer } from "../../components";
import HeaderContainer from "../../components/Header";
import methods from "../../assets/images/methods.svg";
import AxiosApi from "axios";
import { baseUrl } from "../../utils/config";
import moment from "moment";
import { ErrorMessage } from "@hookform/error-message";
import FormError from "../../components/FormError";
import Stripe from "../../components/stripe/addPointsElement";
const AddPaymentMethod = () => {
  const [present] = useIonToast();
  const history = useHistory();
  const presentErrorToast = (message: any) => {
    present({
      message: message,
      duration: 3000,
      position: "bottom",
      color: "danger",
    });
  };
  const presentSuccessToast = (message: any) => {
    present({
      message: message,
      duration: 3000,
      position: "bottom",
      color: "success",
    });
  };
  const token = localStorage.getItem("token");
  const [cardNum, setCardNum] = useState<any>();
  const [year, setYear] = useState<any>(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const HandleCreatePaymentCard = async (data: any) => {
    if (moment(data.year).format("YYYY") < moment(new Date()).format("YYYY")) {
      return setYear(true);
    }
    let cardNumber = data.cardNumber.split(" ").join("");
    if (data.month < 10) {
      data.month = `${0 + data.month}`;
    }
    const HandleData = {
      token,
      expirationDate: moment(data.year + data.month).format("MM-YYYY"),
      CVV: data.CVV,
      nameOnCard: data.nameOnCard,
      cardNumber,
    };
    try {
      await AxiosApi
        .post(`${baseUrl}/api/user/card/add`, { ...HandleData, token })
        .then((res: any) => {
          presentSuccessToast("Card Added successfully");
          setTimeout(() => {
          history.push("/paymentmethods");
          }, 1500);
        });
    } catch (err: any) {
      presentErrorToast(err?.response?.data.data);
    }
  };

  return (
    <IonPage>
      <IonContent fullscreen>
        <div id="appCapsule">
          <HeaderContainer headerTitle=" Add Payment Method" />
          <div className="row mt-2">
            <div className="col-12 ">
              <div className=" mt-5 pt-5">
                <form
                  onSubmit={handleSubmit(HandleCreatePaymentCard)}
                  className=" "
                >
                  <div className="payment-container w-90">
                    <div className="form-group boxed d-flex">
                      <div className="flex flex-column ">
                        <div className="input-wrapper mt-0 me-1 d-flex ">
                          <input
                            type="text"
                            className="form-control login-input"
                            id="cardName"
                            placeholder="Card Name"
                            {...register("nameOnCard", {
                              required: "this field is required",
                              pattern: {
                                value: /(^[a-z ]+$)/i,
                                message: "Enter a Valid Name",
                              },
                            })}
                          />
                        </div>
                        <ErrorMessage
                          errors={errors}
                          name={"nameOnCard"}
                          render={FormError}
                        />
                      </div>
                      <div className="d-flex flex-column ">
                        <div className="input-wrapper mt-0 ms-1 d-flex ">
                          <input
                            type="tel"
                            className="form-control login-input"
                            value={cardNum}
                            id="cardNumber"
                            inputMode="numeric"
                            placeholder="Card Number"
                            maxLength={19}
                            {...register("cardNumber", {
                              pattern: {
                                value: /^(\d{4} ){3}\d{4}$/i,
                                message: "should be 16 character",
                              },
                              required: "should be 16 character",
                            })}
                            onChange={(e: any) => {
                              setCardNum(
                                e.target.value
                                  .replace(/\s|[^0-9]+/g, "")
                                  .match(/.{1,4}/g)
                                  ?.join(" ") ?? ""
                              );
                            }}
                          />
                        </div>
                        <ErrorMessage
                          errors={errors}
                          name={"cardNumber"}
                          render={FormError}
                        />
                      </div>
                    </div>

                    <div className="form-group boxed p-0 d-flex">
                      <div className="d-flex flex-column">
                        <label className="login-label  me-1  f-10">
                          Expiration Date
                        </label>
                        <div className="input-wrapper mt-0 me-1 d-flex w-75">
                          <input
                            type="tel"
                            className="form-control login-input"
                            id="year"
                            placeholder="YYYY"
                            maxLength={4}
                            {...register("year", {
                              required: " please enter a valid year and month",
                              pattern: /^[0-9]{4}$/,
                            })}
                          />

                          <input
                            type="tel"
                            className="form-control login-input"
                            id="month"
                            placeholder="M"
                            maxLength={2}
                            {...register("month", {
                              required: "required",
                              pattern: {
                                value: /^(1[0-2]|[1-9])$/,
                                message: "enter valid month",
                              },
                            })}
                          />
                        </div>
                        <ErrorMessage
                          errors={errors}
                          name={"month"}
                          render={FormError}
                        />
                        {year && (
                          <p className="text-danger">
                            please enter a valid year
                          </p>
                        )}
                      </div>
                      <div className="d-flex flex-column">
                        <label className="login-label  ms-1 f-10">CVV</label>
                        <div className="input-wrapper mt-0 ms-1 d-flex ">
                          <input
                            type="tel"
                            className="form-control login-input"
                            id="cvv"
                            placeholder=""
                            maxLength={3}
                            {...register("CVV", {
                              required: "required",
                              pattern: {
                                value: /^\d{3}$/,
                                message: "should be 3 chars",
                              },
                            })}
                          />
                        </div>
                        <ErrorMessage
                          errors={errors}
                          name={"CVV"}
                          render={FormError}
                        />
                      </div>
                    </div>

                    <div className="d-flex">
                      <div className="mt-0 me-1 d-flex w-50"></div>
                      <div className="mt-3 ms-1 d-flex w-50">
                        <img className="" src={methods} alt="methods" />
                      </div>
                    </div>
                  </div>
                  <div className="row m-2">
                    <div className="col-12">
                      <button className="btn btn-primary btn-block btn-lg">
                        Add New Card
                        {/* <label className="total-pay">{packageData?.amount}$</label> */}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
      <Footer />
    </IonPage>
  );
};
export default AddPaymentMethod;
