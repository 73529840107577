import { IonContent, IonPage, useIonToast } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoginValidation } from "../../utils/validationSchema";
import api from "../../services/api";
import { updateSpinnerStatus, loginUser } from "../../redux/actions";
import fprint from "../../assets/images/fprint.png";
import logo from "../../assets/images/logo.svg";
import { StatusBar, Style } from "@capacitor/status-bar";
import { SuccessMessageContainer } from "../../components/SuccesssMessage";
import { LoadingContainer } from "../../components/ActionSheets/LoadingAction";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { ErrorMessage } from "@hookform/error-message";
import FormError from "../../components/FormError";
import AxiosApi from "../../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import login from "../../redux/reducers/user";
import { baseUrl } from "../../utils/config";
import { Device } from "@capacitor/device";

const LoginPage: React.FC = () => {
  const history = useHistory();
  // const token = useSelector((state: any) => state.loginreducer.token);
  const [phoneErrMssg, setPhoneErrMssg] = useState<any>(false);
  const dispatch = useDispatch();
  const [loadingset, setLoadingset] = useState(false);
  const [errorLogin, setErrorLogin] = useState(false);
  const [phone, setPhone] = useState<any>("");
  const [country, setCountry] = useState<any>();
  const setStatusBarStyleLight = async () => {
    await StatusBar.setStyle({ style: Style.Light });
  };
  StatusBar.setOverlaysWebView({ overlay: true });

  setStatusBarStyleLight();
  const [present] = useIonToast();

  const presentToast = (message: any) => {
    present({
      message: message,
      duration: 3000,
      position: "bottom",
    });
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    if (localStorage.getItem("token")) {
      history.push("/home");
    }
  }, []);

  const getDeviceId = async () => {
    return await Device.getId();
  };

  const onSubmit = async (data: any) => {
    console.log(phone.length);

    if (
      phone.length == undefined ||
      phone.length < 5 ||
      phone.replace(country?.dialCode, "") == ""
    ) {
      return setPhoneErrMssg(true);
    }
    setLoadingset(true);

    /* ---------- get device id  ---------- */

    const deviceId = await getDeviceId();

    let pushToken =
      localStorage.getItem("pushToken") ||
      "c4OaffAZS5K7MWytw_GCWG:APA91bE9yGzw9WoEHXbhywRc5bVN9OhCCknk7DssurV_aek38NJIfNl9_X-cFuim_sKL5zRkT7BYMdJlUVv6eLPicftutBY1LIfrwyidJsOIpSsF4vx7yec6tvmHJTTMXum1dz3mMoOa";
    data.username = phone;
    data.deviceId = deviceId.uuid;
    data.deviceToken = pushToken;
    data.lastLogin = new Date();
    AxiosApi({
      url: `${baseUrl}/api/user/login`,
      method: "post",
      data: data,
    })
      .then((res) => {
        console.log(res.data.data.rule);
        if (
          res?.data?.data?.rule === "user" ||
          res?.data?.data?.rule === "agentUser"
        ) {
          const token = res?.data?.data?.token;
          localStorage.setItem("token", token);
          setPhone("");
          history.push("/home");
          reset();
        } else {
          setLoadingset(false);
          return presentToast("You Are Not Authorithed To Login");
        }
      })
      .catch((err) => {
        setErrorLogin(true);
        setLoadingset(false);
        setErrorLogin(false);
        presentToast(err?.response?.data?.message);
        setPhone("");
        setPhoneErrMssg(false);
        console.log(err);
      });
  };

  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="pt-0">
          <div>
            <div className="section login-header">
              <img className="center-item" src={logo} alt="logo" />
              <h1 className="login-h1">Login to your account</h1>
              <h6 className="login-h6">
                We've got some great deals waiting
                <br /> for you!
              </h6>
            </div>

            {loadingset === true ? (
              <LoadingContainer />
            ) : (
              <div className="section mt-1 mb-5 header-padding">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group boxed">
                    <label className="login-label">Phone Number</label>
                    <div className="input-wrapper">
                      <PhoneInput
                        inputClass="form-control w-100 login-input"
                        country="qa"
                        value={phone}
                        inputProps={{
                          name: "phone",
                          required: true,
                          autoFocus: true,
                        }}
                        inputStyle={{
                          textIndent: "30px",
                        }}
                        onChange={(e: any, country: any) => {
                          setPhone(e);
                          setCountry(country);
                        }}
                        isValid={(value, country: any) => {
                          if (
                            value.match(
                              /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4,7}$/
                            )
                          ) {
                            return true;
                          } else if (value.length == 0) {
                            return "please enter your phone number";
                          } else {
                            return (
                              "Invalid value: " + value + ", " + country.name
                            );
                          }
                        }}
                        disableCountryCode={true}
                      />
                    </div>
                    {phoneErrMssg && (
                      <span className="text-danger font-9">
                        Invalid Phone Num
                      </span>
                    )}
                  </div>

                  <div className="form-group boxed">
                    <label className="login-label">Password</label>
                    <div className="input-wrapper">
                      <input
                        type="password"
                        className="form-control login-input"
                        placeholder="******"
                        {...register("password", {
                          required: "this field is required",
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="password"
                        render={FormError}
                      />
                      <i className="clear-input">
                        {/* <ion-icon name="close-circle"></ion-icon> */}
                      </i>
                    </div>
                  </div>

                  <div className="form-links mt-2">
                    <div>
                      <Link to="/forgotpassword" className="login-label">
                        Forgot Password?
                      </Link>
                    </div>
                  </div>

                  {/* <div>
                    <a>
                      <img className="center-item" src={fprint} alt="fprint" />
                    </a>
                  </div> */}

                  <div>
                    {errorLogin == true ? (
                      <div className="text-danger alert  my-1" role="alert">
                        Please Enter valid username & Password
                      </div>
                    ) : (
                      <></>
                    )}
                    <br />
                    <button
                      type="submit"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#actionSheetContent"
                      className="btn btn-primary btn-block btn-lg"
                    >
                      Log in
                    </button>
                  </div>
                  <div>
                    <br />
                    <div>
                      <p className="login-p">
                        Do not have account?
                        <Link to="register" className="login-label">
                          {" "}
                          Register
                        </Link>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default LoginPage;
