import { ErrorMessage } from "@hookform/error-message";
import { IonContent, IonPage, useIonToast } from "@ionic/react";
import AxiosApi from "../../utils/axios";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { baseUrl } from "../../utils/config";
import FormError from "../FormError";
import arrow from "../../assets/images/arrowback.svg";
import GoogleMapReact from "google-map-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const UpDateAddressForm = ({ addressData, showDetails, update }: any) => {
  const [lats, setLats] = useState();
  const [long, setLong] = useState();
  const [present] = useIonToast();
  const { t } = useTranslation();
  const presentToast = (message: any) => {
    present({
      message: message,
      duration: 3000,
      position: "bottom",
    });
  };

  const defaultProps = {
    center: {
      lat: lats || addressData?.Address.lat,
      lng: long || addressData?.Address.long,
    },
    zoom: 14,
  };
  const history = useHistory();
  const token = localStorage.getItem("token");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(CreateUserValidation),
  });
  console.log(addressData);
  const onSubmit = (data: any) => {
    const finalData = {
      address1: data?.address1 || addressData.Address.address1,
      address2: data?.address2 || addressData.Address.address2,
      area: data?.area || addressData.Address.area,
      city: data?.city || addressData.Address.city,
      long: defaultProps.center.lng,
      lat: defaultProps.center.lat,
      token,
      addressId: addressData?.Address.id,
    };

    AxiosApi.post(`${baseUrl}/api/user/address/update`, {
      ...finalData,
    })
      .then((res: any) => {
        update(true);
        showDetails(false);
        presentToast("Address Updates successfully");
      })
      .catch((err: any) => {
        presentToast(err?.response?.data.message);
      });
  };
  console.log(update);
  return (
    <IonPage>
      <IonContent>
        <div className="content mt-5">
          <div>
            <div className="menu-size">
              <div className="d-flex  mt-3 py-1">
                <div className="align-self-center">
                  <Link
                    to="#"
                    onClick={() => {
                      showDetails(false);
                    }}
                    className="ps-1"
                    shadow-0=""
                  >
                    <img src={arrow} alt="back" />
                  </Link>
                </div>
                <div className="align-self-center ms-3">
                  <h1
                    className="m-0 main-clr"
                    style={{
                      fontWeight: "700",
                      fontSize: "24px",
                      lineHeight: "30px",
                    }}
                  >
                    {t("updateaddress")}
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="px-3 text-left pb-7"
          >
            <div className="form-group boxed">
              <label className="login-label">{t("address1")}</label>
              <div className="input-wrapper">
                <input
                  type="text"
                  className="form-control login-input"
                  placeholder={t("address1")}
                  defaultValue={addressData?.Address.address1}
                  {...register("address1")}
                />
                <ErrorMessage
                  errors={errors}
                  name="address1"
                  render={FormError}
                />
              </div>
            </div>
            <div className="pb-1"></div>
            <div className="form-group boxed">
              <label className="login-label">{t("address2")}</label>
              <div className="input-wrapper">
                <input
                  type="text"
                  className="form-control login-input"
                  defaultValue={addressData?.Address.address2}
                  placeholder={t("address2")}
                  {...register("address2")}
                />
                <ErrorMessage
                  errors={errors}
                  name="address2"
                  render={FormError}
                />
              </div>
            </div>
            <div className="pb-1"></div>
            <div className="form-group boxed">
              <label className="login-label">{t("city")}</label>
              <div className="input-wrapper">
                <input
                  type="text"
                  className="form-control login-input"
                  placeholder={t("city")}
                  defaultValue={addressData?.Address.city}
                  {...register("city")}
                />
                <ErrorMessage errors={errors} name="city" render={FormError} />
              </div>
            </div>
            <div className="pb-1"></div>
            <div className="form-group boxed">
              <label className="login-label">{t("area")}</label>
              <div className="input-wrapper">
                <input
                  type="text"
                  className="form-control login-input"
                  placeholder={t("area")}
                  defaultValue={addressData?.Address.area}
                  {...register("area")}
                />
                <ErrorMessage errors={errors} name="area" render={FormError} />
              </div>
            </div>
            <div className="pb-1"></div>
            <div style={{ height: 400, width: 370 }}>
              <GoogleMapReact
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
                bootstrapURLKeys={{
                  key: "AIzaSyDxsUzIfLpefNwPISG4wYuo_qrrKHuMUKQ",
                }}
                yesIWantToUseGoogleMapApiInternals={true}
                onClick={(e: any) => {
                  setLats(e.lat);
                  setLong(e.lng);
                  console.log(e);
                }}
              >
                <Marker
                  lat={defaultProps.center.lat}
                  lng={defaultProps.center.lng}
                  text="check"
                />
              </GoogleMapReact>
            </div>

            <div className="pb-5 mb-3">
              <button
                className=" btn btn-primary btn-block btn-lg mt-1  mb-5"
                style={{ width: "100%" }}
              >
                {t("updateaddress")}
              </button>
            </div>
          </form>
        </div>
      </IonContent>
    </IonPage>
  );
};
export default UpDateAddressForm;

const Marker = ({ text }: any) => {
  return (
    <div className="SuperAwesomePin">
      <img
        height="25"
        width="25"
        src="https://img.icons8.com/color/48/000000/marker--v1.png"
        alt="location"
      />
    </div>
  );
};
