import React from "react";
import nodata from "../../assets/images/mountain-bike.png";
import { useTranslation } from "react-i18next";
export const NodataContainer: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="text-center">
      <br />
      <h4 className="loading-text-header">
        <img src={nodata} className="text-center nodata-img" />
        <br />
        <span className="nothing-title">{t("nodata")}</span>
      </h4>
    </div>
  );
};
