import {
  IonContent,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  useIonToast,
} from "@ionic/react";
import AxiosApi from "../../utils/axios";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Footer } from "../../components";
import HeaderContainer from "../../components/Header";
import { baseUrl } from "../../utils/config";
import avatar from "../../assets/images/avatar.png";
import product from "../../assets/images/products.svg";
import offerg from "../../assets/images/offerg.svg";
import AddAddressForm from "../../components/ActionSheets/AddAddressForm";
import { Link } from "react-router-dom";
import van from "../../assets/images/van.png";
import UpDateAddressForm from "../../components/ActionSheets/UpdateAddressForm";
import CreateProfileForm from "../../components/ActionSheets/CreateProfileForm";
import UpDateProfileForm from "../../components/ActionSheets/UpdateProfileForm";
import rabbit from "../../assets/images/rabbit.png";
import { LoadingContainer } from "../../components/ActionSheets/LoadingAction";
import { NodataContainer } from "../../components/ActionSheets/NoData";
import { useTranslation } from "react-i18next";

const ProfilePage = () => {
  const history = useHistory();
  const [user, setUser] = useState<any>();
  const token = localStorage.getItem("token");
  const [loadingset, setLoadingset] = useState(false);
  const [addresses, setAddresses] = useState<any>([]);
  const [handleaddress, setHandleAddress] = useState<any>();
  const [details, showDetails] = useState<any>(false);
  const [installments, setinstallments] = useState<any>();
  const [forceupdate, setForceUpdate] = useState<any>();
  const [addAddress, setAddAddress] = useState<any>(false);
  const [present] = useIonToast();
  const { t } = useTranslation();
  const presentToast = (message: any) => {
    present({
      message: message,
      duration: 3000,
      position: "bottom",
    });
  };
  const getAddressList = useCallback(() => {
    try {
      AxiosApi({
        url: `${baseUrl}/api/user/address/list`,
        method: "post",
        data: { token },
      })
        .then((res: any) => {
          setAddresses(res?.data.data.rows);
          setLoadingset(false);
        })
        .catch((err) => {
          setLoadingset(false);
          presentToast(err?.response?.data.message);
        });
    } catch (e: any) {
      console.log(e);
    }
  }, [token]);

  useEffect(() => {
    setForceUpdate(false);
  }, [forceupdate]);
  useEffect(() => {
    setLoadingset(true);
    AxiosApi({
      url: `${baseUrl}/api/user/me`,
      method: "post",
      data: { token },
    })
      .then((res) => {
        setUser(res?.data.data);
        setLoadingset(false);
      })
      .catch((err) => {
        setLoadingset(false);
        history.push("/login");
        presentToast(err?.response?.data.message);
      });
  }, [history, token, forceupdate]);

  useEffect(() => {
    AxiosApi({
      url: `${baseUrl}/api/user/company/plan/list`,
      method: "post",
      data: { token },
    })
      .then((res: any) => {
        setinstallments(res?.data.data.rows);
        setLoadingset(false);
      })
      .catch((err) => {
        setLoadingset(false);
        presentToast(err?.response?.data.message);
      });
  }, [token, forceupdate]);

  const DeleteAddress = useCallback(
    (address: any) => {
      try {
        AxiosApi.post(`${baseUrl}/api/user/address/delete`, {
          token,
          id: address.id,
        }).then(() => {
          presentToast("Address Deleted Successfully");
          getAddressList();
        });
      } catch (err: any) {
        presentToast(err?.response?.data.message);
      }
    },
    [getAddressList, token]
  );

  useEffect(() => {
    getAddressList();
  }, [token, handleaddress, setForceUpdate, forceupdate, getAddressList]);

  function handleRefresh(event: any) {
    setTimeout(() => {
      AxiosApi({
        url: `${baseUrl}/api/user/address/list`,
        method: "post",
        data: { token },
      })
        .then((res: any) => {
          setAddresses(res?.data.data.rows);
        })
        .catch((err) => {
          presentToast(err?.response?.data.message);
        });
      AxiosApi({
        url: `${baseUrl}/api/user/me`,
        method: "post",
        data: { token },
      })
        .then((res) => {
          setUser(res?.data.data);
        })
        .catch((err) => {
          presentToast(err?.response?.data.message);
        });
      AxiosApi({
        url: `${baseUrl}/api/user/company/plan/list`,
        method: "post",
        data: { token },
      })
        .then((res: any) => {
          setinstallments(res?.data.data.rows);
          setLoadingset(false);
        })
        .catch((err) => {
          setLoadingset(false);
          presentToast(err?.response?.data.message);
        });
      event.detail.complete();
    }, 2000);
  }

  return (
    <IonPage>
      <IonContent fullscreen>
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent refreshingSpinner="circles"></IonRefresherContent>
        </IonRefresher>
        {loadingset ? (
          <LoadingContainer />
        ) : !details ? (
          !addAddress ? (
            <div id="appCapsule">
              <HeaderContainer headerTitle={t("profile")} />
              <div className="mt-5 profile-page">
                <div className="w-25  mx-auto">
                  <img src={rabbit} className="text-center nodata-img" />
                </div>
                <h3 className="text-center name">{user?.name}</h3>
                <div className="d-flex justify-content-center ">
                  <div className="d-flex flex-column text-center">
                    <p className="pointsnum m-0">{user?.balance}</p>
                    <span className="points">{t("point")}</span>
                  </div>
                </div>

                <div>
                  <div className="col-12 mt-2 ">
                    <ul className="nav nav-tabs capsuled" role="tablist">
                      <li className="nav-item">
                        <a
                          className="nav-link active "
                          data-bs-toggle="tab"
                          href="#addresses"
                          role="tab"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6 tabs-img"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                            />
                          </svg>
                          {t("addresses")}
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#info"
                          role="tab"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6 tabs-img"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z"
                            />
                          </svg>
                          {t("information")}
                        </a>
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#installments"
                          role="tab"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6 tabs-img"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z"
                            />
                          </svg>
                          {t("installment")}
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content mt-4 px-2">
                      <div
                        className="tab-pane fade active show"
                        id="addresses"
                        role="tabpanel"
                      >
                        <div className="row text-center">
                          {addresses.length !== 0 ? (
                            addresses?.map((address: any) => (
                              <div
                                className="d-flex  gap-4 text-left address-box mb-2 position-relative"
                                key={Math.random()}
                              >
                                <div className="vanbox">
                                  <img alt="logo" src={van} />
                                </div>
                                <div className="d-flex flex-column">
                                  <div className="d-flex  justify-content-between">
                                    <p
                                      style={{
                                        color: "#1C1C1C",
                                        fontSize: "11px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {t("deliverytohome")}
                                    </p>

                                    <div
                                      className="collapse show"
                                      id="tab-17"
                                      data-bs-parent="#tab-group-6"
                                    >
                                      <div className="">
                                        <div
                                          className="text-center "
                                          data-bs-toggle="offcanvas"
                                          data-bs-target="#menu-update-address"
                                        >
                                          <Link
                                            to="#"
                                            className="flex"
                                            onClick={() => {
                                              setHandleAddress(address);
                                              showDetails(true);
                                            }}
                                          >
                                            <span
                                              className=" position-absolute"
                                              style={{
                                                fontSize: "9px",
                                                right: "0px",
                                                color: "#FF9900",
                                                top: "50px",
                                              }}
                                            >
                                              {" "}
                                              {t("change")}
                                            </span>
                                          </Link>
                                          <Link
                                            to="#"
                                            className="flex "
                                            onClick={() => {
                                              DeleteAddress(address);
                                            }}
                                          >
                                            <i
                                              className=" position-absolute bi bi-x-circle"
                                              style={{
                                                fontSize: "20px",
                                                right: "2px",
                                                color: "#FF9900",
                                              }}
                                            />
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <p
                                    className="m-0 position relative d-flex gap-2"
                                    style={{
                                      color: "#1C1C1C",
                                      fontSize: "9px",
                                    }}
                                  >
                                    <i
                                      className="bi bi-geo-alt-fill "
                                      style={{
                                        color: "#211CA0",
                                        fontSize: "15px",
                                      }}
                                    />{" "}
                                    <p>
                                      {address?.Address?.address1}{" "}
                                      {address?.Address?.address2}
                                    </p>
                                  </p>
                                </div>
                              </div>
                            ))
                          ) : (
                            <NodataContainer />
                          )}
                          <div
                            className="collapse show mt-2"
                            id="tab-17"
                            data-bs-parent="#tab-group-6"
                          >
                            <div
                              className="card card-style w-100 mx-auto mb-5 py-3 "
                              style={{
                                backgroundColor: "rgb(238, 238, 238)",
                                filter: "drop-shadow(0px 3px 6px #0000001A)",
                              }}
                            >
                              <div
                                className="text-center "
                                onClick={() => setAddAddress(true)}
                              >
                                <Link
                                  to="#"
                                  className="flex"
                                  style={{
                                    fontSize: "11px",
                                  }}
                                >
                                  <i className="font-16 bi bi-house-fill" />
                                  <span> {t("addnewaddress")}</span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade " id="info" role="tabpanel">
                        <div className="text-center mb-2">
                          {!user?.UserProfile ? (
                            <div
                              className="collapse show pb-2"
                              id="tab-17"
                              data-bs-parent="#tab-group-6"
                            >
                              <div
                                className="card card-style mx-auto mb-0 py-2"
                                style={{
                                  color: `#56eea8`,
                                  backgroundColor: `#092C4C`,
                                  width: `50%`,
                                }}
                              >
                                <div
                                  className="text-center "
                                  data-bs-toggle="offcanvas"
                                  data-bs-target="#menu-create-profile"
                                >
                                  <Link
                                    to="#"
                                    className="flex "
                                    style={{
                                      color: `#56eea8`,
                                      backgroundColor: `#092C4C`,
                                    }}
                                  >
                                    <i className="font-14 bi bi-plus" />
                                    {t("createprofile")}
                                  </Link>
                                </div>
                              </div>
                              <div
                                id="menu-create-profile"
                                className="offcanvas offcanvas-bottom offcanvas-detached rounded-m "
                                style={{
                                  display: "block",
                                  visibility: "hidden",
                                }}
                                aria-modal="true"
                                role="dialog"
                              >
                                <div className="menu-size">
                                  <div className="d-flex mx-3 mt-3 py-1">
                                    <div className="align-self-center">
                                      <h1
                                        className="m-0 main-clr mb-2"
                                        style={{
                                          fontWeight: "700",
                                          fontSize: "24px",
                                          lineHeight: "30px",
                                        }}
                                      >
                                        {t("createprofile")}{" "}
                                      </h1>
                                    </div>
                                    <div className="align-self-center ms-auto">
                                      <Link
                                        to="#"
                                        className="ps-4"
                                        shadow-0=""
                                        data-bs-dismiss="offcanvas"
                                      >
                                        <i className="bi bi-x main-clr font-26 line-height-xl"></i>
                                      </Link>
                                    </div>
                                  </div>
                                  <div className="divider divider-margins mt-3"></div>

                                  <CreateProfileForm update={setForceUpdate} />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div
                              className="collapse show pb-2"
                              id="tab-17"
                              data-bs-parent="#tab-group-6"
                            >
                              <div
                                className="card card-style mx-auto mb-0 py-2"
                                style={{
                                  color: `#56eea8`,
                                  backgroundColor: `#092C4C`,
                                  width: `50%`,
                                }}
                              >
                                <div
                                  className="text-center "
                                  data-bs-toggle="offcanvas"
                                  data-bs-target="#menu-create-profile"
                                >
                                  <Link
                                    to="#"
                                    className="flex "
                                    style={{
                                      color: `#56eea8`,
                                      backgroundColor: `#092C4C`,
                                    }}
                                  >
                                    <i className="font-14 bi bi-plus" />
                                    <span> {t("updateprofile")}</span>
                                  </Link>
                                </div>
                              </div>
                              <div
                                id="menu-create-profile"
                                className="offcanvas offcanvas-bottom offcanvas-detached rounded-m "
                                style={{
                                  display: "block",
                                  visibility: "hidden",
                                }}
                                aria-modal="true"
                                role="dialog"
                              >
                                <div className="menu-size">
                                  <div className="d-flex mx-3 mt-3 py-1">
                                    <div className="align-self-center">
                                      <h1
                                        className="m-0 main-clr mb-2"
                                        style={{
                                          fontWeight: "700",
                                          fontSize: "24px",
                                          lineHeight: "30px",
                                        }}
                                      >
                                        {t("updateprofile")}{" "}
                                      </h1>
                                    </div>
                                    <div className="align-self-center ms-auto">
                                      <Link
                                        to="#"
                                        className="ps-4"
                                        shadow-0=""
                                        data-bs-dismiss="offcanvas"
                                      >
                                        <i className="bi bi-x main-clr font-26 line-height-xl"></i>
                                      </Link>
                                    </div>
                                  </div>
                                  <div className="divider divider-margins mt-3"></div>

                                  <UpDateProfileForm userData={user} />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="row text-left">
                          <p className="para-style">
                            {" "}
                            {t("phone")} : {user?.mobile}
                          </p>
                          {user?.UserProfile ? (
                            <>
                              <p className="para-style">
                                {" "}
                                {t("age")}: {user?.UserProfile.age}
                              </p>
                              <p className="para-style">
                                {" "}
                                {t("gender")}: {user?.UserProfile.gender}
                              </p>
                              <p className="para-style">
                                {" "}
                                {t("nationality")}:{" "}
                                {user?.UserProfile.nationality}
                              </p>
                            </>
                          ) : (
                            ``
                          )}
                        </div>
                      </div>
                      <div
                        className="tab-pane fade "
                        id="installments"
                        role="tabpanel"
                      >
                        {installments !== undefined &&
                        installments.length !== 0 ? (
                          <div className="Installment">
                            <div className="font-weight-bold">
                              <p className="para-style">
                                {t("companyname")} :{" "}
                                {installments[0]?.Company.nameEn}
                              </p>
                              <p className="para-style">
                                {t("companyaddress")} :{" "}
                                {installments[0]?.Company.addressEn}
                              </p>
                              <p className="para-style">
                                {t("companyphone")} :{" "}
                                {installments[0]?.Company.phone}
                              </p>
                              <p className="para-style">
                                {t("limit")} : {user?.AgentEmployees.limit}
                              </p>
                              <p className="para-style">
                                {t("installmentsavailablebalance")}:{" "}
                                {user?.AgentEmployees.installmentPointsBalance}
                              </p>
                            </div>
                            {installments?.map((installment: any) => (
                              <>
                                <div
                                  className={`${"active-address-box"} justify-content-between d-flex align-items-center mb-2`}
                                  key={installment?.id}
                                >
                                  <div className="d-flex flex-column align-items-start ">
                                    <p className="mb-0">
                                      {installment.Plan.name} {t("installment")}
                                    </p>
                                    <span> {t("description")}</span>
                                  </div>
                                </div>
                              </>
                            ))}
                          </div>
                        ) : (
                          <p className="para-style text-center">
                            <NodataContainer />
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <AddAddressForm
              setAddAddress={setAddAddress}
              update={setForceUpdate}
            />
          )
        ) : (
          <UpDateAddressForm
            addressData={handleaddress}
            showDetails={showDetails}
            update={setForceUpdate}
          />
        )}
      </IonContent>
      <Footer />
    </IonPage>
  );
};
export default ProfilePage;
