import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import styled from "@emotion/styled";
import AddPointsActionSheet from "../ActionSheets/AddPointsActionSheet";
import { useState } from "react";
const PUBLIC_KEY =
  "pk_test_51MM9KoAdxRDEy2srgF2mvzbUEuwnUBOQIvKC2Bw8Vcei249mLhbcVF7DGVlziF3DcL9lSdUBWZkenY9GZdjN3GPk00UXgACcTK";

const stripeTestPromise = loadStripe(PUBLIC_KEY);
type propsInterface = {
  user?: string;
  noPoints: boolean;
};
type styledPropsInterface = {
  error: boolean;
};
const AddPointsElement = ({ user, noPoints }: propsInterface) => {
  const [error, setError] = useState(false);
  return (
    <ElementsWrapper error={error}>
      <Elements stripe={stripeTestPromise}>
        <AddPointsActionSheet user={user} noPoints={noPoints} error={error} setError={setError}/>
      </Elements>
    </ElementsWrapper>
  );
};

export default AddPointsElement;

const ElementsWrapper = styled.div`
  .StripeElement {
    border: ${(props: styledPropsInterface) =>
      props.error ? "2px solid #d04444;" : "2px solid #092c4c;"};
    border-radius: 16px;
    padding: 10px 20px;
    width: 100%;
    animation: shake 150ms 2 linear;
    -moz-animation: shake 150ms 2 linear;
    -webkit-animation: shake 150ms 2 linear;
    -o-animation: shake 150ms 2 linear;

    @keyframes shake {
      0% {
        transform: translate(3px, 0);
      }
      50% {
        transform: translate(-3px, 0);
      }
      100% {
        transform: translate(0, 0);
      }
    }
  }
`;
