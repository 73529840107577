import { IonContent, IonPage } from "@ionic/react";
import { Footer } from "../../components";
import HeaderContainer from "../../components/Header";
import TicketList from "./TicketList";
import { useTranslation } from "react-i18next";

const Tickets = () => {
  const { t } = useTranslation();
  return (
    <IonPage>
      <IonContent fullscreen>
        <div id="appCapsule">
          <HeaderContainer headerTitle={t("tickets")} />
          <TicketList />
        </div>
      </IonContent>
      <Footer />
    </IonPage>
  );
};
export default Tickets;
