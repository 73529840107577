import Rating, { IconContainerProps } from "@mui/material/Rating";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import { styled } from "@mui/material/styles";

import AxiosApi from "../../utils/axios";
import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { baseUrl } from "../../utils/config";
import arrow from "../../assets/images/arrowback.svg";
import gift from "../../assets/images/gift.png";
import { useIonToast } from "@ionic/react";
import { LoadingContainer } from "../../components/ActionSheets/LoadingAction";
import { HandleTranslation } from "../../hooks";
import { useTranslation } from "react-i18next";
type Product = {
  offer: any;
  state: any;
};

const OfferFormRate = ({ offer, state }: Product) => {
  const StyledRating = styled(Rating)(({ theme }) => ({
    "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
      color: theme.palette.action.disabled,
    },
  }));

  const [comment, setComment] = useState("");
  const [value, setValue] = useState<number>(0);
  const token = localStorage.getItem("token");
  const [present] = useIonToast();
  const { t } = useTranslation();

  const presentToast = (message: any) => {
    present({
      message: message,
      duration: 3000,
      position: "bottom",
    });
  };
  const customIcons: {
    [index: string]: {
      icon: React.ReactElement;
      label: string;
    };
  } = {
    1: {
      icon: <SentimentVeryDissatisfiedIcon color="error" fontSize="large" />,
      label: "Very Dissatisfied",
    },
    2: {
      icon: <SentimentDissatisfiedIcon color="error" fontSize="large" />,
      label: "Dissatisfied",
    },
    3: {
      icon: <SentimentSatisfiedIcon color="success" fontSize="large" />,
      label: "Neutral",
    },
    4: {
      icon: <SentimentSatisfiedAltIcon color="success" fontSize="large" />,
      label: "Satisfied",
    },
    5: {
      icon: <SentimentVerySatisfiedIcon color="success" fontSize="large" />,
      label: "Very Satisfied",
    },
  };
  function IconContainer(props: IconContainerProps) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
  }
  const offerItem = useMemo(() => {
    return offer;
  }, [offer]);
  const RateProduct = (e: any) => {
    e.preventDefault();
    if (value === 0 || comment == "") {
      return presentToast("Please Enter Valid Review and Rating ");
    }
    AxiosApi.post(`${baseUrl}/api/user/offer/rate`, {
      token,
      offer_id: offerItem?.offerDetail.id,
      rate: value,
      request_offer_id: offerItem.id,
      rateComment: comment,
    })
      .then(() => {
        state(false);
        presentToast("Offer Rated Successfully ");
      })
      .catch((err) => {
        state(false);
        presentToast(err?.response?.data.message);
      });
  };

  return (
    <>
      {offerItem ? (
        <>
          <div className="left ms-5">
            <Link
              to="#"
              onClick={() => {
                state(false);
              }}
              className="headerButton"
            >
              <img className="" src={arrow} alt="heart" />
            </Link>
          </div>
          <div className="d-flex justify-content-cneter flex-column align-items-center  p-2 pb-5">
            <img className="w-25" src={gift} alt="gift" />
            <h2 className="font-weight-bold ">{t("rateoffer")}</h2>
            <h4 className="font-weight-bold text-start mb-1 mt-0 opacity-75">
              {HandleTranslation(
                offerItem?.offerDetail.title,
                offerItem?.offerDetail.titleAr
              )}
            </h4>
            <h6 className=" opacity-50 m-0 mb-1">
              {HandleTranslation(
                offerItem?.offerDetail.description,
                offerItem?.offerDetail.descriptionAr
              )}
            </h6>
            <StyledRating
              onChange={(event, newValue: any) => {
                setValue(newValue);
              }}
              name=" highlight-selected-only"
              value={value}
              IconContainerComponent={IconContainer}
              getLabelText={(value: number) => customIcons[value].label}
              highlightSelectedOnly
            />

            <span className="para-style ">{t("clicktorate")}</span>

            <div className="w-100">
              {" "}
              <span className="mb-0 para-style">{t("offerreview")}</span>
              <textarea
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  setComment(e.target.value)
                }
                className="form-control "
                rows={8}
                cols={1}
              ></textarea>
            </div>
            <button
              className="btn btn-primary btn-block btn-lg mt-3"
              onClick={RateProduct}
            >
              {t("submit")}
            </button>
          </div>
        </>
      ) : (
        <LoadingContainer />
      )}
    </>
  );
};

export default OfferFormRate;
