import React, { useEffect, useRef, useState } from "react";
import bot from "../../assets/images/bot.png";

// import { Header, Footer } from '../../components'
import {
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useHistory, Link } from "react-router-dom";
import alert from "../../assets/images/alert.svg";
import heart from "../../assets/images/heart.svg";
import plus from "../../assets/images/plus.svg";
import ticket from "../../assets/images/ticket.svg";
import wallet from "../../assets/images/wallet.svg";
import offers from "../../assets/images/offers.svg";
import fire from "../../assets/images/fire.svg";
import pizza from "../../assets/images/pizza.svg";
import arrow from "../../assets/images/arrow.svg";
import products from "../../assets/images/products.svg";
import starb from "../../assets/images/dummyicon.png";
import addd from "../../assets/images/addd.svg";
import label from "../../assets/images/label.svg";
import methods from "../../assets/images/methods.svg";
import { IonSearchbar } from "@ionic/react";
import { Footer } from "../../components/Footer";
import AxiosApi from "../../utils/axios";
import { baseUrl } from "../../utils/config";
import { LoadingContainer } from "../../components/ActionSheets/LoadingAction";
import AddPointsElement from "../../components/stripe/addPointsElement";
import { getCurrentPosition } from "../../utils/geolocation";
import FormRate from "./FormRate";
import OfferFormRate from "./OfferFormRate";
import { HandleTranslation } from "../../hooks";
import i18n from "../../i18n/i18n";
import { useTranslation } from "react-i18next";
import { NodataContainer } from "../../components/ActionSheets/NoData";

// import FormRate from "./FormRate";

interface geoInterface {
  lon: number;
  lat: number;
  timestamp: number;
}
const HomePage: React.FC = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const [user, setUser] = useState<any>();
  const [offer, setOffer] = useState<any>();
  const [product, setProducts] = useState<any>();
  const token = localStorage.getItem("token");
  const [loadingset, setLoadingset] = useState(false);
  const [orderList, setOrderList] = useState<any[]>([]);
  const [openSearch, setOpenSearch] = useState(false);
  const [geo, setGeo] = useState<geoInterface>();
  const [searchData, setSearchData] = useState<any>([]);
  const [rate, setRate] = useState<boolean>(false);
  const [ratedOffer, setRatedOffer] = useState<boolean>(false);
  const [rateItem, setRateItem] = useState<any>("");
  const [rateOffer, setRateOffer] = useState();
  const [favList, setFavList] = useState<any>("");

  const searchMenu: any = useRef();

  const handleSearchMenu = (e: any) => {
    if (searchMenu.current && !searchMenu.current.contains(e.target)) {
      setOpenSearch(false);
    }
  };
  document.addEventListener("mousedown", handleSearchMenu);
  useEffect(() => {
    try {
      AxiosApi({
        url: `${baseUrl}/api/user/favouriteTag/list`,
        method: "post",
        data: { token },
      }).then((res) => {
        if (res?.data.data.length < 3) {
          setFavList(false);
          return history.push("/favouritetags");
        } else {
          setFavList(true);
        }
        setLoadingset(false);
      });
    } catch (err: any) {
      console.log(err);
      setLoadingset(false);
    }
  }, []);
  useEffect(() => {
    try {
      favList &&
        AxiosApi.post(`${baseUrl}/api/user/product/unrated`, { token }).then(
          (res) => {
            if (res?.data.data.length > 0) {
              setRateItem(res?.data.data[0]);
              return setRate(true);
            } else {
              setRateItem(undefined);
            }
          }
        );
    } catch (err) {
      setLoadingset(false);

      console.log(err);
    }
  }, [favList, token]);
  useEffect(() => {
    try {
      favList &&
        rateItem === undefined &&
        AxiosApi.post(`${baseUrl}/api/user/offers/unrated`, { token }).then(
          (res) => {
            console.log(res?.data.data.rows[0]);
            if (res?.data.data.rows.length > 0) {
              setRateOffer(res?.data.data.rows[0]);
              return setRatedOffer(true);
            }
          }
        );
    } catch (err) {
      console.log(err);
    }
  }, [favList, rateItem, token]);

  useEffect(() => {
    getCurrentPosition().then((result) => {
      setGeo({
        lon: result?.coords.longitude,
        lat: result?.coords.latitude,
        timestamp: result?.timestamp,
      });
      localStorage.setItem(
        "geo",
        JSON.stringify({
          lon: result?.coords.longitude,
          lat: result?.coords.latitude,
          timestamp: result?.timestamp,
        })
      );
    });
  }, []);

  useEffect(() => {
    setLoadingset(true);
    AxiosApi({
      url: `${baseUrl}/api/user/me`,
      method: "post",
      data: { token, geo },
    })
      .then((res) => {
        setUser(res?.data?.data);
        setLoadingset(false);
      })
      .catch((err) => {
        setLoadingset(false);
        history.push("/login");
        console.log(err);
      });
  }, [geo]);

  useEffect(() => {
    AxiosApi({
      url: `${baseUrl}/api/public/allOffers/list`,
      method: "post",
      data: { token, limit: 5 },
    })
      .then((res) => {
        setOffer(res?.data?.data?.rows);
        setLoadingset(false);
      })
      .catch((err) => {
        setLoadingset(false);
        setOpenSearch(false);
        console.log(err);
      });
  }, []);

  useEffect(() => {
    AxiosApi({
      url: `${baseUrl}/api/user/product/list`,
      method: "post",
      data: { token, limit: 5 },
    })
      .then((res) => {
        setProducts(res?.data.data.rows);
        setLoadingset(false);
      })
      .catch((err) => {
        setLoadingset(false);
        history.push("/login");
        console.log(err);
      });
  }, []);
  useEffect(() => {
    AxiosApi({
      url: `${baseUrl}/api/user/list/req`,
      method: "post",
      data: { token },
    }).then((res) => setOrderList(res?.data.data.rows));
  }, []);

  const merchantsearch = (name: any) => {
    if (name === "") {
      return;
    }
    setOpenSearch(true);
    AxiosApi.post(`${baseUrl}/api/public/merchant/search`, { name }).then(
      (res: any) => {
        setSearchData(res.data.data.rows);
      }
    );
  };
  function handleRefresh(event: any) {
    setTimeout(() => {
      AxiosApi({
        url: `${baseUrl}/api/user/me`,
        method: "post",
        data: { token },
      })
        .then((res) => {
          setUser(res?.data.data);
        })
        .catch((err) => {
          setLoadingset(false);

          history.push("/login");
          console.log(err);
        });
      AxiosApi({
        url: `${baseUrl}/api/public/allOffers/list`,
        method: "post",
        data: { token: "test", limit: 5 },
      })
        .then((res) => {
          setOffer(res?.data?.data?.rows);
        })
        .catch((err) => {
          setLoadingset(false);
          setOpenSearch(false);
          history.push("/login");
          console.log(err);
        });
      AxiosApi({
        url: `${baseUrl}/api/user/product/list`,
        method: "post",
        data: { token, limit: 5 },
      })
        .then((res) => {
          setProducts(res?.data.data.rows);
          setLoadingset(false);
        })
        .catch((err) => {
          setLoadingset(false);
          history.push("/login");
          console.log(err);
        });
      AxiosApi({
        url: `${baseUrl}/api/user/list/req`,
        method: "post",
        data: { token },
      }).then((res) => setOrderList(res?.data.data.rows));

      event.detail.complete();
    }, 2000);
  }

  return (
    <IonPage>
      <IonContent fullscreen>
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent refreshingSpinner="circles"></IonRefresherContent>
        </IonRefresher>
        <div id="appCapsule">
          {loadingset ? (
            <LoadingContainer />
          ) : rate ? (
            <FormRate product={rateItem} state={setRate} />
          ) : ratedOffer ? (
            <OfferFormRate offer={rateOffer} state={setRatedOffer} />
          ) : (
            <div className="container px-0 ">
              <div className="row home px-3">
                {/* <h4>test {window.location.href}</h4> */}
                <div className={`col-6 `}>
                  <h1 className="main-h1">
                    {t("hi")} {user?.name}
                  </h1>
                </div>
                <div
                  className={`col-6 text-right
           `}
                >
                  <Link to="favourites">
                    <img className="mx-2" src={heart} alt="heart" />
                  </Link>
                  <Link to="notifications">
                    <img className="" src={alert} alt="alert" />
                  </Link>

                  {/* <a className="headerButton">
                    <img
                      className=""
                      src={bot}
                      alt="bot"
                      onClick={() => {
                        window.botpressWebChat.sendEvent({ type: "show" });
                      }}
                    />
                  </a>
                  <button
                    className="bg-white main-clr"
                    style={{
                      fontWeight: "bold",
                    }}
                    onClick={(e: any) => {
                      chooseLang(e.target.innerHTML);
                    }}
                  >
                    {lang}
                  </button> */}
                </div>

                <div className="col-12 mt-2 position-relative">
                  <>
                    <IonSearchbar
                      onIonChange={(e: any) => merchantsearch(e.detail.value)}
                      placeholder={t("whatoffer")}
                    />

                    {searchData.length > 0 && openSearch && (
                      <ul
                        ref={searchMenu}
                        className="d-flex gap-5 flex-wrap position-absolute  bg-white p-3 flex-row home__search____Box"
                      >
                        {searchData.length > 0 ? (
                          searchData.map((data: any) => (
                            <Link
                              to={`/merchantdetails/${data.id}`}
                              key={data?.id}
                            >
                              {data.name}
                            </Link>
                          ))
                        ) : (
                          <NodataContainer />
                        )}
                      </ul>
                    )}
                  </>
                </div>

                <div className="col-12 mt-2 mb-3">
                  <img className="wallet-img" src={wallet} alt="wallet" />
                  <span className="sub-title"> {t("yourpoints")}</span>
                </div>
                <div className="col-5 ">
                  <div className="points-box">
                    <div className="point-title">{t("points")}</div>
                    <div className="total-earned">{t("total")} !</div>
                    <div className="total-points">
                      {HandleTranslation(
                        user?.balance,
                        Number(user?.balance).toLocaleString("ar-u-nu-arab")
                      )}

                      <span>{t("point")}</span>
                    </div>
                    <a className="manage-all">
                      {t("managepoints")}{" "}
                      <img className="" src={arrow} alt="arrow" />{" "}
                    </a>
                  </div>
                </div>
                <div className="col-7 ">
                  <div className="row">
                    <div className="col-4 mt-1">
                      <div className="redeem-box  text-center">
                        <h5>{t("balance")}</h5>
                        <p>
                          {HandleTranslation(
                            user?.balance,
                            Number(user?.balance).toLocaleString("ar-u-nu-arab")
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="col-8 mt-1">
                      <button
                        className="btn  relative btn-green"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#actionSheetContent2"
                      >
                        {t("addpoints")}{" "}
                        <img className="img-absolut" src={plus} alt="plus" />
                      </button>

                      <AddPointsElement user={user} noPoints={false} />
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-4">
                      <div className="redeem-box  text-center">
                        <h5>{t("cash")}</h5>
                        <p>
                          {HandleTranslation(
                            Math.ceil(user?.balance / 5),
                            Math.ceil(user?.balance / 5).toLocaleString(
                              "ar-u-nu-arab"
                            )
                          )}
                          ${" "}
                        </p>
                      </div>
                    </div>

                    <div className="col-8">
                      <button
                        type="button"
                        className="btn relative btn-green"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#actionSheetContente"
                      >
                        {t("requests")}{" "}
                        <img
                          className="img-absolut"
                          src={ticket}
                          alt="ticket"
                        />
                      </button>

                      <div
                        className="offcanvas offcanvas-bottom action-sheet section"
                        id="actionSheetContente"
                      >
                        <div className="offcanvas-header">
                          <h5 className="offcanvas-title">
                            {" "}
                            <img className="" src={label} alt="label" />{" "}
                            {t("requestshistory")}{" "}
                            <a
                              href="#"
                              className=""
                              data-bs-dismiss="offcanvas"
                            ></a>
                          </h5>
                        </div>
                        <div className="offcanvas-body">
                          <div className="action-sheet-content">
                            <div className="requests">
                              {orderList.length !== 0 ? (
                                orderList?.map((list: any) => (
                                  <div
                                    className="history-card splide_grey"
                                    onClick={() => {
                                      history.push(
                                        `/offers/${list?.offerDetail?.id}`
                                      );
                                    }}
                                    key={Math.random()}
                                  >
                                    <div className="row">
                                      <div className="col-3">
                                        <div className="circle">
                                          <img
                                            className=""
                                            src={
                                              list?.merchant?.image
                                                ? `https://api.eshailx.com/${list?.merchant?.image}`
                                                : starb
                                            }
                                            alt="starb"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-5 px-0 slide-box-container">
                                        <h4> {list?.merchant?.name}</h4>
                                        <h5 className="mb-0">
                                          {list?.offerDetail.points}{" "}
                                          {t("points")}
                                        </h5>
                                        <h6 className="mt-0 pt-0"></h6>
                                      </div>
                                      <div className="col-4 px-0">
                                        <button className="btn btn-spend">
                                          {list?.isAproved
                                            ? "Approved"
                                            : "Processing"}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <NodataContainer />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 mt-2">
                  <ul className="nav nav-tabs capsuled" role="tablist">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        data-bs-toggle="tab"
                        href="#home"
                        role="tab"
                      >
                        <img className="tabs-img" src={offers} alt="offers" />{" "}
                        {t("offers")}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        href="#profile"
                        role="tab"
                      >
                        <img
                          className="tabs-img"
                          src={products}
                          alt="products"
                        />{" "}
                        {t("products")}
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content mt-2">
                    <div
                      className="tab-pane fade show active"
                      id="home"
                      role="tabpanel"
                    >
                      <div className="section full mb-2">
                        <span className="section-title"></span>

                        <div className="carousel-single splide">
                          <div className="splide__track">
                            <ul className="splide__list">
                              {offer?.map((offer: any, i: any) => (
                                <li
                                  id="slide-auto"
                                  className={`splide__slide ${
                                    i % 2 === 0 ? "" : "splide_grey"
                                  } `}
                                  key={offer?.id}
                                >
                                  <div className="row">
                                    <div className="col-3">
                                      <div className="circle">
                                        <img
                                          className=""
                                          src={
                                            offer?.merchant.image === null
                                              ? starb
                                              : ` https://api.eshailx.com/${offer?.merchant.image}`
                                          }
                                          alt="starb"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-5 slide-box-container">
                                      <h4>{offer?.merchant?.name}</h4>
                                      <h5>
                                        {" "}
                                        {HandleTranslation(
                                          offer?.points,
                                          Number(offer?.points).toLocaleString(
                                            "ar-u-nu-arab"
                                          )
                                        )}{" "}
                                        {t("point")}
                                      </h5>
                                    </div>
                                    <div className="col-4 px-0">
                                      {" "}
                                      <button
                                        className="btn btn-spend"
                                        onClick={() => {
                                          history.push(`/offers/${offer?.id}`);
                                        }}
                                      >
                                        {t("spend")}
                                      </button>
                                    </div>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade mt-2"
                      id="profile"
                      role="tabpanel"
                    >
                      <div className="carousel-single splide">
                        <div className="splide__track mb-2">
                          <ul className="splide__list">
                            {product?.map((product: any, i: any) => (
                              <li
                                id="slide-auto"
                                className={`splide__slide ${
                                  i % 2 === 0 ? "" : "splide_grey"
                                } `}
                                key={product?.id}
                              >
                                <div className="row">
                                  <div className="col-3">
                                    <div className="circle">
                                      <img
                                        className=""
                                        src={
                                          product?.image === null
                                            ? starb
                                            : ` https://api.eshailx.com/${product?.image}`
                                        }
                                        alt="starb"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-5 slide-box-container">
                                    <h4>{product?.merchant?.name}</h4>
                                    <h5>
                                      {HandleTranslation(
                                        product?.points,
                                        Number(product?.points).toLocaleString(
                                          "ar-u-nu-arab"
                                        )
                                      )}{" "}
                                      {t("point")}
                                    </h5>
                                  </div>
                                  <div className="col-4 px-0">
                                    {" "}
                                    <button
                                      onClick={() => {
                                        history.push(`/product/${product?.id}`);
                                      }}
                                      className="btn btn-spend"
                                    >
                                      {t("spend")}
                                    </button>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 mb-3">
                  <h6 className="sub-title mt-1">
                    <img className="wallet-img" src={fire} alt="fire" />{" "}
                    {t("newoffers")}!
                  </h6>

                  <div className="section full mb-3">
                    <span className="section-title"></span>

                    <div className="carousel-single splide">
                      <div className="splide__track">
                        <ul className="splide__list  splide-2">
                          {offer?.map((offer: any, i: any) => (
                            <li
                              key={Math.random()}
                              id="slide-auto"
                              className={`splide__slide ${
                                i % 2 === 0 ? "bigger-box" : "bigger-box-grey"
                              }  `}
                            >
                              <div className="row">
                                <div className="col-7 slide-box-container2">
                                  <h4
                                    style={{
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {HandleTranslation(
                                      offer?.points,
                                      Number(offer?.points).toLocaleString(
                                        "ar-u-nu-arab"
                                      )
                                    )}{" "}
                                    {t("point")}
                                  </h4>
                                  <h5>
                                    {HandleTranslation(
                                      new Date(offer?.endDate).getDate() -
                                        new Date(offer?.startDate).getDate(),
                                      Number(
                                        new Date(offer?.endDate).getDate() -
                                          new Date(offer?.startDate).getDate()
                                      ).toLocaleString("ar-u-nu-arab")
                                    )}{" "}
                                    {t("daysleft")}{" "}
                                  </h5>
                                </div>
                                <div className="col-4">
                                  <div className="circle">
                                    <img
                                      className=""
                                      src={`${
                                        offer?.merchant?.image === null
                                          ? pizza
                                          : ` https://api.eshailx.com/${offer?.merchant?.image}`
                                      }`}
                                      alt="pizza"
                                    />
                                  </div>
                                </div>
                                <p className="box-p d-block  text-white font-bold">
                                  {HandleTranslation(
                                    offer?.price,
                                    Number(offer?.price).toLocaleString(
                                      "ar-u-nu-arab"
                                    )
                                  )}{" "}
                                  <span className="">/{t("aday")}</span>
                                </p>
                                <div className="col-7">
                                  <button
                                    className="btn-2 btn"
                                    onClick={() => {
                                      history.push(`/offers/${offer?.id}`);
                                    }}
                                  >
                                    {t("spend")}
                                  </button>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}{" "}
        </div>
      </IonContent>
      <Footer />
    </IonPage>
  );
};

export default HomePage;

declare global {
  interface Window {
    // ⚠️ notice that "Window" is capitalized here
    botpressWebChat: any;
  }
}
