//export const baseUrl = "http://45.77.29.107:4200";
export const baseUrl = "https://api.eshailx.com";
// export const baseUrl = "http://localhost:4200";

// export const baseUrl = "http://localhost:4200";

export const langOptions = [
  { value: "en", isRTL: false, label: "EN", icon: "us" },
  { value: "ar", isRTL: true, label: "AR", icon: "ae" },
];
