import HeaderContainer from "../../components/Header";
import offerg from "../../assets/images/offerg.svg";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonToast,
} from "@ionic/react";
import { Footer } from "../../components/Footer";
import product from "../../assets/images/products.svg";
import hrtred from "../../assets/images/hrtred.svg";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import fries from "../../assets/images/fries.svg";
import mac from "../../assets/images/mac.svg";
import { EmptyData } from "../../components/EmptyData";
import { NodataContainer } from "../../components/ActionSheets/NoData";
import { HandleTranslation } from "../../hooks";
import { useTranslation } from "react-i18next";

const FavouritesPage: React.FC = () => {
  const [present] = useIonToast();
  const presentToast = (message: any) => {
    present({
      message: message,
      duration: 3000,
      position: "bottom",
    });
  };
  const { t } = useTranslation();
  const [offerslist, setOfferslist] = useState<any>([]);
  const [productslist, setProductslist] = useState<any>([]);

  useEffect(() => {
    const localS: any = localStorage.getItem("favourites_offers");
    const arr = JSON.parse(localS);
    setOfferslist(arr);

    const localSP: any = localStorage.getItem("favourites_products");
    const arrS = JSON.parse(localSP);
    setProductslist(arrS);
  }, []);

  const handleDeleteFunc = (offer: any) => {
    let updatedOfferList = offerslist.filter((singleOffer: any) => {
      return singleOffer.id != offer.id;
    });
    setOfferslist(updatedOfferList);
    localStorage.setItem("favourites_offers", JSON.stringify(updatedOfferList));
    presentToast("Offer Removed Successfully");
  };

  const handleDeleteProductFunc = (productItem: any) => {
    let updatedProductList = productslist.filter((singleProduct: any) => {
      return singleProduct.id != productItem.id;
    });

    setProductslist(updatedProductList);
    localStorage.setItem(
      "favourites_products",
      JSON.stringify(updatedProductList)
    );
    presentToast("Product Removed Successfully");
  };

  return (
    <IonPage>
      <IonContent fullscreen>
        <div id="appCapsule">
          <HeaderContainer headerTitle={t("favourites")} />
          <div className="section favourites">
            <div className="col-12 mt-5">
              <ul className="nav nav-tabs capsuled" role="tablist">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    data-bs-toggle="tab"
                    href="#homes"
                    role="tab"
                  >
                    <img className="tabs-img" src={offerg} alt="offerg" />{" "}
                    {t("offers")}{" "}
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-bs-toggle="tab"
                    href="#products"
                    role="tab"
                  >
                    <img className="tabs-img" src={product} alt="products" />{" "}
                    {t("products")}{" "}
                  </a>
                </li>
              </ul>
              <div className="tab-content mt-2">
                <div
                  className="tab-pane fade active show"
                  id="homes"
                  role="tabpanel"
                >
                  <div className="row text-center">
                    {offerslist && offerslist.length > 0 ? (
                      offerslist?.map((offer: any) => (
                        <div className="col-6 mt-2 " key={offer?.id}>
                          <div className="card product-card">
                            <div className="card-body">
                              <img
                                className="brand-position"
                                src={`${
                                  offer?.Offer.images === null
                                    ? mac
                                    : `https://api.eshailx.com/${offer?.Offer?.images[0]}`
                                }   `}
                                alt="fries"
                              />
                              <h5 className="offercard-title">
                                {HandleTranslation(
                                  offer?.Offer.title,
                                  offer?.Offer.titleAr
                                )}
                              </h5>
                              <h5 className="offercard-earn">
                                {t("earn")}{" "}
                                <span className="offercard-earnpoints">
                                  {HandleTranslation(
                                    offer?.Offer.points,
                                    Number(offer?.Offer.points).toLocaleString(
                                      "ar-u-nu-arab"
                                    )
                                  )}
                                </span>{" "}
                                {t("point")}
                              </h5>
                              <img
                                className="position-absolute "
                                style={{
                                  top: "15px",
                                  right: "10px",
                                }}
                                src={hrtred}
                                alt="hrtred"
                                onClick={() => handleDeleteFunc(offer)}
                              />

                              <img
                                className="product-position"
                                src={`${
                                  offer?.Offer.merchant.image === null
                                    ? mac
                                    : `https://api.eshailx.com/${offer?.Offer.merchant.image} `
                                }   `}
                                alt="logo"
                              />

                              <p className="offercard-desc offercard2">
                                {HandleTranslation(
                                  offer?.Offer.description,
                                  offer?.Offer.descriptionAr
                                )}
                              </p>
                              <div className="bottomBtns">
                                <Link
                                  to={`/offers/${offer?.Offer?.id}`}
                                  className="btn btn-sm btn-primary btn-block blue-btn"
                                >
                                  {t("earnpoints")}
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <NodataContainer />
                    )}
                  </div>
                </div>

                <div className="tab-pane fade" id="products" role="tabpanel">
                  <div className="row text-center">
                    {productslist && productslist.length > 0 ? (
                      productslist?.map((product: any) => (
                        <div className="col-6 mt-2 " key={product?.id}>
                          <div className="card product-card">
                            <div className="card-body">
                              <img
                                className="brand-position"
                                src={
                                  product?.image === null
                                    ? fries
                                    : `https://api.eshailx.com/${product?.image}`
                                }
                                alt="fries"
                              />
                              <h5 className="offercard-title">
                                {HandleTranslation(
                                  product?.name,
                                  product?.nameAr
                                )}
                              </h5>
                              <h5 className="offercard-earn">
                                {t("earn")}{" "}
                                <span className="offercard-earnpoints">
                                  {HandleTranslation(
                                    product?.points,
                                    Number(product?.points).toLocaleString(
                                      "ar-u-nu-arab"
                                    )
                                  )}
                                </span>{" "}
                                {t("point")}
                              </h5>
                              <img
                                className="position-absolute "
                                style={{
                                  top: "15px",
                                  right: "10px",
                                }}
                                src={hrtred}
                                alt="hrtred"
                                onClick={() => handleDeleteProductFunc(product)}
                              />

                              {/* <img className="product-position" src={products} alt="product" /> */}
                              <p className="offercard-desc offercard2">
                                {HandleTranslation(
                                  product.description,
                                  product.descriptionAr
                                )}
                              </p>
                              <a
                                href="#"
                                className="btn btn-sm btn-primary btn-block blue-btn"
                              >
                                {t("buy")}
                              </a>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <NodataContainer />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
      <Footer />
    </IonPage>
  );
};

export default FavouritesPage;
