import React from "react";
import HeaderContainer from "../../components/Header";
import Offers from "./OffersList";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Footer } from "../../components/Footer";
import { useTranslation } from "react-i18next";

const OffersPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <IonPage>
      <IonContent fullscreen>
        <div id="appCapsule">
          <HeaderContainer headerTitle={t("offers")} />

          <Offers />
        </div>
      </IonContent>
      <Footer />
    </IonPage>
  );
};

export default OffersPage;
