import {
  IonButton,
  IonContent,
  IonPage,
  useIonAlert,
  useIonToast,
} from "@ionic/react";
import { useCallback, useEffect, useState } from "react";
import { Footer } from "../../components";
import HeaderContainer from "../../components/Header";
import mastercard from "../../assets/images/mastercard.png";
import visa from "../../assets/images/visa.png";
import cash from "../../assets/images/cash.png";
import { baseUrl } from "../../utils/config";
import { useHistory } from "react-router";
import AxiosApi from "../../utils/axios";
import { Link } from "react-router-dom";
import { LoadingContainer } from "../../components/ActionSheets/LoadingAction";
import { presentToast } from "../../common/toast";
import { HandleTranslation } from "../../hooks";
import { useTranslation } from "react-i18next";

const PaymentMethods = () => {
  const token = localStorage.getItem("token");
  const history = useHistory();
  const [user, setUser] = useState<any>();
  const [cardlist, setCardList] = useState<any>();
  const [loadingset, setLoadingset] = useState(false);
  const [method, setMethod] = useState("personal");
  const [presentAlert] = useIonAlert();
  const [present] = useIonToast();
  const { t } = useTranslation();
  useEffect(() => {
    setLoadingset(true);
    AxiosApi({
      url: `${baseUrl}/api/user/me`,
      method: "post",
      data: { token },
    })
      .then((res) => {
        setUser(res?.data.data);
        setLoadingset(false);
      })
      .catch((err) => {
        setLoadingset(false);
        history.push("/login");
        console.log(err);
      });
  }, [history, token]);

  const getCardList = useCallback(() => {
    AxiosApi({
      url: `${baseUrl}/api/user/card/list`,
      method: "post",
      data: { token },
    })
      .then((res) => {
        setCardList(res?.data.data);
      })
      .catch((err) => {
        setLoadingset(false);

        console.log(err);
      });
  }, []);

  const SetCardAsDefault = (id: any, e: any) => {
    let checkboxes: any = document.getElementsByClassName("payment-checkboxes");
    for (let i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = false;
      checkboxes[i].disabled = false;
      e.target.checked = true;
      e.target.disabled = true;
    }
    try {
      AxiosApi.post(`${baseUrl}/api/user/card/setDefault`, { id, token }).then(
        () => {
          localStorage.setItem("defaultCard", id);
          getCardList();
          setLoadingset(false);
        }
      );
    } catch (err: any) {
      console.log(err);
      setLoadingset(false);
    }
  };

  const HandleDeleteCard = async (id: any) => {
    try {
      await AxiosApi.post(`${baseUrl}/api/user/card/delete`, {
        id,
        token,
      }).then(() => {
        getCardList();
        presentToast(present, "Card has been deleted", "success");
        setLoadingset(false);
      });
    } catch (err: any) {
      presentToast(present, err.response?.data?.message, "danger");
      setLoadingset(false);
    }
  };
  useEffect(() => {
    getCardList();
  }, [getCardList]);
  return (
    <IonPage>
      {loadingset ? (
        <LoadingContainer />
      ) : (
        <>
          <IonContent fullscreen>
            <div id="appCapsule">
              <HeaderContainer headerTitle={t("paymentmethods")} />
              <div className="p-3 payment-methods">
                <h1 className="head">{t("paymentoptions")}</h1>
                <div className="nav nav-tabs capsuled" role="tablist">
                  <div className="nav-item">
                    <a
                      className={`nav-link ${
                        method === "personal" ? "active" : ""
                      } `}
                      data-bs-toggle="tab"
                      href="#personal"
                      role="tab"
                      onClick={() => setMethod("personal")}
                    >
                      {t("personal")}{" "}
                    </a>
                  </div>
                  <div className="nav-item">
                    <a
                      className={`nav-link ${
                        method === "business" ? "active" : ""
                      } `}
                      data-bs-toggle="tab"
                      href="#business"
                      onClick={() => setMethod("business")}
                      role="tab"
                    >
                      {t("business")}{" "}
                    </a>
                  </div>
                </div>
                <div className="tab-content mt-5">
                  <div
                    className="tab-pane fade active show "
                    id="personal"
                    role="tabpanel"
                  >
                    <div>
                      <h5 className="opacity-50 mb-3">
                        {t("personalbusiness")}
                      </h5>
                      <div className="row">
                        {cardlist?.map((card: any, i: any) => (
                          <div
                            className={`row mb-2 border  align-items-center p-2 `}
                            key={card?.id}
                          >
                            <img className="col-2" src={visa} alt="visa" />
                            <div className=" col-9">
                              <p className="payment_methods-list mb-0">
                                {" "}
                                {card.nameOnCard}
                              </p>
                              <span className="payment_methods-list">
                                {" "}
                                {`xxxxxxxxxx-${card.cardNumber}`}
                              </span>
                            </div>
                            <div className="col-1 flex-column align-items-center d-flex ">
                              {!card?.isDefault && (
                                <IonButton
                                  fill="clear"
                                  onClick={() =>
                                    presentAlert({
                                      header:
                                        "Are you Sure you want to delete this Card",
                                      buttons: [
                                        {
                                          text: "Cancel",
                                          role: "cancel",
                                        },
                                        {
                                          text: "OK",
                                          role: "confirm",
                                          cssClass: "btn-danger",
                                          handler: () => {
                                            HandleDeleteCard(card?.id);
                                          },
                                        },
                                      ],
                                    })
                                  }
                                >
                                  <i className="bi bi-x-circle"></i>
                                </IonButton>
                              )}
                              {card?.isDefault && cardlist.length === 1 && (
                                <IonButton
                                  fill="clear"
                                  onClick={() =>
                                    presentAlert({
                                      header:
                                        "Are you Sure you want to delete this Card",

                                      buttons: [
                                        {
                                          text: "Cancel",
                                          role: "cancel",
                                        },
                                        {
                                          text: "OK",
                                          role: "confirm",
                                          cssClass: "btn-danger",
                                          handler: () => {
                                            HandleDeleteCard(card?.id);
                                          },
                                        },
                                      ],
                                    })
                                  }
                                >
                                  <i className="bi bi-x-circle"></i>
                                </IonButton>
                              )}

                              <input
                                className="form-check-input mt-1 payment-checkboxes"
                                type="checkbox"
                                title="checkbox"
                                value={card?.isDefault}
                                id="flexCheckDefault"
                                defaultChecked={card?.isDefault}
                                onClick={(e: any) =>
                                  SetCardAsDefault(card?.id, e)
                                }
                                disabled={
                                  card?.isDefault === true ? true : false
                                }
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                      <Link className="para-style" to="/transactions">
                        <i className="bi bi-arrow-right-short"></i>{" "}
                        {t("showalltransactions")}
                      </Link>{" "}
                    </div>
                    <div className="text-center mt-3">
                      <button
                        className="btn btn-primary btn-block btn-lg"
                        onClick={() => history.push("/addpaymentmethod")}
                      >
                        {t("addnewcard")}
                      </button>
                    </div>
                  </div>
                  <div className="tab-pane fade " id="business" role="tabpanel">
                    <p className="para-style">
                      {t("point")} :{" "}
                      {HandleTranslation(
                        user?.balance,
                        Number(user?.balance).toLocaleString("ar-u-nu-arab")
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </IonContent>
          <Footer />
        </>
      )}
    </IonPage>
  );
};
export default PaymentMethods;
