import { IonContent, IonPage } from "@ionic/react";
import AxiosApi from "../../utils/axios";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Footer } from "../../components";
import { LoadingContainer } from "../../components/ActionSheets/LoadingAction";
import { NodataContainer } from "../../components/ActionSheets/NoData";
import HeaderContainer from "../../components/Header";
import { baseUrl } from "../../utils/config";

const TicketDetails = () => {
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const TicketId: any = useParams();
  const [ticketDetails, setTicketDetails] = useState<any>();
  const [ticketMessagesList, setTicketMessagesList] = useState<any>();
  const [replay, setReplay] = useState<any>();
  const [message, setMessage] = useState<any>();
  const GetTicketDetails = useCallback(() => {
    setLoading(true);
    try {
      AxiosApi.post(`${baseUrl}/api/user/ticket/details`, {
        token,
        id: TicketId?.id,
      }).then((res) => {
        setTicketDetails(res?.data.data);
        setLoading(false);
      });
    } catch (err: any) {
      console.log(err);
      setLoading(false);
    }
  }, [TicketId?.id, token]);
  const GetMessagesHistory = useCallback(() => {
    try {
      AxiosApi.post(`${baseUrl}/api/user/ticket/messageHistory/list`, {
        token,
        ticketId: TicketId?.id,
      }).then((res) => {
        setTicketMessagesList(res?.data.data.ticketsHistoryMessage.rows);
        setLoading(false);
      });
    } catch (err: any) {
      console.log(err);
      setLoading(false);
    }
  }, [TicketId?.id, token]);
  const HandleDeleteMessage = useCallback(
    (id: any) => {
      try {
        AxiosApi.post(`${baseUrl}/api/user/ticket/message/delete`, {
          token,
          messageId: id,
        }).then((res) => {
          GetMessagesHistory();
          setLoading(false);
        });
      } catch (err: any) {
        console.log(err);
        setLoading(false);
      }
    },
    [GetMessagesHistory, token]
  );
  const HandleReplayMessage = useCallback(() => {
    try {
      AxiosApi.post(`${baseUrl}/api/user/userTicket/message/reply`, {
        token,
        ticketId: TicketId?.id,
        messageBody: message,
      }).then((res) => {
        GetMessagesHistory();
        setLoading(false);
      });
    } catch (err: any) {
      console.log(err);
      setLoading(false);
    }
  }, [GetMessagesHistory, TicketId?.id, message, token]);

  useEffect(() => {
    GetTicketDetails();
    GetMessagesHistory();
  }, [GetMessagesHistory, GetTicketDetails]);
  console.log(ticketMessagesList);
  return (
    <IonPage>
      <IonContent fullscreen>
        {loading ? (
          <LoadingContainer />
        ) : (
          <>
            <div id="appCapsule">
              <HeaderContainer headerTitle=" Ticket Details" />
              {ticketDetails?.ticketType === "offer" ? (
                <Link to="#" className="p-2 mt-2">
                  <div className="p-3">
                    <h5 className="border-bottom pb-2">Ticket :</h5>
                    <p className="para-bold-style font-weight-bold mb-0">
                      Ticket Type : {ticketDetails?.ticketType}
                    </p>
                    <p className="para-bold-style font-weight-bold mb-0">
                      Ticket Message : {ticketDetails?.ticketBody}{" "}
                    </p>
                    {!ticketDetails?.isClosed ? (
                      <p className="para-bold-style font-weight-bold mb-0">
                        Status : {ticketDetails?.status}
                      </p>
                    ) : null}
                    <p className="para-bold-style font-weight-bold mb-0">
                      Date:{" "}
                      {moment(ticketDetails?.createdAt).format("DD-MM-YYYY")}
                    </p>

                    <p className="para-bold-style font-weight-bold mb-0 ">
                      OfferDetails :
                      <div className="card  p-2">
                        Name : {ticketDetails?.Request?.offerDetail.title}{" "}
                        <br />
                        Price : {ticketDetails?.Request?.offerDetail.price}
                        <br />
                        Points : {
                          ticketDetails?.Request?.offerDetail.points
                        }{" "}
                        <br />
                        Start Date :{" "}
                        {moment(
                          ticketDetails?.Request?.offerDetail.createdAt
                        ).format("DD-MM-YYYY")}{" "}
                        <br />
                        End Date :{" "}
                        {moment(
                          ticketDetails?.Request?.offerDetail.endDate
                        ).format("DD-MM-YYYY")}
                      </div>
                    </p>
                    {ticketDetails?.isClosed ? (
                      <div className="text-center mt-2">
                        <span className="text-danger">Closed</span>
                      </div>
                    ) : null}
                  </div>
                </Link>
              ) : ticketDetails?.ticketType === "product" ? (
                <Link to="#" className="p-2 mt-2">
                  <div className="p-3">
                    <h5 className="border-bottom pb-2">Ticket :</h5>
                    <p className="para-bold-style font-weight-bold mb-0">
                      Ticket Type : {ticketDetails?.ticketType}
                    </p>
                    <p className="para-bold-style font-weight-bold mb-0">
                      Ticket Message : {ticketDetails?.ticketBody}{" "}
                    </p>
                    {!ticketDetails?.isClosed ? (
                      <p className="para-bold-style font-weight-bold mb-0">
                        Status :{" "}
                        {ticketDetails?.isComplete ? "Done" : "Pending"}
                      </p>
                    ) : null}
                    <p className="para-bold-style font-weight-bold mb-0">
                      Date :{" "}
                      {moment(ticketDetails?.createdAt).format("DD-MM-YYYY")}
                    </p>
                    <p className="para-bold-style font-weight-bold mb-0 ">
                      ProductDetails :
                      <div className="card  p-2">
                        Price : {ticketDetails?.ProductRequest?.amountPrice}
                        <br />
                        Points : {
                          ticketDetails?.ProductRequest?.amountPoints
                        }{" "}
                        <br />
                        Start Date :{" "}
                        {moment(
                          ticketDetails?.Request?.offerDetail?.createdAt
                        ).format("DD-MM-YYYY")}{" "}
                      </div>
                    </p>
                  </div>
                  {ticketDetails?.isClosed ? (
                    <div className="text-center">
                      <span className="text-danger">Closed</span>
                    </div>
                  ) : null}
                </Link>
              ) : (
                <Link to="#" className="p-2 mt-2">
                  <div className="p-3">
                    <h5 className="border-bottom pb-2">Ticket :</h5>
                    <p className="para-bold-style font-weight-bold mb-0">
                      Ticket Number : {ticketDetails?.ticketNumber}
                    </p>
                    <p className="para-bold-style font-weight-bold mb-0">
                      Ticket Type : {ticketDetails?.ticketType}
                    </p>
                    <p className="para-bold-style font-weight-bold mb-0">
                      Ticket Message : {ticketDetails?.ticketBody}{" "}
                    </p>
                    {!ticketDetails?.isClosed ? (
                      <p className="para-bold-style font-weight-bold mb-0">
                        Status :{" "}
                        {ticketDetails?.isComplete ? "Done" : "Pending"}
                      </p>
                    ) : null}

                    <p className="para-bold-style font-weight-bold mb-0">
                      Date:{" "}
                      {moment(ticketDetails?.createdAt).format("DD-MM-YYYY")}
                    </p>
                  </div>
                  {ticketDetails?.isClosed ? (
                    <div className="text-center">
                      <span className="text-danger">Closed</span>
                    </div>
                  ) : null}
                </Link>
              )}
              <p className="text-center messagelist-title">Messages</p>
              <div className="p-2 messagelist">
                {ticketMessagesList?.length !== 0 ? (
                  ticketMessagesList?.map((message: any, i: any) => (
                    <div className=" card  mb-2 " key={message?.id}>
                      <div className="card-body">
                        <p className="messagelist-from">
                          {i === 0 ? "Recently" : ""}
                        </p>
                        <p className="messagelist-body">
                          {message?.messageBody}
                        </p>
                        <p className="messagelist-recently">
                          {message?.adminId ? (
                            " From Admin"
                          ) : (
                            <p className="messagelist-recently">
                              From User{" "}
                              <i
                                onClick={() => HandleDeleteMessage(message?.id)}
                                className="bi bi-x-circle position-absolute"
                                style={{
                                  right: "5px",
                                  top: "8px",
                                  fontSize: "20px",
                                }}
                              />
                            </p>
                          )}
                        </p>
                      </div>
                    </div>
                  ))
                ) : (
                  <NodataContainer />
                )}
              </div>
              <div className="text-center mx-auto mb-5 pb-5">
                {!ticketDetails?.isClosed &&
                ticketMessagesList?.length !== 0 ? (
                  <>
                    <textarea
                      className="para-style"
                      onChange={(e: any) => {
                        setMessage(e.target.value);
                      }}
                      rows={6}
                      cols={50}
                    />
                    <div className="p-3 w-50 mx-auto">
                      <button
                        className="btn btn-primary btn-block btn-lg"
                        onClick={HandleReplayMessage}
                      >
                        Replay
                      </button>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </>
        )}
      </IonContent>
      <Footer />
    </IonPage>
  );
};
export default TicketDetails;
