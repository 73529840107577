import { useCallback, useEffect, useState } from "react";
import merchantcover from "../../assets/images/merchant-cover.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  NavLink,
  Switch,
  Route,
  useRouteMatch,
  Redirect,
  useLocation,
  Link,
} from "react-router-dom";
import SwiperCore, { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import beanos from "../../assets/images/beanos.png";
import pizza from "../../assets/images/pizza.svg";
import starb from "../../assets/images/dummyicon.png";
import ProductCard from "./ProductCard";
import hrtred from "../../assets/images/hrtred.svg";
import mac from "../../assets/images/mac.svg";
import product from "../../assets/images/product.svg";
import fries from "../../assets/images/fries.svg";
import dataLoader from "../../assets/images/dataloader.gif";

// Import Swiper styles
import "swiper/css";
import AxiosApi from "../../utils/axios";
import { baseUrl } from "../../utils/config";
import { LoadingContainer } from "../../components/ActionSheets/LoadingAction";
import { IonRefresher, IonRefresherContent, useIonToast } from "@ionic/react";
import { HandleTranslation } from "../../hooks";
import { useTranslation } from "react-i18next";
const favorites: any = localStorage.getItem("favourites_products");
const newWish = JSON.parse(favorites);

const Products: React.FC = () => {
  const [loaderData, setLoadingData] = useState(false);
  const [present] = useIonToast();
  const presentToast = (message: any) => {
    present({
      message: message,
      duration: 1000,
      position: "bottom",
    });
  };
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cleanCateg, setCleanCateg] = useState(true);
  const [tags, setTags] = useState<any>([]);

  const [id, setId] = useState();

  const [swiper, setSwiper] = useState<any>();
  useEffect(() => {
    setLoading(true);
    cleanCateg &&
      AxiosApi({
        url: `${baseUrl}/api/public/tag/list`,
        method: "post",
        data: { token: "test" },
      }).then((res) => setTags(res?.data?.data?.rows));
    return () => {
      setCleanCateg(false);
      setLoading(false);
    };
  }, [cleanCateg]);

  // useEffect(() => {
  //   setLoading(true);
  //   AxiosApi({
  //     url: `${baseUrl}/api/user/product/list`,
  //     method: "post",
  //     data: { token },
  //   }).then((res) => {
  //     setProducts(res?.data.data.rows);
  //     setLoading(false);
  //   });
  // }, [token]);

  const pushNewItem = useCallback((data: any) => {
    console.log(data);
    const duplicateItem = newWish.findIndex(function (element: any) {
      if (element.id == data.id) return true;
      else return false;
    });

    if (duplicateItem == -1) {
      console.log(duplicateItem);
      console.log("add item");
      console.log(newWish);
      newWish.push(data);
      localStorage.setItem("favourites_products", JSON.stringify(newWish));
    } else {
      newWish.splice(duplicateItem, 1);
      console.log("remove item");
      console.log(newWish);
      localStorage.setItem("favourites_products", JSON.stringify(newWish));
    }
  }, []);
  useEffect(() => {
    tags.length !== 0 &&
      AxiosApi.post(`${baseUrl}/api/public/tagProducts/list`, {
        tagId: tags[0]?.id,
      })
        .then((res: any) => {
          setProducts(res?.data.data.rows);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
  }, [tags]);

  const TagFilter = (tagId: any) => {
    setLoadingData(true);
    AxiosApi.post(`${baseUrl}/api/public/tagProducts/list`, {
      tagId: tagId,
    }).then((res: any) => {
      setProducts(res?.data.data.rows);
      setLoadingData(false);
    });
  };

  function handleRefresh(event: any) {
    setTimeout(() => {
      AxiosApi({
        url: `${baseUrl}/api/public/tag/list`,
        method: "post",
        data: { token: "test" },
      }).then((res) => setTags(res?.data?.data?.rows));
      AxiosApi.post(`${baseUrl}/api/public/tagProducts/list`, {
        tagId: tags[0]?.id,
      }).then((res: any) => {
        setProducts(res?.data.data.rows);
        setLoading(false);
      });
      event.detail.complete();
    }, 2000);
  }
  const { t } = useTranslation();
  return (
    <>
      {loading ? (
        <LoadingContainer />
      ) : (
        <>
          <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
            <IonRefresherContent refreshingSpinner="circles"></IonRefresherContent>
          </IonRefresher>
          <div>
            <div>
              <img
                className="center-item imaged w-90 cover-img"
                src={merchantcover}
                alt="fprint"
              />
            </div>
          </div>
          <div className="section  merchant-list page-footer">
            <div className="scrollmenu merchant-list sticky-bar">
              {tags?.map((tag: any, i: any) => (
                <Link
                  key={tag?.id}
                  to="#"
                  className={`${swiper.activeIndex === i ? "activemenu" : ""}`}
                  onClick={() => {
                    swiper.activeIndex = 0;
                    tags.splice(0, 0, tag);
                    tags.splice(i + 1, 1);
                    TagFilter(tag?.id);
                  }}
                >
                  {HandleTranslation(tag?.name, tag?.nameAr)}
                </Link>
              ))}
            </div>

            <Swiper
              onSwiper={setSwiper}
              pagination={false}
              modules={[Pagination]}
              centeredSlides={true}
              navigation={{
                prevEl: ".prev",
                nextEl: ".next",
              }}
              onSlideChange={(swiperCore) => {
                let { activeIndex } = swiperCore;

                TagFilter(tags?.[activeIndex]?.id);
              }}
            >
              {tags.map((tag: any) => (
                <SwiperSlide key={tag?.id}>
                  <div
                    className="row text-center"
                    style={{
                      minHeight: "350px",
                    }}
                  >
                    {loaderData ? (
                      <div className="text-center">
                        <img
                          src={dataLoader}
                          alt="loader"
                          className="w-50 h-50"
                        />
                      </div>
                    ) : (
                      products.map((product: any) => (
                        <div
                          key={product?.id}
                          className="col-6  mt-1 offercard-padding"
                          onClick={() => {
                            setId(product?.Product?.id);
                          }}
                        >
                          <div className="card product-card">
                            <div className="card-body">
                              <img
                                className="brand-position"
                                src={
                                  product?.image === null
                                    ? fries
                                    : `https://api.eshailx.com/${product?.image}`
                                }
                                alt="fries"
                              />
                              <div>
                                <h5 className="offercard-title">
                                  {HandleTranslation(
                                    product?.name,
                                    product?.nameAr
                                  )}
                                </h5>
                                <h5 className="offercard-earn">
                                  {t("getitfor")}{" "}
                                  <span className="offercard-earnpoints">
                                    {HandleTranslation(
                                      product?.points,
                                      Number(product?.points).toLocaleString(
                                        "ar-u-nu-arab"
                                      )
                                    )}
                                  </span>{" "}
                                  {t("point")}
                                </h5>
                                {product?.stockAmount == 0 ? (
                                  <span
                                    className="text-danger  d-block"
                                    style={{
                                      fontSize: "11px",
                                    }}
                                  >
                                    {t("noamount")}
                                  </span>
                                ) : null}
                                <i
                                  className={`bi ${
                                    newWish?.findIndex(
                                      (elem: any) => elem.id === product?.id
                                    ) == -1
                                      ? `bi-heart`
                                      : `bi-heart-fill`
                                  }  `}
                                  style={{
                                    color: "#ff5700",
                                    fontSize: "13px",
                                  }}
                                  onClick={() => {
                                    pushNewItem(product);
                                    newWish?.findIndex(
                                      (elem: any) => elem.id === product?.id
                                    ) == -1
                                      ? presentToast(
                                          "Product Removed Successfully"
                                        )
                                      : presentToast(
                                          "Product Added Successfully"
                                        );
                                    window.location.reload();
                                  }}
                                ></i>

                                {/* <img className="product-position" src={products} alt="product" /> */}
                              </div>
                              <p className="offercard-desc offercard2">
                                {HandleTranslation(
                                  product.description,
                                  product.descriptionAr
                                )}
                              </p>
                              <Link
                                to={`/product/${product?.id}`}
                                className="btn btn-sm btn-primary btn-block blue-btn"
                              >
                                {t("buy")}
                              </Link>
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                  <br />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </>
      )}
    </>
  );
};

export default Products;
