export const TRANSLATIONS_EN = {
  Register: "Create Account",
  Name: "Name",
  Mobile: "Mobile",
  Password: "Password",
  Currency: "Currency",
  Oops: "Oops",
  UnexpectedError: "Unexpected Error",
  LogIn: "Log In",
  havenotAccount: "Don't have account?",
  Alreadyhaveaccount: "Already have account?",
  LetGetStarted: "Let's Get Started",
  CreateAccount: "Create Account",
  haveanaccount: "have an account?",
  yourpoints: "Your Points",
  points: "Points",
  total: "Total Earned",
  point: "Points",
  managepoints: "Manage Points",
  balance: "Balance",
  cash: "Cash",
  addpoints: "Add Points",
  requests: "Requests",
  requestshistory: "Requests History",
  orderhistory: "Order History",
  nodata: "Nothing Here Yet",
  offers: "Offers",
  products: "Products",
  spend: "Spend",
  newoffers: "New Offers",
  daysleft: "Days Left",
  aday: "a day",
  hi: "Hi",
  whatoffer: "What Offer Do You Want To Redeem Today?",
  menu: "Menu",
  welcometo: "Welcome To ",
  getoffers: "Get Offers",
  purchaseproducts: "Purchase Products",
  profile: "Profile",
  setting: "Setting",
  merchants: "Merchants",
  favtags: "Favourite Tags",
  notification: "Notifications",
  favourites: "Favourites",
  tickets: "Tickets",
  paymentmethods: "Payment Methods",
  paynow: "Pay Now",
  paymentmethodserr: "you don't have any payment methods",
  gotopayment: " Go to Payment Methods Page",
  mydebts: "My Debts",
  changepw: "Change Password",
  logout: "Logout",
  cashout: "Cash Out",
  delivery: "Delivery",
  pickup: "Pickup",
  details: "Details",
  branches: "Branches",
  earn: "Earn",
  expiredoffer: "Expired Offer",
  earnpoints: "Earn Points",
  noamount: "No Amount",
  buy: "Buy",
  buynow: "Buy Now",
  telephone: "Telephone",
  getitfor: "Get It For",
  description: "Description",
  branchname: "Branch Name",
  status: "Status",
  pending: "Pending",
  verify: "Verify",
  accept: "Accept",
  getthisoffer: "Get This Offer",
  stillpending: " Still Pending to approve. please accept and verify",
  gothisoffer: "You already got this offer before check history list",
  requestdetails: "Request Details",
  pointsearned: "Points Earned",
  date: "Date",
  recomproducts: "Recommended Products",
  pleasechooseadd: " please choose or add new adress",
  deliverytohome: "Deliver To Home",
  addnewaddress: "Add New Address",
  branchaddress: "Branch Address",
  changeaddress: "Change Address",
  changebranch: "Change Branch",
  pickupfrom: "Pickup From",
  information: "Information",
  installment: "Installment",
  merchantname: "Merchant Name",
  estimetedship: "Estimated Ship By",
  deliverytype: "Delivery Type",
  subtotoal: "subtotal",
  companyname: " Company Name",
  companyaddress: "Company Address",
  companyphone: " Company Phone",
  totalearned: "Total Earned",
  select: "Select",
  purchased: "Purchased",
  ontheway: "on the way",
  recieved: "recieved",
  delivered: "delivered",
  orderrecieved: "order recieved",
  faverror: "you should have 3 minimum and 5 maximum",
  submit: "submit",
  addresses: "Addresses",
  change: "change",
  createprofile: "Create Profile",
  updateprofile: "Update Profile",
  age: "Age",
  gender: "Gender",
  nationality: "Nationality",
  limit: "Limit",
  installmentsavailablebalance: "Installments Available Balance",
  address1: "Address1",
  address2: "Address2",
  city: "City",
  area: "Area",
  changelang: "Change Language",
  addaddress: "Add Address",
  redeempoints: "Redeem Points",
  addnewticket: "Add New Ticket",
  closeticket: "Close Ticket",
  closed: "Closed",
  ticketmessage: "Ticket Message",
  ticketnumber: "Ticket Number",
  tickettype: "Ticket Type",
  paymentoptions: "Payment Options",
  personalbusiness: "Personal Business",
  showalltransactions: "Show All Transactions",
  addnewcard: "Add New Card",
  currentpw: "Current Password",
  newpw: "New Password",
  verifypw: "Verify Password",
  youwillchangepw: " you will change password",
  ofyouracc: "of your account",
  loading: "Loading",
  pleasewait: "Please Wait",
  productreview: "Product Review",
  offerreview: "Offer Review",
  clicktorate: "Click To Rate",
  rateproduct: "Rate Product",
  rateoffer: "Rate Offer",
  errpoints: " You Don't Have Enough Points To Purchase",
  orbuypackage: "Or buy Packages",
  packages: "Packages",
  showmore: "Show More",
  showless: "Show Less",
  male: "Male",
  female: "Female",
  updateaddress: "Update Address",
};
